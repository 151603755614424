import { RedirectVariant } from "API/Interfaces";
import { redirectsServiceApi } from "API/Services/RedirectsServiceApi";
import { useLoaderContext, usePopupContext } from "Contexts";
import { useAuthContext } from "Pages/Auth";
import { isIOS, isMacOs } from "react-device-detect";
import { isErrorCode } from "Utils";

export const useRedirects = () => {
  const { redirectToValet: getValetRedirect, redirectToEnf: getEnfRedirect } =
    redirectsServiceApi;
  const { user, unsetUser } = useAuthContext();
  const { setLoading } = useLoaderContext();
  const { setPopup, showPopup } = usePopupContext();

  const redirectToValet = (variant?: RedirectVariant, id?: string) => {
    if (user) {
      setLoading(true);
      const { sessionId } = user;
      getValetRedirect({ redirectVariant: variant, id, sessionId })
        .then(({ host }) => {
          if (!isIOS && !isMacOs) {
            window.open(host, "_blank");
          } else {
            window.location.href = host;
          }
        })
        .catch((e) => {
          setPopup({
            titleTranslation: "DIALOGS_REDIRECTTOVALET",
            variant: "error",
            descriptionTranslation: isErrorCode(e, "UnauthorizedAccess")
              ? "DIALOGS_SESSIONEXPIRED"
              : "DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN",
            buttonOkClick: isErrorCode(e, "UnauthorizedAccess")
              ? unsetUser
              : () => undefined,
          });
          showPopup();
        })
        .finally(() => setLoading(false));
    }
  };

  const redirectToEnf = () => {
    if (user) {
      setLoading(true);
      const { sessionId } = user;
      getEnfRedirect(sessionId)
        .then(({ host, sessionId }) => {
          const url = `${host}?sessionId=${sessionId}`;
          if (!isIOS && !isMacOs) {
            window.open(url, "_blank");
          } else {
            window.location.href = url;
          }
        })
        .catch((e) => {
          setPopup({
            titleTranslation: "DIALOGS_REDIRECTTOVALET",
            variant: "error",
            descriptionTranslation: isErrorCode(e, "UnauthorizedAccess")
              ? "DIALOGS_SESSIONEXPIRED"
              : "DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN",
            buttonOkClick: isErrorCode(e, "UnauthorizedAccess")
              ? unsetUser
              : () => undefined,
          });
          showPopup();
        })
        .finally(() => setLoading(false));
    }
  };

  return { redirectToValet, redirectToEnf } as const;
};

export default useRedirects;
