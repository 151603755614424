import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";
export interface FormSeparatorTextProps extends TypographyProps {
  translateLabel?: TranslationKeys;
}

const StyledTypo = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const FormSeparatorText: React.FC<FormSeparatorTextProps> = ({
  translateLabel,
  children,
  ...rest
}) => {
  const t = useTranslate();
  return (
    <StyledTypo {...rest} variant="h2">
      {translateLabel && t(translateLabel)}
      {children}
    </StyledTypo>
  );
};

export default FormSeparatorText;
