import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

export interface AppTooltipProps extends TooltipProps {}

export const AppTooltip: React.FC<AppTooltipProps> = (props) => {
  return (
    <Tooltip {...props}>
      <div style={{ display: "flex" }}>{props.children}</div>
    </Tooltip>
  );
};

export default AppTooltip;
