import { apiClient } from "API/Auth/apiClient";
import { RedirectVariant } from "API/Interfaces";
import urls from "./urls";

export interface IRedirectValetData {
  sessionId: string;
  redirectVariant?: RedirectVariant;
  id?: string;
}

export const redirectsServiceApi = {
  redirectToEnf: async (
    sessionId: string
  ): Promise<{
    host: string;
    sessionId: string;
  }> => {
    const res = await apiClient.post(urls.redirects.enforcement, { sessionId });
    return res.data.data;
  },
  redirectToValet: async (
    params: IRedirectValetData
  ): Promise<{
    host: string;
  }> => {
    const res = await apiClient.post(urls.redirects.valet, params);
    return res.data.data;
  },
};
