import {
  DefaultFormAddressConfigurationFieldProps,
  FormComponentsAddressFields,
  FormTextFieldComponent,
} from "API/Interfaces";
import React from "react";
import { getFieldName } from "Utils";
import FormTextField from "./FormTextField";
import { FormTextFieldWithAddressConfiguration } from "./FormTextFieldWithAddressConfiguration";

const defaultProps: DefaultFormAddressConfigurationFieldProps = {
  fieldName: getFieldName<FormComponentsAddressFields>("addressStreet2"),
  labelTranslation: "COMMON_FORMS_STREET2",
};

export const FormStreet2Field: FormTextFieldComponent = (props) => (
  <FormTextField {...defaultProps} {...props} />
);

export const FormStreet2WithAddressConfig: FormTextFieldComponent = (props) => (
  <FormTextFieldWithAddressConfiguration {...defaultProps} {...props} />
);

export default FormStreet2Field;
