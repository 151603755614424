import React from "react";
import styled, { css } from "styled-components";

export interface ButtonWrapperProps {
  /** MUI spacing */
  topSpacing?: number;
  justify?: React.CSSProperties["justifyContent"];
  /** MUI spacing */
  marginBottom?: number;
}

const Wrapper = styled.div<{
  $topSpacing?: number;
  $justify?: React.CSSProperties["justifyContent"];
  $marginBottom?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(_) => _.$justify};
  align-items: center;
  margin: 20px 12px;
  margin-bottom: ${(_) => _.theme.spacing(_.$marginBottom)}px;
  ${(_) =>
    _.$topSpacing &&
    css`
      padding-top: ${_.theme.spacing(_.$topSpacing)}px;
    `}
  > *:not(:first-child) {
    margin-left: 15px;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    width: 100%;
  }
`;
Wrapper.defaultProps = {
  $justify: "center",
  $marginBottom: 0,
};
const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  topSpacing,
  justify,
  marginBottom,
  ...props
}) => {
  return (
    <Wrapper
      $topSpacing={topSpacing}
      $justify={justify}
      $marginBottom={marginBottom}
    >
      {props.children}
    </Wrapper>
  );
};

export default ButtonWrapper;
