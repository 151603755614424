import { FormComponentsAddressFields } from "API/Interfaces";
import { ILocationDetails } from "API/Interfaces/ILocation";
import { MapFields } from "Layout/FormComponents/Map/types";
import { getMapInitValues } from "Layout/FormComponents/Map/utils";
import { FormComponentsFields } from "Layout/FormComponents/types";

type SharedFields = Required<
  Pick<FormComponentsFields, "name" | "country" | "logoId" | "timezone">
>;
export interface LocationFormFields extends SharedFields {
  contactName: string;
  contactPhone: string;
  contactEmail: string[];
  billingName: string;
  billingPhone: string;
  billingEmail: string[];
}
export type LocationFormFieldsWithAddress = LocationFormFields &
  FormComponentsAddressFields &
  MapFields;
export const locationFormToRequest = (
  l: LocationFormFieldsWithAddress
): ILocationDetails => ({
  name: l.name,
  contactPerson: {
    name: l.contactName,
    emailAddresses: l.contactEmail,
    mobilePhoneNumber: l.contactPhone,
  },
  billingPerson: {
    name: l.billingName,
    emailAddresses: l.billingEmail,
    mobilePhoneNumber: l.billingPhone,
  },
  gpsCoordinates: {
    longitude: l.markerPosition.lng,
    latitude: l.markerPosition.lat,
  },
  logoId: l.logoId,
  address: {
    countryId: l.country,
    timeZoneName: l?.timezone || "",
    addressConfiguration: {
      street1: l.addressStreet1,
      street2: l.addressStreet2,
      zipCode: l.addressPostalCode,
      county: l.addressCounty,
      city: l.addressCity,
      state: l.addressState,
    },
  },
});

export const locationResponseToForm = (
  l: any //TODO: fix types
): LocationFormFieldsWithAddress => ({
  name: l?.name || "",
  country: l?.address.countryId || 236,
  addressStreet1: l?.address.addressConfiguration.street1?.value || "",
  addressStreet2: l?.address.addressConfiguration.street2?.value || "",
  addressPostalCode: l?.address.addressConfiguration.zipCode?.value || "",
  addressCounty: l?.address.addressConfiguration.county?.value || "",
  addressCity: l?.address.addressConfiguration.city?.value || "",
  addressState: l?.address.addressConfiguration.state?.value || "",
  timezone: l?.address?.timeZoneName || "",
  logoId: l?.logoId || "",
  contactName: l?.contactPerson.name || "",
  contactPhone: l?.contactPerson.mobilePhoneNumber || "",
  contactEmail: l?.contactPerson.emailAddresses || [],
  billingName: l?.billingPerson.name || "",
  billingPhone: l?.billingPerson.mobilePhoneNumber || "",
  billingEmail: l?.billingPerson.emailAddresses || [],
  ...getMapInitValues(l?.gpsCoordinates),
});
