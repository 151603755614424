const CORPORATIONS = "corporations";
const LOCATIONS = "locations";
const LOTS = "lots";
const REPORTS = "reports";
const LOGIN = "login";
const IMPORT_LOTS = "import-lots";

export const routes = {
  root: "/",
  login: `/${LOGIN}`,
  corporationList: `/${CORPORATIONS}`,
  corporation: `/${CORPORATIONS}/:id`,
  location: `/${LOCATIONS}/:id`,
  reports: REPORTS,
  lot: `/${LOTS}/:id`,
  import: `/${LOCATIONS}/:id/${IMPORT_LOTS}`,
};

export const generateRoute = {
  corporation: (id: string) => `/${CORPORATIONS}/${id}`,
  location: (id: string) => `/${LOCATIONS}/${id}`,
  lot: (id: string) => `/${LOTS}/${id}`,
  import: (id: string) => `/${LOCATIONS}/${id}/${IMPORT_LOTS}`,
};
