import { AppTypography } from "Layout/Components/AppTyphography";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";

export interface ColumnLabelProps {
  translateLabel: TranslationKeys;
  required?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const ColumnLabel: React.FC<ColumnLabelProps> = ({
  translateLabel,
  required,
}) => {
  const t = useTranslate();
  return (
    <Container>
      <AppTypography variant="body2">
        {t(translateLabel)}{" "}
        {required && <span style={{ color: "red" }}>{" *"}</span>}
      </AppTypography>
    </Container>
  );
};

export default ColumnLabel;
