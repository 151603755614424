export enum Translations {
  COMMON_BUTTONS_SEARCH = "common.buttons.search",
  COMMON_BUTTONS_ADDNEWCORPORATION = "common.buttons.addNewCorporation",
  COMMON_BUTTONS_ADDNEWLOCATION = "common.buttons.addNewLocation",
  COMMON_BUTTONS_ADDNEWLOT = "common.buttons.AddNewLot",
  COMMON_BUTTONS_IMPORTFROMCSV = "common.buttons.importFromCsv",
  COMMON_BUTTONS_SEEMORELOCATIONS = "common.buttons.seeMoreLocations",
  COMMON_BUTTONS_SEEMORELOTS = "common.buttons.seeMoreLots",
  COMMON_BUTTONS_DELETECORPORATION = "common.buttons.deleteCorporation",
  COMMON_BUTTONS_DELETELOCATION = "common.buttons.deleteLocation",
  COMMON_BUTTONS_DELETELOT = "common.buttons.deleteLot",
  COMMON_BUTTONS_UPLOADLOGO = "common.buttons.uploadLogo",
  COMMON_BUTTONS_UPLOADPHOTO = "common.buttons.uploadPhoto",
  COMMON_BUTTONS_CANCEL = "common.buttons.cancel",
  COMMON_BUTTONS_SAVE = "common.buttons.save",
  COMMON_BUTTONS_SAMEHOURS = "common.buttons.sameHours",
  COMMON_BUTTONS_DIFFERENTHOURS = "common.buttons.differentHours",
  COMMON_BUTTONS_SEEMORERESULTS = "common.buttons.seeMoreResults",
  COMMON_BUTTONS_CORPORATIONS = "common.buttons.corporations",
  COMMON_BUTTONS_REPORTS = "common.buttons.reports",
  COMMON_BUTTONS_IMPORT = "common.buttons.import",
  COMMON_BUTTONS_DOWNLOADCSVTEMPLATE = "common.buttons.downloadCsvTemplate",
  COMMON_BUTTONS_APPLY = "common.buttons.apply",
  COMMON_BUTTONS_GOTOVALETADMINPORTAL = "common.buttons.goToValetAdminPortal",
  COMMON_PAGES_CORPORATIONLIST = "common.pages.corporationList",
  COMMON_PAGES_CORPORATIONDETAILS = "common.pages.corporationDetails",
  COMMON_PAGES_LOCATIONDETAILS = "common.pages.locationDetails",
  COMMON_PAGES_LOTDETAILS = "common.pages.lotDetails",
  COMMON_PAGES_LOCATIONS = "common.pages.locations",
  COMMON_PAGES_LOCATION = "common.pages.location",
  COMMON_PAGES_LOTS = "common.pages.lots",
  COMMON_PAGES_LOT = "common.pages.lot",
  COMMON_PAGES_GENERAL = "common.pages.general",
  COMMON_PAGES_GENERALINFO = "common.pages.generalInfo",
  COMMON_PAGES_BILLINGINFO = "common.pages.billingInfo",
  COMMON_PAGES_CREATEDIN = "common.pages.createdIn",
  COMMON_PAGES_ADMINISTRATOR = "common.pages.administrator",
  COMMON_PAGES_NEWCORPORATION = "common.pages.newCorporation",
  COMMON_PAGES_NEWLOCATION = "common.pages.newLocation",
  COMMON_PAGES_NEWLOT = "common.pages.newLot",
  COMMON_PAGES_CONTACT = "common.pages.contact",
  COMMON_PAGES_BILLING = "common.pages.billing",
  COMMON_PAGES_OPENINGHOURS = "common.pages.openingHours",
  COMMON_PAGES_IMPORTLOTS = "common.pages.importLots",
  COMMON_PAGES_DRAGANDDROPCSVFILE = "common.pages.dragAndDropCsvFile",
  COMMON_PAGES_COLUMNMAPPING = "common.pages.columnMapping",
  COMMON_PAGES_LOTDATA = "common.pages.lotData",
  COMMON_PAGES_IMPORTCOLUMNS = "common.pages.importColumns",
  COMMON_PAGES_IMPORTRESULT = "common.pages.importResult",
  COMMON_PAGES_ERRORS = "common.pages.errors",
  COMMON_PAGES_EDITDATA = "common.pages.editData",
  COMMON_PAGES_DELETEDATA = "common.pages.deleteData",
  COMMON_PAGES_YOURFILEHASBEENSUCCESSFULLYIMPORTED = "common.pages.yourFileHasBeenSuccessfullyImported",
  COMMON_PAGES_FUNCTIONALITIESANDPRODUCTS = "common.pages.functionalitiesAndProducts",
  COMMON_PAGES_FUNCTIONALITIESMANAGEMENT = "common.pages.functionalitiesManagement",
  COMMON_PAGES_PARKINGMANAGEMENT = "common.pages.parkingManagement",
  COMMON_PAGES_VALETADMINPORTAL = "common.pages.valetAdminPortal",
  COMMON_PAGES_VALETADMINPORTALFUNCTIONALITY = "common.pages.valetAdminPortalFunctionality",
  COMMON_PAGES_TEXT2PARKADMINPORTAL = "common.pages.text2ParkAdminPortal",
  COMMON_PAGES_LOCATIONTYPE = "common.pages.locationType",
  COMMON_PAGES_MAINLOTTYPE = "common.pages.mainLotType",
  COMMON_PAGES_ENFORCEMENTPRODUCT = "common.pages.enforcementProduct",
  COMMON_PAGES_LOTTYPE = "common.pages.lotType",
  COMMON_FORMS_NAME = "common.forms.name",
  COMMON_FORMS_DEALER = "common.forms.dealer",
  COMMON_FORMS_COUNTRY = "common.forms.country",
  COMMON_FORMS_COUNTY = "common.forms.county",
  COMMON_FORMS_STREET1 = "common.forms.street1",
  COMMON_FORMS_STREET2 = "common.forms.street2",
  COMMON_FORMS_POSTALCODE = "common.forms.postalCode",
  COMMON_FORMS_CITY = "common.forms.city",
  COMMON_FORMS_STATE = "common.forms.state",
  COMMON_FORMS_TIMEZONE = "common.forms.timezone",
  COMMON_FORMS_EMAIL = "common.forms.email",
  COMMON_FORMS_PHONE = "common.forms.phone",
  COMMON_FORMS_OFFICEPHONE = "common.forms.officePhone",
  COMMON_FORMS_MOBILEPHONE = "common.forms.mobilePhone",
  COMMON_FORMS_CONTACTPERSON = "common.forms.contactPerson",
  COMMON_FORMS_BILLINGPERSON = "common.forms.billingPerson",
  COMMON_FORMS_ACCOUNTNUMBER = "common.forms.accountNumber",
  COMMON_FORMS_FIELDISREQUIRED = "common.forms.fieldIsRequired",
  COMMON_FORMS_FIELDISTOLONG = "common.forms.fieldIsToLong",
  COMMON_FORMS_INVALIDFORMAT = "common.forms.invalidFormat",
  COMMON_FORMS_NAMEISREQUIRED = "common.forms.nameIsRequired",
  COMMON_FORMS_COUNTRYISREQUIRED = "common.forms.countryIsRequired",
  COMMON_FORMS_ADDRESSISREQUIRED = "common.forms.addressIsRequired",
  COMMON_FORMS_POSTALCODEISREQUIRED = "common.forms.postalCodeIsRequired",
  COMMON_FORMS_COUNTYISREQUIRED = "common.forms.countyIsRequired",
  COMMON_FORMS_EMAILISREQUIRED = "common.forms.emailIsRequired",
  COMMON_FORMS_CITYISREQUIRED = "common.forms.cityIsRequired",
  COMMON_FORMS_STATEISREQUIRED = "common.forms.stateIsRequired",
  COMMON_FORMS_TIMEZONEISREQUIRED = "common.forms.timezoneIsRequired",
  COMMON_FORMS_ENTERVALIDEMAIL = "common.forms.enterValidEmail",
  COMMON_FORMS_OFFICEPHONEISREQUIRED = "common.forms.officePhoneIsRequired",
  COMMON_FORMS_MOBILEPHONEISREQUIRED = "common.forms.mobilePhoneIsRequired",
  COMMON_FORMS_THISFIELDMUSTHAVE = "common.forms.thisFieldMustHave",
  COMMON_FORMS_THISFIELDINCLUDESTOOMANY = "common.forms.thisFieldIncludesTooMany",
  COMMON_FORMS_SEARCHCORPORATION = "common.forms.searchCorporation",
  COMMON_FORMS_GLOBALSEARCH = "common.forms.globalSearch",
  COMMON_FORMS_SEARCHLOCATION = "common.forms.searchLocation",
  COMMON_FORMS_SEARCHLOT = "common.forms.searchLot",
  COMMON_FORMS_LOGOISREQUIRED = "common.forms.logoIsRequired",
  COMMON_FORMS_ACCOUNTNUMBERREQUIRED = "common.forms.accountNumberRequired",
  COMMON_FORMS_LONGITUDEREQUIRED = "common.forms.longitudeRequired",
  COMMON_FORMS_LATITUDEREQUIRED = "common.forms.latitudeRequired",
  COMMON_FORMS_PHONEREQUIRED = "common.forms.phoneRequired",
  COMMON_FORMS_FAILEDUPLOADIMAGE = "common.forms.failedUploadImage",
  COMMON_FORMS_FIELDMUSTBEANUMBER = "common.forms.fieldMustBeANumber",
  COMMON_FORMS_MAXCHARACTER15 = "common.forms.maxCharacter15",
  COMMON_FORMS_MAXCHARACTER50 = "common.forms.maxCharacter50",
  COMMON_FORMS_MAXCHARACTER100 = "common.forms.maxCharacter100",
  COMMON_FORMS_MAXCHARACTER200 = "common.forms.maxCharacter200",
  COMMON_FORMS_MINCHARACTER3 = "common.forms.minCharacter3",
  COMMON_FORMS_MAXVALUE = "common.forms.maxValue",
  COMMON_FORMS_INCORRECTLATITUDE = "common.forms.incorrectLatitude",
  COMMON_FORMS_INCORRECTLONGITUDE = "common.forms.incorrectLongitude",
  COMMON_FORMS_INCORRECTNUMBERFORMAT = "common.forms.incorrectNumberFormat",
  COMMON_FORMS_INCORRECTCSVFILEFORMAT = "common.forms.incorrectCSVFileFormat",
  COMMON_FORMS_ELIERTIMEERROR = "common.forms.elierTimeError",
  COMMON_FORMS_LOTNUMBER = "common.forms.lotNumber",
  COMMON_FORMS_CAPACITY = "common.forms.capacity",
  COMMON_FORMS_SUNDAY = "common.forms.sunday",
  COMMON_FORMS_MONDAY = "common.forms.monday",
  COMMON_FORMS_TUESDAY = "common.forms.tuesday",
  COMMON_FORMS_WEDNESDAY = "common.forms.wednesday",
  COMMON_FORMS_THURSDAY = "common.forms.thursday",
  COMMON_FORMS_FRIDAY = "common.forms.friday",
  COMMON_FORMS_SATURDAY = "common.forms.saturday",
  COMMON_FORMS_ALLWEEK = "common.forms.allWeek",
  COMMON_FORMS_FROM = "common.forms.from",
  COMMON_FORMS_TO = "common.forms.to",
  COMMON_FORMS_LATITUDE = "common.forms.latitude",
  COMMON_FORMS_LONGITUDE = "common.forms.longitude",
  COMMON_FORMS_5DEVICESFEE = "common.forms.5DevicesFee",
  COMMON_FORMS_SOFTWAREFEE = "common.forms.softwareFee",
  COMMON_FORMS_PRIORITYLETTERFEE = "common.forms.priorityLetterFee",
  COMMON_FORMS_PLATELOOKUPFEE = "common.forms.plateLookupFee",
  COMMON_FORMS_CERTIFIEDLETTERSFEE = "common.forms.certifiedLettersFee",
  COMMON_FORMS_ENFORCEMENTFEES = "common.forms.enforcementFees",
  COMMON_FORMS_TEZSERVICEFEE = "common.forms.TEZServiceFee",
  COMMON_FORMS_PERCITATION = "common.forms.PerCitation",
  COMMON_FORMS_CITATIONPREFIX = "common.forms.citationPrefix",
  COMMON_FORMS_PERPAYMENT = "common.forms.perPayment",
  COMMON_FORMS_STARTTYPINGADDRESSLOOKUP = "common.forms.startTypingAddressLookup",
  COMMON_FORMS_ADDRESSLOOKUP = "common.forms.addressLookup",
  COMMON_FORMS_PARKINGCOMPANY = "common.forms.parkingCompany",
  COMMON_FORMS_BASEONGEOCOORDINATES = "common.forms.baseOnGeoCoordinates",
  COMMON_FORMS_ACTIONS = "common.forms.actions",
  COMMON_FORMS_SHOWONLYDATAWITHERRORS = "common.forms.showOnlyDataWithErrors",
  COMMON_FORMS_SELECT = "common.forms.select",
  COMMON_FORMS_CSVVALUEERROR = "common.forms.csvValueError",
  COMMON_AUTH_USERNAME = "common.auth.username",
  COMMON_AUTH_PASSWORD = "common.auth.password",
  COMMON_AUTH_FORGOTPASSWORD = "common.auth.forgotPassword",
  COMMON_AUTH_LOGIN = "common.auth.login",
  COMMON_AUTH_LOGOUT = "common.auth.logout",
  COMMON_AUTH_GOTOENFORCEMENT = "common.auth.goToEnforcement",
  COMMON_AUTH_ENFORCEMENTPORTAL = "common.auth.enforcementPortal",
  COMMON_AUTH_PASSWORDCANNOTBEEMPTY = "common.auth.passwordCannotBeEmpty",
  COMMON_AUTH_PASSWORDMATCHES = "common.auth.passwordMatches",
  COMMON_AUTH_USERNAMECANNOTBEEMPTY = "common.auth.usernameCannotBeEmpty",
  COMMON_AUTH_YOUHAVEENTEREDANINVALIDUSERNAMEORPASSWORD = "common.auth.youHaveEnteredAnInvalidUsernameOrPassword",
  COMMON_NODATA = "common.noData",
  COMMON_NOCORPORATIONS = "common.noCorporations",
  COMMON_NOLOCATIONS = "common.noLocations",
  COMMON_NOLOTS = "common.noLots",
  COMMON_ACTIVE = "common.active",
  COMMON_NOACTIVE = "common.noActive",
  COMMON_WELCOME = "common.welcome",
  COMMON_LOGINTOCRMPORTAL = "common.loginToCrmPortal",
  COMMON_LOGO = "common.logo",
  COMMON_JPGFORMATFILE50KB = "common.jpgFormatFile50kb",
  COMMON_JPGFORMATFILE5MB = "common.jpgFormatFile5mb",
  COMMON_FILELARGERTHAN50KB = "common.fileLargerThan50kb",
  COMMON_FILELARGERTHAN5MB = "common.fileLargerThan5mb",
  COMMON_RESULT = "common.result",
  COMMON_RESULTS = "common.results",
  COMMON_VALUE = "common.value",
  COMMON_LOT = "common.lot",
  COMMON_NONE = "common.none",
  ENUMS_SYSTEMS_ENFORCEMENT = "enums.systems.enforcement",
  ENUMS_SYSTEMS_TEXT2PARK = "enums.systems.text2Park",
  ENUMS_SYSTEMS_VALET = "enums.systems.valet",
  ENUMS_SYSTEMS_TEXT4SERVICE = "enums.systems.text4Service",
  ENUMS_LOCATIONTYPE_STANDARD = "enums.locationType.standard",
  ENUMS_LOCATIONTYPE_AIRPORT = "enums.locationType.airport",
  ENUMS_LOCATIONTYPE_HOTEL = "enums.locationType.hotel",
  ENUMS_MAINLOTTYPE_VALET = "enums.mainlotType.Valet",
  ENUMS_MAINLOTTYPE_SELFPARK = "enums.mainlotType.SelfPark",
  ENUMS_MAINLOTTYPE_TEXT2PARK = "enums.mainlotType.Text2Park",
  ENUMS_MAINLOTTYPE_HOTEL = "enums.mainlotType.Hotel",
  ENUMS_INTEGRATIONSTATUS_PENDING = "enums.integrationStatus.pending",
  ENUMS_INTEGRATIONSTATUS_CREATED = "enums.integrationStatus.created",
  ENUMS_INTEGRATIONSTATUS_ACTIVE = "enums.integrationStatus.active",
  ENUMS_INTEGRATIONSTATUS_TERMINATED = "enums.integrationStatus.terminated",
  DIALOGS_ALREADYEXISTS = "dialogs.alreadyExists",
  DIALOGS_CANCEL = "dialogs.cancel",
  DIALOGS_NO = "dialogs.no",
  DIALOGS_YES = "dialogs.yes",
  DIALOGS_CREATECORPORATIONTITLE = "dialogs.createCorporationTitle",
  DIALOGS_CREATESUCCESS = "dialogs.createSuccess",
  DIALOGS_CREATEFAIL = "dialogs.createFail",
  DIALOGS_EDITCORPORATIONTITLE = "dialogs.editCorporationTitle",
  DIALOGS_EDITSUCCESS = "dialogs.editSuccess",
  DIALOGS_EDITFAIL = "dialogs.editFail",
  DIALOGS_CREATELOCATIONTITLE = "dialogs.createLocationTitle",
  DIALOGS_EDITLOCATIONTITLE = "dialogs.editLocationTitle",
  DIALOGS_CREATELOTTITLE = "dialogs.createLotTitle",
  DIALOGS_EDITLOTTITLE = "dialogs.editLotTitle",
  DIALOGS_DELETE = "dialogs.delete",
  DIALOGS_DELETECORPINFORMATION = "dialogs.deleteCorpInformation",
  DIALOGS_DELETECORPDIALOGTITLE = "dialogs.deleteCorpDialogTitle",
  DIALOGS_DELETELOCATIONINFORMATION = "dialogs.deleteLocationInformation",
  DIALOGS_DELETELOCATIONDIALOGTITLE = "dialogs.deleteLocationDialogTitle",
  DIALOGS_DELETELOTINFORMATION = "dialogs.deleteLotInformation",
  DIALOGS_DELETELOTDIALOGTITLE = "dialogs.deleteLotDialogTitle",
  DIALOGS_OK = "dialogs.ok",
  DIALOGS_ERROR = "dialogs.error",
  DIALOGS_SUCCESS = "dialogs.success",
  DIALOGS_SESSIONEXPIRED = "dialogs.sessionExpired",
  DIALOGS_YOURSESSIONHASEXPIREDPLEASELOGIN = "dialogs.yourSessionHasExpiredPleaseLogin",
  DIALOGS_SEARCHRESULT = "dialogs.searchResult",
  DIALOGS_NOSEARCHRESULTS = "dialogs.noSearchResults",
  DIALOGS_PHRASE = "dialogs.phrase",
  DIALOGS_CORPORATIONDETAILS = "dialogs.corporationDetails",
  DIALOGS_CORPORATIONNOTFOUND = "dialogs.corporationNotFound",
  DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN = "dialogs.unexpectedErrorPleaseTryAgain",
  DIALOGS_WANTTODELETETHESELECTEDDATA = "dialogs.wantToDeleteTheSelectedData",
  DIALOGS_CANCELIMPORTPROCESS = "dialogs.cancelImportProcess",
  DIALOGS_AREYOUSURETOCANCELTHEIMPORTPROCESS = "dialogs.areYouSureToCancelTheImportProcess",
  DIALOGS_REDIRECTTOENF = "dialogs.redirectToEnf",
  DIALOGS_REDIRECTTOVALET = "dialogs.redirectToValet",
  DIALOGS_CORPORATIONEXISTSINVALET = "dialogs.corporationExistsInValet",
  DIALOGS_LOCATIONEXISTSINVALET = "dialogs.locationExistsInValet",
  DIALOGS_LOTEXISTSINVALET = "dialogs.lotExistsInValet",
  DIALOGS_ATLEASTONELOT = "dialogs.atLeastOneLot",
  DIALOGS_DISABLEDONCORPORATION = "dialogs.disabledOnCorporation",
  DIALOGS_DISABLEDONLOCATION = "dialogs.disabledOnLocation",
  DIALOGS_TODISABLEVALETADMINPORTAL = "dialogs.toDisableValetAdminPortal",
  DIALOGS_CORPORATIONCANNOTBEDELETED = "dialogs.corporationCanNotBeDeleted",
  DIALOGS_LOCATIONCANNOTBEDELETED = "dialogs.locationCanNotBeDeleted",
  DIALOGS_LOTCANNOTBEDELETED = "dialogs.lotCanNotBeDeleted",
};

export type TranslationKeys = keyof typeof Translations

export default Translations;