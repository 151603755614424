import { TableCell } from "@material-ui/core";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";
import styled from "styled-components";
import React from "react";
import ErrorMarkInformation from "./ErrorMarkInformation";

export interface RowErrorProps {
  fieldName: string;
  errors?: ImportErrors[];
}

const ColumnContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > * {
    margin: 0 2px;
  }
`;

const TableColumn: React.FC<RowErrorProps> = ({
  fieldName,
  errors,
  children,
}) => {
  return (
    <TableCell>
      <ColumnContent>
        <ErrorMarkInformation errors={errors} fieldName={fieldName} />
        {children}
      </ColumnContent>
    </TableCell>
  );
};

export default TableColumn;
