import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps as MUIDialogProps,
} from "@material-ui/core";
import styled, { css } from "styled-components";
import AppTypography from "./AppTyphography";
import AppButton from "./AppButton";
import { themeComponents } from "Layout/theme";

export interface AppDialogProps extends MUIDialogProps {
  handleOpen?: any;
  agreeAction?: any;
  cancelButton?: string;
  agreeButton?: string;
  descriptionTitle?: string;
  description?: string;
  Icon?: React.FC;
  maxHeight?: number;
}
const IconWrapper = styled.div`
  padding-bottom: 12px;
  > * {
    margin: auto;
    display: block;
    width: auto;
    height: auto;
  }
`;
export const AppDialogActions = styled(DialogActions)`
  padding-bottom: 30px;
  padding-top: 20px;
`;

export const AppDialogWindow = styled(Dialog)<{ $maxHeight?: number }>`
  word-break: break-word;
  margin: 0 10px;
  .MuiDialogContent-root {
    overflow-y: hidden;
    &::-webkit-scrollbar {
      width: 0.4em;
    }
    &::-webkit-scrollbar-track {
      background-color: #c4c4c4;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${themeComponents.primary};
      border-radius: 20px;
    }
  }
  .MuiDialog-paper {
    width: 100%;
    ${(_) =>
      _.$maxHeight &&
      css`
        max-height: ${_.$maxHeight}px;
      `}
  }
`;
export const AppDialog: React.FC<AppDialogProps> = ({
  handleOpen,
  cancelButton,
  agreeAction,
  agreeButton,
  Icon,
  descriptionTitle,
  maxHeight,
  description,
  ...props
}) => {
  return (
    <AppDialogWindow
      onClose={() => handleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      $maxHeight={maxHeight}
      {...props}
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        {props.title}
      </DialogTitle>
      {!!(Icon || descriptionTitle || description || props.children) && (
        <DialogContent>
          {Icon && (
            <IconWrapper>
              <Icon />
            </IconWrapper>
          )}
          {descriptionTitle && (
            <AppTypography
              weight="bold"
              align="center"
              variant="h2"
              gutterBottom
            >
              {descriptionTitle}
            </AppTypography>
          )}
          {description && (
            <AppTypography align="center">{description}</AppTypography>
          )}
          {props.children}
        </DialogContent>
      )}
      {(cancelButton || agreeButton) && (
        <AppDialogActions>
          {cancelButton && (
            <AppButton
              onClick={() => handleOpen(false)}
              color="primary"
              variant="outlined"
            >
              {cancelButton}
            </AppButton>
          )}
          {agreeButton && (
            <AppButton
              onClick={agreeAction}
              color="primary"
              autoFocus
              variant="contained"
            >
              {agreeButton}
            </AppButton>
          )}
        </AppDialogActions>
      )}
    </AppDialogWindow>
  );
};
AppDialog.defaultProps = {
  agreeAction: () => undefined,
  handleOpen: () => undefined,
};
export default AppDialog;
