import useBreakpoints from "Hooks/useBreakpoints";
import { AppTypography, AppTypographyProps } from "Layout/Components";
import { FormTextFieldProps } from "Layout/FormComponents";
import FormCurrencyInput from "Layout/FormComponents/FormCurrencyInput";
import React from "react";
import styled from "styled-components";
import { TranslationKeys } from "Translation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  > :nth-child(2) {
    margin-left: ${(_) => _.theme.spacing(2)}px;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    width: 300px;
    > * {
      width: 50%;
    }
    > :nth-child(2) {
      margin-left: 0;
    }
    > :nth-child(1) {
      margin-right: ${(_) => _.theme.spacing(2)}px;
    }
  }
`;

export interface DollarInputProps {
  text: TranslationKeys;
  typographyProps?: AppTypographyProps;
}

export const DollarInput: React.FC<DollarInputProps & FormTextFieldProps> = ({
  text,
  typographyProps,
  ...props
}) => {
  const { down } = useBreakpoints();

  return (
    <>
      <Wrapper style={props.style}>
        <AppTypography
          translation={text}
          noWrap
          paddingRight={1.5}
          align={down("md") ? "right" : "left"}
          line="40px"
          style={{ minWidth: 180 }}
          {...typographyProps}
        />
        <InnerWrapper>
          <FormCurrencyInput {...props} />
          {props.children ? props.children : down("md") && <div />}
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default DollarInput;
