import { FC } from "react";
import { PageBreadcrumbs } from "Layout/Components";
import { useTranslate } from "Hooks";
import { useCorporationContext } from "../Contexts/CorporationContext";

export const CorporationBreadcrumbs: FC = () => {
  const t = useTranslate();
  const { corporation } = useCorporationContext();

  return (
    <PageBreadcrumbs
      current={{
        name: corporation?.name || t("COMMON_PAGES_NEWCORPORATION"),
        integrationStatus: corporation?.integrationStatus,
      }}
      path={[]}
    />
  );
};

export default CorporationBreadcrumbs;
