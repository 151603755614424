import { Country, TimeZone, AddressConfiguration } from "API/Interfaces";
import { apiClient, IResponseApi } from "../Auth/apiClient";
import urls from "./urls";

export type GetCountriesResponse = Country[];

const getCountries = async () => {
  const res = await apiClient.get<IResponseApi<GetCountriesResponse>>(
    urls.addresses.countries
  );
  return res.data.data;
};

export type GetTimeZonesResponse = TimeZone[];

const getTimeZones = async (id: number) => {
  const res = await apiClient.get<IResponseApi<GetTimeZonesResponse>>(
    urls.addresses.countriesTimezones(id)
  );
  return res.data.data;
};

export type GetAddressConfigurationResponse = AddressConfiguration;

const getAddressConfiguration = async (id: number) => {
  const res = await apiClient.get<
    IResponseApi<GetAddressConfigurationResponse>
  >(urls.addresses.countriesConfiguration(id));
  return res.data.data;
};

const getAddressByCoordinates = async (lat: string, lng: string) => {
  const res = await new google.maps.Geocoder().geocode({
    location: new google.maps.LatLng({ lat: Number(lat), lng: Number(lng) }),
  });
  return res.results;
};

const getCoordinatesByAddress = async (
  city: string = "",
  zipCode: string = "",
  countryName: string = ""
) => {
  const address = `${city.replace(/\W+/, "")}+${zipCode}+${countryName.replace(
    /\W+/,
    ""
  )}`;
  const res = await new google.maps.Geocoder().geocode({
    address,
  });
  return res.results;
};

export const AddressServiceApi = {
  getCountries,
  getTimeZones,
  getAddressConfiguration,
  getAddressByCoordinates,
  getCoordinatesByAddress,
};
export default AddressServiceApi;
