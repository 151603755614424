import styled from "styled-components";

export const UploadBox = styled.div`
  position: relative;
  height: 168px;
  width: 100%;
  border: 1px dashed ${(_) => _.theme.palette.primary.main};
  border-radius: 6px;
  color: ${(_) => _.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    width: 200px;
    height: 100px;
  }
  cursor: pointer;
`;

export const FilePreviewOverlay = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: #000;
  transition: opacity 0.218s ease;
  &:hover {
    opacity: 0.55;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
