import React from "react";
import { useTranslate } from "Hooks";
import { useHistory } from "react-router-dom";
import { AppDialog, AppTypography } from "Layout/Components";
import { useCsvImportContext } from "../Contexts/CsvImportContext";

export interface DeleteCorporationDialogProps {
  open: boolean;
  onClose: any;
}

export const CancelImportLotDialog: React.FC<DeleteCorporationDialogProps> = ({
  open,
  onClose,
}) => {
  const t = useTranslate();
  const { push } = useHistory();
  const { handleCancelImportProcess } = useCsvImportContext();

  const handleCancelImportLot = () => {
    handleCancelImportProcess();
    onClose(false);
    push("/");
  };

  return (
    <AppDialog
      open={open}
      agreeAction={() => handleCancelImportLot()}
      handleOpen={onClose}
      onClose={() => onClose(false)}
      title={t("DIALOGS_CANCELIMPORTPROCESS")}
      cancelButton={t("DIALOGS_NO")}
      agreeButton={t("DIALOGS_YES")}
    >
      <AppTypography
        variant="body1"
        weight="bold"
        translation="DIALOGS_AREYOUSURETOCANCELTHEIMPORTPROCESS"
      />
    </AppDialog>
  );
};

export default CancelImportLotDialog;
