import { RedirectVariant } from "API/Interfaces";
import useRedirects from "Hooks/useRedirects";
import { FC } from "react";
import { AppButton } from ".";

interface RedirectToValetButtonProps {
  variant: RedirectVariant;
  id: string;
}

export const RedirectToValetButton: FC<RedirectToValetButtonProps> = ({
  variant,
  id,
}) => {
  const { redirectToValet } = useRedirects();

  return (
    <>
      <AppButton
        variant="outlined"
        translation="COMMON_BUTTONS_GOTOVALETADMINPORTAL"
        onClick={() => redirectToValet(variant, id)}
      />
    </>
  );
};

export default RedirectToValetButton;
