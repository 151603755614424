import { IFunctionalitiesData } from "API/Interfaces";
import { PropertiesKeys } from "Utils";
import * as yup from "yup";

export const fieldsNames: PropertiesKeys<IFunctionalitiesData> = {
  valetPortal: "valetPortal",
  enfPortal: "enfPortal",
};
export const validationSchema = yup.object({
  valetPortal: yup.object({
    isEnabled: yup.boolean(),
    isVisible: yup.boolean(),
  }),
  enfPortal: yup.object({
    isEnabled: yup.boolean(),
    isVisible: yup.boolean(),
  }),
});

export const functionalitiesInit = (
  f?: IFunctionalitiesData
): IFunctionalitiesData => {
  return {
    valetPortal: {
      isEnabled: f?.valetPortal.isEnabled ?? false,
      isVisible: f?.valetPortal.isVisible ?? false,
    },
    enfPortal: {
      isEnabled: f?.enfPortal.isEnabled ?? false,
      isVisible: f?.enfPortal.isVisible ?? false,
    },
  };
};
