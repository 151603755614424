import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";

export interface FormCheckboxProps extends CheckboxProps {
  fieldName: string;
  tooltipTitle?: TranslationKeys;
  labelTranslation?: TranslationKeys;
}

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 9px;
`;

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  fieldName,
  color,
  labelTranslation,
  ...props
}) => {
  const t = useTranslate();
  const { values, handleChange } = useFormikContext<any>();

  return (
    <Main>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={values[fieldName]}
            onChange={handleChange}
            name={fieldName}
            color={color || "primary"}
            {...props}
          />
        }
        label={t(labelTranslation)}
      />
    </Main>
  );
};

export default FormCheckbox;
