import { IconButton } from "@material-ui/core";
import { ILotDto } from "API/Interfaces";
import { BigLocationIcon, DeleteIcon } from "assets/icons";
import { useDeleteDialog, useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import {
  AddButton,
  AppButton,
  AppPaper,
  AppTypography,
} from "Layout/Components";
import IntegrationStatusChip from "Layout/Components/IntegrationStatusChip";
import ProductType from "Layout/Components/ProductType";
import SearchInput from "Layout/Components/SearchInput";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { generateRoute } from "Routing/routes";
import { useLocationContext } from "./Contexts/LocationContext";
import {
  ColumnTitle,
  DeleteWrapper,
  EmptyWrapper,
  HeaderButtonWrapper,
  ListTitle,
  LotItem,
  LotName,
  LotsHeader,
  LotWrapper,
  Paging,
  StyledTooltip,
} from "./Components/Lots.styles";

const Lots: React.FC = () => {
  const t = useTranslate();
  const { push } = useHistory();
  const { id, lots } = useLocationContext();
  const {
    data,
    page,
    search,
    setSearch,
    numberOfPages,
    getData,
    getClearData,
    status,
    restData,
  } = lots;
  useEffect(() => {
    !data && getClearData();
  }, [data, getClearData]);

  const [lotToDelete, setLotToDelete] = useState({ id: "", name: "" });
  const [DeleteDialog, openDeleteDialog] = useDeleteDialog(
    "lot",
    "none",
    getClearData
  );
  const handleDeleteClick = (id: string, name: string) => {
    setLotToDelete({ id, name });
    openDeleteDialog();
  };
  const { down } = useBreakpoints();
  const isLastLot = restData?.numberOfLots && restData.numberOfLots <= 1;

  return data ? (
    <div>
      <LotsHeader>
        {!status.error ? (
          <SearchInput
            placeholder="COMMON_FORMS_SEARCHLOT"
            onSubmit={(value) => setSearch(value)}
            value={search}
            showButton
          />
        ) : (
          <div />
        )}
        <HeaderButtonWrapper>
          <AddButton
            onClick={() => push(generateRoute.lot("new"), { locationId: id })}
            translation="COMMON_BUTTONS_ADDNEWLOT"
          />
          {down("md") && (
            <AppButton
              onClick={() => push(`${generateRoute.import(id)}`)}
              translation="COMMON_BUTTONS_IMPORTFROMCSV"
              variant="outlined"
            />
          )}
        </HeaderButtonWrapper>
      </LotsHeader>
      <AppPaper elevation={3} padding={down("md") ? 3 : 1} fullWidth>
        {down("md") && data.length > 0 && (
          <ListTitle>
            <LotWrapper>
              <ColumnTitle>{t("COMMON_LOT")}</ColumnTitle>
            </LotWrapper>
            <DeleteWrapper>
              <ColumnTitle>{t("DIALOGS_DELETE")}</ColumnTitle>
            </DeleteWrapper>
          </ListTitle>
        )}
        <div>
          {data?.length > 0 ? (
            data?.map((l: ILotDto) => (
              <LotItem key={l.id}>
                <LotWrapper>
                  <LotName
                    color="primary"
                    size={1.125}
                    paddingRight={1}
                    onClick={() =>
                      push(generateRoute.lot(l.id), { goBackToList: true })
                    }
                  >
                    {l.name}
                  </LotName>
                  <IntegrationStatusChip
                    integrationStatus={l.integrationStatus}
                  />
                  <ProductType integratedWith={l.integratedWith} />
                </LotWrapper>
                {down("md") && (
                  <StyledTooltip
                    title={t("DIALOGS_ATLEASTONELOT")}
                    disableHoverListener={!isLastLot}
                    arrow
                    placement="top"
                  >
                    <DeleteWrapper>
                      <IconButton
                        disabled={isLastLot}
                        onClick={() => handleDeleteClick(l.id, l.name)}
                      >
                        <DeleteIcon disabled={isLastLot} />
                      </IconButton>
                    </DeleteWrapper>
                  </StyledTooltip>
                )}
              </LotItem>
            ))
          ) : (
            <EmptyWrapper>
              <BigLocationIcon />
              <AppTypography color="secondary" translation="COMMON_NOLOTS" />
            </EmptyWrapper>
          )}
        </div>
      </AppPaper>
      {numberOfPages > 1 && (
        <Paging
          pages={numberOfPages}
          currentPage={page}
          callback={(p: number) => getData(p, search)}
        />
      )}
      <DeleteDialog id={lotToDelete.id} name={lotToDelete.name} />
    </div>
  ) : null;
};

export default Lots;
