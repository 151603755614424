import { Tooltip } from "@material-ui/core";
import { EntityType } from "API/Interfaces";
import { Form, Formik } from "formik";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPaper, AppTypography } from "Layout/Components";
import AppSwitch from "Layout/Components/AppSwitch";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { FormCancelButton, FormSubmitButton } from "Layout/FormComponents";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "Routing/routes";
import styled from "styled-components";
import useFunctionalities from "./Hooks/useFunctionalities";
import { fieldsNames, validationSchema } from "./schema";

interface FunctionalitiesAndProductsProps {
  entityType: EntityType;
  getData: () => void;
}

const SwitchWrapper = styled.div`
  margin: 12px 0 16px 0;
  > span > label > span,
  > label > span {
    font-weight: 700;
  }
`;

export const FunctionalitiesAndProducts: FC<
  FunctionalitiesAndProductsProps
> = ({ entityType, getData }) => {
  const t = useTranslate();
  const tooltipTitle = t(
    entityType === EntityType.Location
      ? "DIALOGS_DISABLEDONCORPORATION"
      : "DIALOGS_DISABLEDONLOCATION"
  );
  const { push } = useHistory();
  const { down } = useBreakpoints();
  const { functionalities, getFormData, sendFormData } = useFunctionalities(
    entityType,
    getData
  );

  useEffect(() => {
    !functionalities && getFormData();
  }, [functionalities, getFormData]);

  return (
    functionalities && (
      <AppPaper elevation={3} padding={down("md") ? 3 : 2} fullWidth>
        <Formik
          initialValues={functionalities}
          validationSchema={validationSchema}
          onSubmit={(v, actions) => {
            sendFormData(v);
            if (
              v.valetPortal.isEnabled === false &&
              functionalities.valetPortal.isEnabled
            ) {
              actions.resetForm({ values: functionalities });
            }
          }}
          enableReinitialize
        >
          {({ values, handleChange }) => (
            <>
              <Form>
                <AppTypography
                  variant="h2"
                  translation="COMMON_PAGES_PARKINGMANAGEMENT"
                />
                <SwitchWrapper>
                  <AppSwitch
                    name={`${fieldsNames.valetPortal}.isEnabled`}
                    checked={values.valetPortal.isEnabled}
                    value={values.valetPortal.isEnabled}
                    onChange={handleChange}
                    disabled={!functionalities.valetPortal.isVisible}
                    translationLabel="COMMON_PAGES_VALETADMINPORTAL"
                  />
                </SwitchWrapper>
                <AppTypography
                  variant="h2"
                  translation="ENUMS_SYSTEMS_ENFORCEMENT"
                />
                <SwitchWrapper>
                  <Tooltip
                    arrow
                    title={tooltipTitle}
                    disableHoverListener={functionalities.enfPortal.isVisible}
                  >
                    <span>
                      <AppSwitch
                        name={`${fieldsNames.enfPortal}.isEnabled`}
                        checked={values.enfPortal.isEnabled}
                        value={values.enfPortal.isEnabled}
                        onChange={handleChange}
                        disabled={!functionalities.enfPortal.isVisible}
                        translationLabel="ENUMS_SYSTEMS_ENFORCEMENT"
                      />
                    </span>
                  </Tooltip>
                </SwitchWrapper>

                <ButtonWrapper>
                  <FormCancelButton onClick={() => push(routes.root)} />
                  <FormSubmitButton />
                </ButtonWrapper>
              </Form>
            </>
          )}
        </Formik>
      </AppPaper>
    )
  );
};

export default FunctionalitiesAndProducts;
