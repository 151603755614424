import { SmallDeleteIcon } from "assets/icons/Icons";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { FC } from "react";
import styled from "styled-components";
import { AppButton, AppButtonProps } from ".";

interface DeleteButtonProps extends AppButtonProps {}

const StyledButton = styled(AppButton)`
  height: 36px;
  color: ${(_) => _.theme.palette.danger.main};
  border-color: ${(_) => _.theme.palette.danger.main};
  > .MuiButton-startIcon {
    max-height: 10px;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    color: ${(_) => _.theme.palette.primary.main};
    border-color: ${(_) => _.theme.palette.primary.main};
    height: unset;
  }
`;

export const DeleteButton: FC<DeleteButtonProps> = ({
  translation,
  ...rest
}) => {
  const t = useTranslate();
  const { down } = useBreakpoints();

  return (
    <StyledButton
      startIcon={<SmallDeleteIcon />}
      minWidth={60}
      {...rest}
      variant="outlined"
    >
      {down("md") && t(translation)}
    </StyledButton>
  );
};

export default DeleteButton;
