import { User } from "../Contexts/AuthContext";

const set = (tokens: User) => {
  localStorage.setItem("tokens", JSON.stringify(tokens));
};

const get = () => {
  const tokensStorage = localStorage.getItem("tokens");
  if (!tokensStorage) return null;

  const tokens: User = JSON.parse(tokensStorage);
  return tokens;
};

const remove = () => {
  localStorage.removeItem("tokens");
};

export const tokensStorageService = {
  set,
  get,
  remove,
};

export default tokensStorageService;
