import { useFormikContext } from "formik";
import React, { createContext, useContext } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { FormTextField, FormTextFieldProps } from ".";

export type FormNumberInputProps = {
  fieldName: string;
  numberFieldProps?: NumberFormatProps<any>;
} & FormTextFieldProps;

export type NumberFormatContextType = NumberFormatProps<any>;
const NumberFormatContext = createContext<NumberFormatContextType>({});
const useNumberFormatContext = () => useContext(NumberFormatContext);

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumberFormatCustom = React.forwardRef<
  NumberFormat<any>,
  NumberFormatCustomProps
>((props, ref) => {
  const { onChange, ...other } = props;
  const numberFormatProps = useNumberFormatContext();
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={({ value }) =>
        onChange({ target: { name: props.name, value: value } })
      }
      {...numberFormatProps}
    />
  );
});

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  fieldName,
  numberFieldProps = {},
  ...rest
}) => {
  const { values, handleChange } = useFormikContext<any>();
  return (
    <NumberFormatContext.Provider value={numberFieldProps}>
      <FormTextField
        {...rest}
        fieldName={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        InputLabelProps={{
          shrink: values[fieldName] !== null && values[fieldName] !== "",
        }}
      />
    </NumberFormatContext.Provider>
  );
};

export default FormNumberInput;
