import { TimeZone } from "API/Interfaces";
import { AddressServiceApi } from "API/Services";
import { useFormikContext } from "formik";
import { FormComponentsFields } from "Layout/FormComponents/types";
import { useEffect, useState } from "react";

export const useTimeZonesCountryChange = () => {
  const [timezones, setTimezones] = useState<TimeZone[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormComponentsFields>();

  useEffect(() => {
    setIsLoading(true);
    AddressServiceApi.getTimeZones(values.country)
      .then(setTimezones)
      .finally(() => setIsLoading(false));
  }, [setFieldValue, values.country]);

  useEffect(() => {
    if (timezones.length > 0) {
      const timezone = timezones.find((el) => el.name === values.timezone);
      if (timezone) return;
      else setFieldValue("timezone", timezones[0].name);
    }
  }, [setFieldValue, timezones, values.timezone]);

  return { timezones, isLoading };
};

export default useTimeZonesCountryChange;
