import LotBreadcrumbs from "./Components/LotBreadcrumbs";
import LotDetailsFrom from "./Components/LotDetailsForm/LotDetailsForm";
import LotProvider, { useLotContext } from "./Contexts/LotContext";
import React, { useState } from "react";
import usePageName from "Hooks/usePageName";
import { AddressConfigurationProvider } from "Utils";
import { EntityType, LotTabs } from "API/Interfaces";
import { PageDetailsHeader } from "Layout/Components";
import { Tab, Tabs } from "@material-ui/core";
import { useTranslate } from "Hooks";
import FunctionalitiesAndProducts from "Layout/FormComponents/FunctionalitiesAndProducts";

const LotDetailsPage: React.FC = () => {
  usePageName("COMMON_PAGES_LOTDETAILS");
  const t = useTranslate();
  const [currentTab, setCurrentTab] = useState(LotTabs.GeneralInfo);
  const { id, getLot } = useLotContext();
  return (
    <>
      <PageDetailsHeader hideBorder={false}>
        <LotBreadcrumbs />
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setCurrentTab(v)}
        >
          <Tab label={t("COMMON_PAGES_GENERALINFO")} />
          {id && <Tab label={t("COMMON_PAGES_FUNCTIONALITIESANDPRODUCTS")} />}
        </Tabs>
      </PageDetailsHeader>
      {currentTab === LotTabs.GeneralInfo && <LotDetailsFrom />}
      {currentTab === LotTabs.FunctionalitiesAndProducts && (
        <FunctionalitiesAndProducts
          getData={getLot}
          entityType={EntityType.Lot}
        />
      )}
    </>
  );
};

const LotDetails = () => (
  <AddressConfigurationProvider>
    <LotProvider>
      <LotDetailsPage />
    </LotProvider>
  </AddressConfigurationProvider>
);

export default LotDetails;
