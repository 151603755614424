import React from "react";
import styled from "styled-components";
import { AppTypography } from "./AppTyphography";

export interface AppEmptyInformationProps {
  text: string;
}

const EmptyInformation = styled(AppTypography)`
  padding: 10px 15px 0;
  color: ${({ theme }) => theme.palette.neutral.main};
`;

const AppEmptyInformation: React.FC<AppEmptyInformationProps> = (props) => {
  return <EmptyInformation>{props.text}</EmptyInformation>;
};

export default AppEmptyInformation;
