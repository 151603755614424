import React from "react";
import { UploadBox } from "./FileUpload.styles";
import useFileUpload from "Hooks/useFileUpload";
import FilePreview from "./FilePreview";
import FileUploadForm from "./FileUploadForm";
import { Box } from "@material-ui/core";
import { AppTypography } from "Layout/Components";
import useBreakpoints from "Hooks/useBreakpoints";
import { IDataLevel } from "API/Interfaces";

export interface FileUploadProps {
  id: string;
  type: IDataLevel;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { file, setFile, message, status, getRootProps, getInputProps } =
    useFileUpload(props);
  const { up } = useBreakpoints();

  return (
    <>
      <UploadBox {...getRootProps()}>
        {file ? (
          <FilePreview
            file={file}
            setFile={setFile}
            isPhoto={props.type === "lot"}
          />
        ) : (
          <FileUploadForm
            inputProps={getInputProps()}
            message={message}
            isLoading={status.loading}
            isError={status.error}
            isPhoto={props.type === "lot"}
          />
        )}
      </UploadBox>
      {!up("md") && (
        <Box display="flex" alignItems="center" marginLeft={2} width="300px">
          <AppTypography
            weight={700}
            size={0.9}
            color={status.error ? "error" : "textSecondary"}
          >
            {message}
          </AppTypography>
        </Box>
      )}
    </>
  );
};

export default FileUpload;
