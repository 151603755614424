import { FormControlLabel, Switch, SwitchProps } from "@material-ui/core";
import { useTranslate } from "Hooks";
import { FC } from "react";
import { TranslationKeys } from "Translation";

export interface AppSwitchProps extends SwitchProps {
  translationLabel?: TranslationKeys;
}

export const AppSwitch: FC<AppSwitchProps> = ({
  translationLabel,
  ...rest
}) => {
  const t = useTranslate();
  return (
    <FormControlLabel
      control={<Switch color="primary" {...rest} />}
      label={t(translationLabel)}
    />
  );
};

export default AppSwitch;
