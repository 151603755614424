import { Corner } from "assets/icons/menu";
import ExpandedPanelContext from "Contexts/ExpandedPanelContext";
import useBreakpoints from "Hooks/useBreakpoints";
import React, { useContext } from "react";
import styled from "styled-components";
import Logo from "../assets/images/Logo";
import ApplicationNav from "./ApplicationNav";
import ExpandButton from "./Components/ExpandButton";

const Drawer = styled.div<{ $isExpanded: boolean }>`
  background-color: #7b59fb;
  position: relative;
  min-width: 135px;
  transition: min-width 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10;
  ${(_) => _.theme.breakpoints.up("md")} {
    min-width: ${(_) => (_.$isExpanded ? 350 : 135)}px;
  }
`;

const BrandSection = styled.div`
  height: 130px;
  width: 100%; //135px if want to logo not move
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApplicationDrawer: React.FC = () => {
  const { isExpanded, setIsExpanded } = useContext(ExpandedPanelContext);
  const { up } = useBreakpoints();

  return (
    <Drawer $isExpanded={isExpanded && !up("xl")}>
      <BrandSection>
        <Logo />
      </BrandSection>
      <ApplicationNav />
      {!up("xl") && (
        <ExpandButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}
      <Corner />
    </Drawer>
  );
};

export default ApplicationDrawer;
