import React from "react";
import pinIcon from "./pin.svg";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { config } from "API/Services";

type Position = {
  lat: number;
  lng: number;
};
interface Props {
  position: Position;
  center: Position;
  zoom: number;
  width?: number | string;
  height?: number | string;
  onClick: (arg: Position) => void;
}

export const MapComponent: React.FC<Props> = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GEOCODE_API_KEY,
    libraries: ["places"],
  });

  const { position, width = "100%", height = "100%", onClick, ...rest } = props;
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width, height }}
      options={{ keyboardShortcuts: false }}
      onClick={(e) =>
        onClick({
          lat: e?.latLng?.lat() || 0,
          lng: e?.latLng?.lng() || 0,
        })
      }
      {...rest}
    >
      <Marker position={position} icon={pinIcon} />
    </GoogleMap>
  ) : (
    <></>
  );
};
