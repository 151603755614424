import { ListItemIcon } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ISearchElements } from "API/Interfaces";
import HighlightedTypography from "Layout/Components/HighlightedTypography";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { generateRoute } from "Routing/routes";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  .MuiListItemIcon-root {
    min-width: auto;
    margin: 0 10px;
  }
  > * {
    ${(_) => {
      let parts = 0;
      React.Children.forEach(_.children, (child) => {
        if (!React.isValidElement(child)) return;
        parts = parts + 1;
      });
      return css`
        max-width: calc(100% / ${parts});
        display: flex;
        align-items: center;
      `;
    }}

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const ElementWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ElementName: React.FC<{
  name: string;
  route: string;
  onClick: () => void;
  noIcon?: boolean;
  searchPhrase: string;
}> = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const Text = () => (
    <HighlightedTypography
      onClick={() => {
        props.onClick();
        if (pathname.includes("corporations")) {
          push(props.route, { goToAnotherTab: true });
        } else {
          push(props.route);
        }
      }}
      text={props.name}
      highlightPhrase={props.searchPhrase}
      pointer
    />
  );

  if (props.noIcon) return <Text />;
  return (
    <ElementWrapper>
      <ListItemIcon>
        <ArrowForwardIcon color="disabled" />
      </ListItemIcon>
      <Text />
    </ElementWrapper>
  );
};

export interface GlobalSearchItemProps extends ISearchElements {
  onClick: () => void;
  searchPhrase: string;
}

export const GlobalSearchListItem: React.FC<GlobalSearchItemProps> = ({
  corporationInfo,
  locationInfo,
  lotInfo,
  onClick,
  searchPhrase,
}) => {
  if (!corporationInfo) return null;
  return (
    <Wrapper>
      {corporationInfo && (
        <ElementName
          name={corporationInfo.name}
          route={generateRoute.corporation(corporationInfo?.id)}
          onClick={onClick}
          noIcon
          searchPhrase={searchPhrase}
        />
      )}
      {locationInfo && (
        <ElementName
          name={locationInfo.name}
          route={generateRoute.location(locationInfo.id)}
          onClick={onClick}
          searchPhrase={searchPhrase}
        />
      )}
      {locationInfo && lotInfo && (
        <ElementName
          name={lotInfo.name}
          route={generateRoute.lot(lotInfo.id)}
          onClick={onClick}
          searchPhrase={searchPhrase}
        />
      )}
    </Wrapper>
  );
};

export default GlobalSearchListItem;
