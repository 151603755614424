import { useFormikContext } from "formik";
import React from "react";
import { MapFields } from "./types";

export const useMap = () => {
  const { values, setFieldValue } = useFormikContext<MapFields>();
  const { center, markerPosition } = values;
  const mapRef: any = React.useRef();
  const onMapMounted = (ref: any) => {
    mapRef.current = ref;
  };
  const onZoomChanged = () => {
    setFieldValue("zoom", mapRef.current?.getZoom());
  };
  const setMarkerPosition = async (arg: { lat: number; lng: number }) => {
    setFieldValue("markerPosition", arg);
  };
  const setCenter = async (arg: { lat: number; lng: number }) => {
    setFieldValue("zoom", 15);
    setFieldValue("center", arg);
  };

  return {
    setMarkerPosition,
    setCenter,
    center,
    markerPosition,
    zoom: values.zoom,
    onMapMounted,
    onZoomChanged,
  };
};

export default useMap;
