import {
  DefaultFormAddressConfigurationFieldProps,
  FormComponentsAddressFields,
  FormTextFieldComponent,
} from "API/Interfaces";
import { getFieldName } from "Utils";
import FormTextField from "./FormTextField";
import { FormTextFieldWithAddressConfiguration } from "./FormTextFieldWithAddressConfiguration";

const defaultProps: DefaultFormAddressConfigurationFieldProps = {
  fieldName: getFieldName<FormComponentsAddressFields>("addressPostalCode"),
  labelTranslation: "COMMON_FORMS_POSTALCODE",
};

export const FormPostalCodeField: FormTextFieldComponent = (props) => (
  <FormTextField {...defaultProps} {...props} />
);

export const FormPostalCodeFieldWithAddressConfig: FormTextFieldComponent = (
  props
) => {
  return <FormTextFieldWithAddressConfiguration {...defaultProps} {...props} />;
};

export default FormPostalCodeField;
