import { FC } from "react";
import styled, { css } from "styled-components";

interface PageDetailsHeaderProps {
  hideBorder: boolean;
}

const DetailsHeader = styled.div<{ $hideBorder: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: flex-start;
  padding: 10px;
  ${({ $hideBorder, theme }) =>
    $hideBorder &&
    css`
      border-bottom: ${`1px solid ${theme.palette.neutral.main}`};
      padding-bottom: 10px;
    `}
  ${(_) => _.theme.breakpoints.up("xl")} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const PageDetailsHeader: FC<PageDetailsHeaderProps> = ({
  hideBorder,
  children,
}) => {
  return <DetailsHeader $hideBorder={hideBorder}>{children}</DetailsHeader>;
};

export default PageDetailsHeader;
