import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Corporations from "Pages/Corporations/Corporations";
import CorporationDetails from "Pages/CorporationDetails/CorporationDetails";
import LocationDetails from "Pages/LocationDetails/LocationDetails";
import LotDetails from "Pages/LotDetails/LotDetails";
import ImportFromCsv from "Pages/ImportFromCsv/ImportFromCsv";
import { routes } from "./routes";
import ApplicationLayout from "Layout/ApplicationLayout";
import { AddressProvider } from "Contexts";

const AuthenticatedRouter = () => {
  return (
    <AddressProvider>
      <Router>
        <ApplicationLayout>
          <Switch>
            <Route
              exact
              path={routes.corporationList}
              component={Corporations}
            />
            <Route
              exact
              path={routes.corporation}
              component={CorporationDetails}
            />
            <Route exact path={routes.location} component={LocationDetails} />
            <Route exact path={routes.lot} component={LotDetails} />
            <Route exact path={routes.import} component={ImportFromCsv} />
            <Redirect from={routes.root} to={routes.corporationList} />
          </Switch>
        </ApplicationLayout>
      </Router>
    </AddressProvider>
  );
};

export default AuthenticatedRouter;
