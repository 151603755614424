import CorporationDetailsForm from "./Components/CoporationForm/CorporationDetailsForm";
import RedirectToValetButton from "Layout/Components/RedirectToValetButton";
import styled from "styled-components";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPaper, AppTypography, DeleteButton } from "Layout/Components";
import { FC } from "react";
import { Grid } from "@material-ui/core";
import { RedirectVariant } from "API/Interfaces";
import { Wrapper } from "Layout/Components/BasicStyledComponents";
import { useCorporationContext } from "./Contexts/CorporationContext";
import useDeleteCorporation from "./Hooks/useDeleteCorporation";

const GeneralHeader = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const GeneralInfo: FC = () => {
  const { id, corporation } = useCorporationContext();
  const [DeleteDialog, openDeleteDialog] = useDeleteCorporation();
  const { down } = useBreakpoints();

  return (
    <>
      <AppPaper elevation={3} padding={down("md") ? 3 : 1} fullWidth>
        <GeneralHeader
          container
          item
          justifyContent="space-between"
          alignItems="center"
        >
          <AppTypography variant="h2" translation="COMMON_PAGES_GENERAL" />
          {id && (
            <Wrapper>
              {corporation?.isEnabledRedirectToValet && down("md") && (
                <RedirectToValetButton
                  variant={RedirectVariant.Corporation}
                  id={id}
                />
              )}
              <DeleteButton
                onClick={() => openDeleteDialog()}
                translation="COMMON_BUTTONS_DELETECORPORATION"
              />
            </Wrapper>
          )}
        </GeneralHeader>
        <CorporationDetailsForm />
      </AppPaper>
      <DeleteDialog id={id} name={corporation?.name || ""} />
    </>
  );
};

export default GeneralInfo;
