import { FormTextField, FormTextFieldProps } from "Layout/FormComponents";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMarkInformation from "../ErrorMarkInformation";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";

export interface InputWithErrorProps {
  errors?: ImportErrors[];
}

export type Props = InputWithErrorProps & FormTextFieldProps;

const InputWithError: React.FC<Props> = ({
  errors,
  fieldName,
  labelTranslation,
  ...props
}) => {
  return (
    <FormTextField
      fieldName={fieldName}
      labelTranslation={labelTranslation}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <ErrorMarkInformation errors={errors} fieldName={fieldName} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputWithError;
