import { ILotDto } from "API/Interfaces";
import LotServiceApi from "API/Services/LotServiceApi";
import { useGenericList } from "Hooks";
import { useParams } from "react-router-dom";

const useLots = () => {
  const { id }: { id: string } = useParams();
  return useGenericList<ILotDto>(LotServiceApi.getLots, id, false);
};

export default useLots;
