import { removeAuthHeader, setAuthHeader } from "API/Auth/apiClient";
import { UserRoles } from "API/Interfaces/Enums";
import { AuthServiceApi, IAuthTokens } from "API/Services";
import React, { useCallback, useContext } from "react";
import {
  convertUserInfo,
  isAuthTokenActive,
  isRefreshTokenActive,
  tokensStorageService,
} from "../utils";
import { useFetchStatus } from "Hooks";
import { IFetchStatusObj, ISetFetchStatusObj } from "API/Interfaces";

export type User = {
  firstName: string;
  lastName: string;
  roles: UserRoles;
  sessionId: string;
} & IAuthTokens;

export type AuthContextType = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  unsetUser: () => void;
  status: IFetchStatusObj;
  setStatus: ISetFetchStatusObj;
};

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  setUser: () => undefined,
  unsetUser: () => undefined,
  status: {} as IFetchStatusObj,
  setStatus: {} as ISetFetchStatusObj,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC = (props) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [setStatus, status] = useFetchStatus();

  const unsetUser = useCallback(() => {
    removeAuthHeader();
    setUser(null);
    tokensStorageService.remove();
    setStatus.success();
  }, [setStatus]);

  const autoLogin = useCallback(async () => {
    const tokens = tokensStorageService.get();
    if (tokens && isAuthTokenActive(tokens)) {
      setUser(tokens);
      setAuthHeader(tokens.auth);
      setStatus.success();
    } else if (tokens && isRefreshTokenActive(tokens)) {
      try {
        setStatus.loading();
        setAuthHeader(tokens.refresh);
        const res = await AuthServiceApi.refreshToken();
        const user = { ...convertUserInfo(res.tokens), roles: res.roles };
        setUser(user);
        tokensStorageService.set(user);
        setAuthHeader(res.tokens.auth);
        setStatus.success();
      } catch {
        unsetUser();
      }
    } else unsetUser();
  }, [setStatus, setUser, unsetUser]);

  React.useEffect(() => {
    autoLogin();
  }, [autoLogin]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        unsetUser,
        status,
        setStatus,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
