import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styled from "styled-components";

export interface AppBreadcrumbsProps {}

const StyledIcon = styled(FiberManualRecordIcon)`
  color: ${({ theme }) => theme.palette.neutral.main};
`;

const AppBreadcrumbs: React.FC<AppBreadcrumbsProps> = (props) => {
  return (
    <Breadcrumbs
      separator={<StyledIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {props.children}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
