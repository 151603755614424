import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { ISearchAllResponse } from "API/Interfaces";
import { useTranslate } from "Hooks";
import { AppDialogWindow, AppTypography } from "Layout/Components";
import SearchInput from "Layout/Components/SearchInput";
import { FormCancelButton } from "Layout/FormComponents";
import React from "react";
import GlobalSearchList from "./GlobalSearchList";
import { MoreResults } from "./MoreResults";
import useGlobalSearch from "./useGlobalSearch";
import styled from "styled-components";
const Title = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export interface GlobalSearchProps {}

export const GlobalSearch: React.FC<GlobalSearchProps> = (props) => {
  const t = useTranslate();
  const [searchAll, { loading }, { searchPhrase, numberOfPagesTotal }] =
    useGlobalSearch();
  const [showDialog, setShowDialog] = React.useState(false);
  const [list, setList] = React.useState<ISearchAllResponse["routes"]>([]);
  const [loadedPage, setLoadedPage] = React.useState(0);
  const [numberOfResults, setNumberOfResults] = React.useState<number>(0);

  const onSubmit = async (arg: string) => {
    try {
      const res = await searchAll(arg);
      setList(res.routes);
      setNumberOfResults(res.numberOfTotalRows);
      setShowDialog(true);
      setLoadedPage(1);
    } catch (error) {}
  };

  const loadMore = async (arg: string, loadedPage: number) => {
    try {
      const currentPage = loadedPage + 1;
      const res = await searchAll(arg, currentPage);
      setList([...list, ...res.routes]);
      setShowDialog(true);
      setLoadedPage(currentPage);
    } catch (error) {}
  };
  return (
    <>
      <SearchInput
        showButton
        onSubmit={onSubmit}
        placeholder={"COMMON_FORMS_GLOBALSEARCH"}
        loading={loading && !showDialog}
        required
      />
      <AppDialogWindow
        open={showDialog}
        $maxHeight={550}
        maxWidth="md"
        onClose={() => setShowDialog(false)}
      >
        <Title disableTypography>
          <AppTypography
            translation="DIALOGS_SEARCHRESULT"
            weight="bold"
            variant="h2"
            size={1.5}
          />
          <div>
            <AppTypography weight="bold" align="right" size={1}>
              {`${numberOfResults} ${t(
                numberOfResults === 1 ? "COMMON_RESULT" : "COMMON_RESULTS"
              )}`}
            </AppTypography>
            <AppTypography align="right" size={1}>
              {`${t("DIALOGS_PHRASE")} "${searchPhrase}"`}
            </AppTypography>
          </div>
        </Title>
        <DialogContent style={{ overflowY: "auto" }}>
          {!list.length ? (
            t("DIALOGS_NOSEARCHRESULTS")
          ) : (
            <GlobalSearchList
              hideDialog={() => setShowDialog(false)}
              list={list}
              searchPhrase={searchPhrase}
            >
              {loadedPage < numberOfPagesTotal && (
                <MoreResults
                  loading={loading}
                  onClick={() => loadMore(searchPhrase, loadedPage)}
                />
              )}
            </GlobalSearchList>
          )}
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setShowDialog(false)} />
        </DialogActions>
      </AppDialogWindow>
    </>
  );
};

export default GlobalSearch;
