import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";
export interface AppTableCellProps extends TableCellProps {
  translateLabel: TranslationKeys;
}

const AppTableCell: React.FC<AppTableCellProps> = ({
  translateLabel,
  ...props
}) => {
  const t = useTranslate();
  return <TableCell {...props}>{t(translateLabel)}</TableCell>;
};

export default AppTableCell;
