import { FormHelperText, FormHelperTextProps } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

type TextAling = React.CSSProperties["textAlign"];

export interface AppFormHelperTextProps {
  absolute?: boolean;
  //** in ems */
  fontSize?: number;
  component?: string;
  textAlign?: TextAling;
}
const StyledFormHelperText = styled(FormHelperText)<{
  $absolute?: boolean;
  $fontSize?: number;
  $textAlign?: TextAling;
}>`
  ${(_) =>
    _.$absolute &&
    css`
      position: absolute;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
    `}
  ${(_) =>
    _.$fontSize &&
    css`
      font-size: ${_.$fontSize}em;
    `}
  ${(_) =>
    _.$textAlign &&
    css`
      text-align: ${_.$textAlign};
    `}
`;

export const AppFormHelperText: React.FC<
  AppFormHelperTextProps & FormHelperTextProps
> = ({ absolute, fontSize, textAlign, ...props }) => {
  return (
    <StyledFormHelperText
      $absolute={absolute}
      $fontSize={fontSize}
      $textAlign={textAlign}
      {...props}
    />
  );
};

export default AppFormHelperText;
