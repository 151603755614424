import { IntegrationStatus, integrationStatusLabel } from "API/Interfaces";
import React from "react";
import styled from "styled-components";
import { AppTypography } from ".";

export interface IntegrationStatusChipProps {
  integrationStatus?: IntegrationStatus | null;
}
const statusBackground: {
  [key in IntegrationStatus]: string;
} = {
  [IntegrationStatus.Pending]: "#B4B4B466",
  [IntegrationStatus.Created]: "#f6a80066",
  [IntegrationStatus.Active]: "#65de4766",
  [IntegrationStatus.Terminated]: "#FF5D5D66",
};

const ChipWrapper = styled.div<{ $status: IntegrationStatus }>`
  height: min-content;
  padding: 1px 6px;
  border-radius: 4px;
  background-color: ${({ $status }) => statusBackground[$status]};
`;

const ChipText = styled(AppTypography)`
  word-break: keep-all;
`;

const IntegrationStatusChip: React.FC<IntegrationStatusChipProps> = ({
  integrationStatus,
}) => {
  return integrationStatus ? (
    <ChipWrapper $status={integrationStatus}>
      <ChipText
        size={0.875}
        color="textSecondary"
        translation={integrationStatusLabel[integrationStatus]}
      />
    </ChipWrapper>
  ) : null;
};

export default IntegrationStatusChip;
