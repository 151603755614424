import {
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  PopupProvider,
  LoaderProvider,
  AppSnackbarProvider,
  PageNameProvider,
} from "Contexts";
import { ExpandedPanelProvider } from "Contexts/ExpandedPanelContext";
import { theme } from "Layout/theme";
import { AuthProvider } from "Pages/Auth";
import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import AppRouter from "./Routing/AppRouter";
import { ErrorProvider } from "Contexts/ErrorContext";

const App: React.FC = () => {
  return (
    <ExpandedPanelProvider>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline />
            <AppSnackbarProvider>
              <LoaderProvider>
                <PopupProvider>
                  <ErrorProvider>
                    <AuthProvider>
                      <PageNameProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <AppRouter />
                        </LocalizationProvider>
                      </PageNameProvider>
                    </AuthProvider>
                  </ErrorProvider>
                </PopupProvider>
              </LoaderProvider>
            </AppSnackbarProvider>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ExpandedPanelProvider>
  );
};

export default App;
