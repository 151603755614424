import { GeocodeResult, TimeZone } from "API/Interfaces";
import { ErrorCodes } from "API/Interfaces/Errors";
import { i18n, TranslationKeys, Translations } from "Translation";

export const getFieldName = <T>(field: keyof T) => field;
export const stringVal = (val?: string | null | undefined) => {
  if (!val) return "";
  return val;
};
export const getAddressFields = (arg?: GeocodeResult, withLocation = true) => {
  if (!arg)
    return {
      county: "",
      city: "",
      state: "",
      street: "",
      postalCode: "",
      countryCode: "",
    };
  let streetNumber = stringVal(
    arg.address_components.find((el) => el.types.includes("street_number"))
      ?.long_name
  );
  streetNumber += streetNumber ? " " : "";
  const streetAddress = stringVal(
    arg.address_components.find((el) => el.types.includes("route"))?.long_name
  );
  const county = arg.address_components.find((el) =>
    el.types.includes("administrative_area_level_2")
  )?.long_name;
  const state = arg.address_components.find((el) =>
    el.types.includes("administrative_area_level_1")
  )?.long_name;
  const city = arg.address_components.find((el) =>
    el.types.includes("locality")
  )?.long_name;
  const postalCode = arg.address_components.find((el) =>
    el.types.includes("postal_code")
  )?.long_name;
  const location = withLocation && {
    lat: arg.geometry.location.lat(),
    lng: arg.geometry.location.lng(),
  };
  const countryCode =
    arg.address_components.find((el) => el.types.includes("country"))?.[
      "short_name"
    ] || "";
  return {
    county: stringVal(county),
    city: stringVal(city),
    state: stringVal(state),
    postalCode: stringVal(postalCode),
    street: streetNumber + streetAddress,
    location,
    countryCode,
  };
};
export const convertBytesToKB = (bytes: any) => Math.round(bytes / 1000);

export const isTimeZoneValid = (zoneName: string, zones: TimeZone[]) => {
  return zones.some(({ name }) => name === zoneName);
};

export const getErrorCode = (error: any) => {
  if (!error?.response?.data?.errors?.length) return ErrorCodes.OtherError;
  if (error.response.data.errors[0].errorCode) {
    return error.response.data.errors[0].errorCode as number;
  }
  return ErrorCodes.OtherError;
};

export const isErrorCode = (err: any, arg: keyof typeof ErrorCodes) => {
  const errorCode = err?.errorCode ? err.errorCode : getErrorCode(err);
  return errorCode === ErrorCodes[arg];
};

export const i18nTranslation = (t: TranslationKeys) => i18n.t(Translations[t]);

export const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const convertDMS = (coordinate: number, type: "lat" | "lng") => {
  const { abs, floor } = Math;
  if (type === "lat" && (coordinate < -90 || coordinate > 90)) return " -";
  if (type === "lng" && (coordinate < -180 || coordinate > 180)) return " - ";
  const absolute = abs(coordinate);
  const degrees = floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = floor(minutesNotTruncated);
  const seconds = floor((minutesNotTruncated - minutes) * 60 * 1000) / 1000;

  const pos = { lat: "N", lng: "E" };
  const neg = { lat: "S", lng: "W" };
  const cardinal = coordinate >= 0 ? pos[type] : neg[type];

  return `${degrees}° ${minutes}' ${seconds}" ${cardinal}`;
};
