import React from "react";
import { TranslationKeys } from "Translation";
import useTranslate from "./useTranslations";
import { usePageNameContext } from "Contexts";

const usePageName = (pageName: TranslationKeys) => {
  const t = useTranslate();
  const { setPageName } = usePageNameContext();
  React.useEffect(() => {
    setPageName(t(pageName));
  }, [pageName, setPageName, t]);
};

export default usePageName;
