import {
  ImportCsvServiceApi,
  IImportRequest,
} from "API/Services/ImportCsvServiceApi";
import { useLoaderContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";

const useSendImportData = () => {
  const { setLoading } = useLoaderContext();
  const { setError } = useErrorContext();

  const sendCsv = async (id: string, csv: IImportRequest) => {
    setLoading(true);
    const response = await ImportCsvServiceApi.sendImportData(id, csv)
      .then((res) => res)
      .catch(() => setError())
      .finally(() => setLoading(false));
    return response;
  };

  return { sendCsv } as const;
};

export default useSendImportData;
