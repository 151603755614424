import { InputAdornment, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Formik } from "formik";
import { useTranslate } from "Hooks";
import { FormSubmitButton } from "Layout/FormComponents";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { i18n, TranslationKeys, Translations } from "Translation";
import { SearchIcon } from "../../assets/icons/Icons";
import * as yup from "yup";
import useBreakpoints from "Hooks/useBreakpoints";

export interface SearchInputProps {
  onSubmit: (arg: string) => void;
  placeholder?: TranslationKeys;
  showButton?: boolean;
  loading?: boolean;
  value?: string;
  required?: boolean;
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const SearchButton = styled(FormSubmitButton)`
  margin-left: 14px;
`;

const helperTextStyle: CSSProperties = {
  color: "#f44336",
  width: "max-content",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      border: "1px solid #C4C4C4",
      borderRadius: "19px",
      outline: "none",
      padding: "11px 13px",
      minWidth: "250px;",
      fontSize: "14px",
      fontWeight: 400,
      color: "#000",
      maxHeight: "38px",
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  })
);

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "COMMON_BUTTONS_SEARCH",
  showButton,
  onSubmit,
  loading,
  value = "",
  required,
}) => {
  const t = useTranslate();
  const classes = useStyles();
  const { down } = useBreakpoints();
  const schema = {
    search: yup
      .string()
      .min(3, i18n.t(Translations.COMMON_FORMS_MINCHARACTER3))
      .nullable(),
  };
  if (required)
    schema.search = schema.search.required(
      i18n.t(Translations.COMMON_FORMS_FIELDISREQUIRED)
    );
  return (
    <Formik
      initialValues={{ search: value }}
      onSubmit={(v) => onSubmit(v.search)}
      validationSchema={yup.object(schema)}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, errors }) => (
        <form onSubmit={handleSubmit}>
          <SearchWrapper>
            <TextField
              name="search"
              placeholder={t(placeholder) + "..."}
              variant="standard"
              onChange={handleChange}
              helperText={errors["search"] || ""}
              FormHelperTextProps={{
                style: helperTextStyle,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes,
                type: "search",
              }}
            />

            {showButton && down("md") && (
              <SearchButton
                translation="COMMON_BUTTONS_SEARCH"
                loading={loading}
              />
            )}
          </SearchWrapper>
        </form>
      )}
    </Formik>
  );
};

export default SearchInput;
