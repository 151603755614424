import { IPages, IPagination } from "API/Interfaces/IPagination";
import { useCallback, useEffect, useRef, useState } from "react";
import { useErrorContext } from "Contexts/ErrorContext";
import { useLoader } from "Hooks";
import useFetchStatus from "./useFetchStatus";

export const useGenericList = <T,>(
  getFromService: (p: number, s: string, ...args: any) => Promise<IPages<T>>,
  id?: string | null,
  loadOnInit = true
) => {
  const [data, setData] = useState<T[] | null>(null);
  const dataPages = useRef<IPagination<T>[]>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [restData, setRestData] = useState<any>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [setLoader] = useLoader();
  const { setError } = useErrorContext();
  const [setStatus, status] = useFetchStatus();
  const initLoad = useRef(loadOnInit);

  const getData = useCallback(
    (startingPage = 1, searchPhrase = "", params?: any) => {
      const savedData = dataPages.current.find(
        (l) => l.pageNumber === startingPage
      );
      if (savedData) {
        setData(savedData.listOfElements);
        setPage(savedData.pageNumber);
      } else {
        setStatus.loading();
        setLoader(true);
        getFromService(startingPage, searchPhrase, id, params)
          .then((res) => {
            setStatus.success();
            const { numberOfPagesTotal, pages, ...rest } = res;
            setNumberOfPages(numberOfPagesTotal);
            dataPages.current = [...dataPages.current, ...pages];
            setData(pages[0]?.listOfElements || []);
            setPage(pages[0]?.pageNumber || 1);
            setRestData(rest);
          })
          .catch(() => {
            setStatus.error();
            setData([]);
            setError();
          })
          .finally(() => setLoader(false));
      }
    },
    [getFromService, id, setError, setLoader, setStatus]
  );

  const getClearData = useCallback(
    (params?: any) => {
      dataPages.current = [];
      getData(1, search, params);
    },
    [getData, search]
  );

  useEffect(() => {
    initLoad.current && getClearData();
    initLoad.current = true;
  }, [getClearData, loadOnInit]);

  return {
    data,
    setData,
    page,
    search,
    setSearch,
    numberOfPages,
    getData,
    getClearData,
    status,
    restData,
  };
};

export default useGenericList;
