import { FormComponentsAddressFields } from "API/Interfaces";
import { FormComponentsFields } from "Layout/FormComponents/types";
import { ILotDetails, IHoursOfOperation } from "API/Interfaces/ILot";
import { MapFields } from "Layout/FormComponents/Map/types";
import { getMapInitValues } from "Layout/FormComponents/Map/utils";

type SharedFields = Required<
  Pick<FormComponentsFields, "name" | "country" | "timezone">
>;

export interface LotFormFields extends SharedFields {
  name: string;
  country: number;
  lotNumber: string;
  capacity: string;
  hoursOfOperation: IHoursOfOperation[];
  fileId?: string;
}

export type LotFormFieldsWithAddress = LotFormFields &
  FormComponentsAddressFields &
  MapFields;

export const lotFormToRequest = (l: LotFormFieldsWithAddress): ILotDetails => ({
  name: l.name,
  number: l.lotNumber,
  gpsCoordinates: {
    longitude: l.markerPosition.lng,
    latitude: l.markerPosition.lat,
  },
  capacity: l.capacity,
  hoursOfOperation: l.hoursOfOperation.filter((el) => el.timeFrom && el.timeTo),
  address: {
    countryId: l.country,
    timeZoneName: l?.timezone || "",
    addressConfiguration: {
      street1: l.addressStreet1,
      street2: l.addressStreet2,
      zipCode: l.addressPostalCode,
      county: l.addressCounty,
      city: l.addressCity,
      state: l.addressState,
    },
  },
  fileId: l.fileId,
});

export const lotResponseToForm = (l: any): LotFormFieldsWithAddress => ({
  name: l?.name || "",
  country: l?.address.countryId || 236,
  lotNumber: l?.number || "",
  capacity: l?.capacity || "",
  timezone: l?.address?.timeZoneName || "",
  addressStreet1: l?.address.addressConfiguration.street1?.value || "",
  addressStreet2: l?.address.addressConfiguration.street2?.value || "",
  addressPostalCode: l?.address.addressConfiguration.zipCode?.value || "",
  addressCounty: l?.address.addressConfiguration.county?.value || "",
  addressCity: l?.address.addressConfiguration.city?.value || "",
  addressState: l?.address.addressConfiguration.state?.value || "",
  hoursOfOperation: l?.hoursOfOperation || [
    { dayOfWeek: 0, timeFrom: "", timeTo: "" },
    { dayOfWeek: 1, timeFrom: "", timeTo: "" },
    { dayOfWeek: 2, timeFrom: "", timeTo: "" },
    { dayOfWeek: 3, timeFrom: "", timeTo: "" },
    { dayOfWeek: 4, timeFrom: "", timeTo: "" },
    { dayOfWeek: 5, timeFrom: "", timeTo: "" },
    { dayOfWeek: 6, timeFrom: "", timeTo: "" },
  ],
  fileId: l?.fileId,
  ...getMapInitValues(l?.gpsCoordinates),
});
