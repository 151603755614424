import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";
import { useAddressContext } from "Contexts";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import React from "react";

export interface FieldsProps {
  errors?: ImportErrors[];
}

export const CountrySelect: React.FC<FieldsProps> = ({ errors }) => {
  const fieldName = "countryName";
  const t = useTranslate();
  const { countries } = useAddressContext();
  const { values, setValues } = useFormikContext<any>();
  const [country, setCountry] = React.useState(
    countries.find((country) => country.name === values[fieldName])?.name || ""
  );

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={fieldName}>{t("COMMON_FORMS_COUNTRY")}</InputLabel>
      <Select
        labelId={fieldName}
        id={fieldName}
        name={fieldName}
        label={t("COMMON_FORMS_COUNTRY")}
        fullWidth
        error={!country}
        value={country}
        onChange={(v) => {
          setCountry(v.target.value as string);
          setValues({ ...values, countryName: v.target.value as string });
        }}
      >
        {countries.map((country) => (
          <MenuItem value={country.name} key={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountrySelect;
