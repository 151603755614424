import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface AppLinkProps extends LinkProps {}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: currentColor;
`;

export const AppLink: FC<AppLinkProps> = ({ children, ...rest }) => {
  return <StyledLink {...rest}>{children}</StyledLink>;
};

export default AppLink;
