import styled from "styled-components";
import { IconButton, SvgIcon } from "@material-ui/core";

const StyledExpandButton = styled(IconButton)`
  width: 64px;
  height: 64px;
  padding: 0px;
  position: absolute;
  top: 135px;
  right: -32px;
  z-index: 1;
`;

const ExpandButton = ({ isExpanded = false, ...props }) => {
  return (
    <StyledExpandButton {...props}>
      <SvgIcon viewBox="0 0 64 64" style={{ fontSize: 64 }}>
        <circle cx="32" cy="32" r="32" fill="#7B59FB" />
        <path
          d={isExpanded ? "M49 42L39 32L49 22" : "M39 22L49 32L39 42"}
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#7B59FB"
        />
      </SvgIcon>
    </StyledExpandButton>
  );
};

export default ExpandButton;
