import React from "react";
import { useAuthContext } from "Pages/Auth";
import UnauthenticatedRouter from "./UnauthenticatedRouter";
import AuthenticatedRouter from "./AuthenticatedRouter";

const AppRouter: React.FC = () => {
  const {
    user,
    status: { success, error },
  } = useAuthContext();

  if (user && success) return <AuthenticatedRouter />;
  if (!user && (success || error)) return <UnauthenticatedRouter />;
  else return null;
};

export default AppRouter;
