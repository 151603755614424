import { ICorporationDetailsResponse } from "API/Interfaces";
import CorporationServiceApi from "API/Services/CorporationServiceApi";
import { useLoaderContext, usePopupContext } from "Contexts";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { routes } from "Routing/routes";
import { isErrorCode } from "Utils";

export const useCorporation = () => {
  const { id }: { id: string } = useParams();
  const { setLoading } = useLoaderContext();
  const { setPopup, showPopup } = usePopupContext();
  const { push } = useHistory();
  const [corporation, setCorporation] =
    useState<ICorporationDetailsResponse | null>();

  const getCorporation = useCallback(() => {
    setLoading(true);
    CorporationServiceApi.getCorporation(id)
      .then(setCorporation)
      .catch((err) => {
        setPopup({
          variant: "error",
          buttonOkClick: () => push(routes.corporationList),
          titleTranslation: "DIALOGS_CORPORATIONDETAILS",
          descriptionTranslation: isErrorCode(err, "ResourceNotFound")
            ? "DIALOGS_CORPORATIONNOTFOUND"
            : "DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN",
        });
        showPopup();
      })
      .finally(() => setLoading(false));
  }, [id, push, setLoading, setPopup, showPopup]);

  useEffect(() => {
    id !== "new" ? getCorporation() : setCorporation(null);
  }, [getCorporation, id]);

  return { corporation, getCorporation };
};

export default useCorporation;
