import { createTheme } from "@material-ui/core/styles";

export const themeComponents = {
  primary: "#00C3FF",
  secondary: "#7B59FB",
  titleText: "#0896C1",
  neutral: "#C4C4C4",
  neutralLight: "#F2F2F2",
  fontFamily: '"Montserrat", sans-serif',
  danger: "#F82222",
};

export const mobileTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1216,
      xxl: 1426,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: themeComponents.primary,
      contrastText: "#fff",
    },
    secondary: {
      main: themeComponents.secondary,
    },
    neutral: {
      main: themeComponents.neutral,
      light: themeComponents.neutralLight,
    },
    danger: {
      main: themeComponents.danger,
    },
  },
  typography: {
    fontFamily: themeComponents.fontFamily,
    h1: {
      fontWeight: 700,
      fontSize: "1rem",
      [mobileTheme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: "1.25rem",
    },
    body1: {
      color: "#000",
      fontFamily: themeComponents.fontFamily,
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontWeight: "bold",
    },
    subtitle2: {
      fontWeight: "bold",
    },
  },
  breakpoints: mobileTheme.breakpoints,
  overrides: {
    MuiBreadcrumbs: {
      root: {
        "& .MuiTypography-root": {
          fontFamily: themeComponents.fontFamily,
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: 22,
        fontSize: "0.75rem",
        textTransform: "uppercase",
      },
      outlinedPrimary: {
        border: "2px solid #00c3ff",
        background: "#fff",
        "&:hover": {
          border: "2px solid #00c3ff",
          background: "#f5f5f5!important",
        },
      },
      outlinedSizeSmall: {
        border: "2px solid #00c3ff",
        "&:hover": {
          border: "2px solid #00c3ff",
        },
      },
      label: {
        fontFamily: themeComponents.fontFamily,
      },
    },
    MuiChip: {
      root: {
        "&.MuiChip-outlinedSecondary": {
          border: `1px solid ${themeComponents.danger}`,
          color: themeComponents.danger,
          "&>svg": {
            color: themeComponents.danger,
          },
        },
      },
    },
    MuiAccordion: {
      root: {
        border: "0",
        backgroundColor: "transparent",
        boxShadow: "0 0 0 0",
        borderBottom: `1px solid ${themeComponents.neutral}`,
        "&$expanded": {
          margin: "0",
          minHeight: "unset",
        },
        "&:before": {
          content: null,
        },
        "&:last-child": {
          borderBottom: "0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        borderTop: `1px solid ${themeComponents.neutral}`,
        flexDirection: "column",
      },
    },
    MuiTabs: {
      root: {
        maxWidth: "100%",
        overflow: "visible",
      },
      fixed: {
        overflow: "auto !important",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    MuiTab: {
      root: {
        fontFamily: themeComponents.fontFamily,
        fontSize: 16,
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        minWidth: 100,
        maxWidth: "unset",
        "&$selected": {
          fontWeight: 700,
          color: themeComponents.neutral,
        },
        [mobileTheme.breakpoints.up("md")]: {
          minWidth: 100,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      textColorPrimary: {
        color: "black",
        fontWeight: 400,
      },
      selected: {},
    },
    MuiFormHelperText: {
      root: {
        marginRight: "0!important",
      },
    },
    MuiPaper: {
      root: {
        // padding: "20px 30px",
      },
      rounded: {
        borderRadius: "20px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: themeComponents.neutral,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: themeComponents.primary,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: "0",
        maxWidth: "500px",
      },
      paperWidthXs: {
        maxWidth: "500px",
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: themeComponents.fontFamily,
        fontWeight: 700,
        borderBottom: `1px solid ${themeComponents.neutral}`,
        fontSize: "1.25rem",
        [mobileTheme.breakpoints.up("md")]: {
          fontSize: "1.25rem",
          padding: "24px 50px",
        },
      },
    },
    MuiDialogContent: {
      root: {
        [mobileTheme.breakpoints.up("md")]: {
          padding: "20px 50px 15px 50px",
        },
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
        padding: "30px 0",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiSelect: {
      root: {
        "&$disabled": {
          backgroundColor: "#F6F6F6",
        },
      },
    },
    MuiStep: {},
    MuiStepLabel: {
      label: {
        fontWeight: 700,
        color: `${themeComponents.primary}4D`,
        "&.MuiStepLabel-active, &.MuiStepLabel-completed": {
          color: themeComponents.primary,
          fontWeight: 700,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: `${themeComponents.primary}4D`,
        "&.MuiStepIcon-active": {
          color: themeComponents.primary,
        },
      },
    },
    MuiTableHead: {
      root: {
        "& .MuiTableCell-head": {
          fontWeight: "bold",
          borderBottom: "none",
        },
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-last-child(1) td": {
          borderBottom: 0,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
        fontWeight: "normal",
      },
    },
    MuiFormControl: {
      root: {
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiOutlinedInput-notchedOutline":
          {
            fontWeight: "bold",
            fontSize: "0.75rem",
            color: themeComponents.neutral,
            [mobileTheme.breakpoints.up("md")]: {
              fontSize: "0.85rem",
            },
          },
      },
    },
    MuiTextField: {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "0.875rem",
          color: themeComponents.neutral,
        },
        "& .MuiInputBase-input.Mui-disabled": {
          backgroundColor: themeComponents.neutralLight,
        },
        "& .MuiOutlinedInput-adornedEnd, & .MuiOutlinedInput-adornedStart": {
          "&.Mui-disabled": {
            backgroundColor: themeComponents.neutralLight,
          },
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiOutlinedInput-notchedOutline":
          {
            fontWeight: "bold",
            fontSize: "0.75rem",
            color: themeComponents.neutral,
            [mobileTheme.breakpoints.up("md")]: {
              fontSize: "0.85rem",
            },
          },
      },
    },
  },
});
