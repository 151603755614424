import { Grid, Tooltip } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { AddressConfiguration, RedirectVariant } from "API/Interfaces";
import { useLotContext } from "Pages/LotDetails/Contexts/LotContext";
import { Formik } from "formik";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPaper, AppTypography, DeleteButton } from "Layout/Components";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import {
  FormCancelButton,
  FormCityFieldWithAddressConfig,
  FormCountriesField,
  FormCountyFieldWithAddressConfig,
  FormNameField,
  FormPostalCodeFieldWithAddressConfig,
  FormStateFieldWithAddressConfig,
  FormStreet1WithAddressConfig,
  FormStreet2WithAddressConfig,
  FormSubmitButton,
  FormTextField,
  FormTimeZonesField,
  Map,
} from "Layout/FormComponents";
import FileUpload from "Layout/FormComponents/FileUpload/FileUpload";
import FormAddressAutocomplete from "Layout/FormComponents/FormAddressAutocomplete";
import ParkingManagement from "../ParkingManagement";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { lotResponseToForm } from "Utils/lotForm";
import { Wrapper } from "Layout/Components/BasicStyledComponents";
import RedirectToValetButton from "Layout/Components/RedirectToValetButton";
import { useTranslate } from "Hooks";
import useSaveLot from "../../Hooks/useSaveLot";
import useDeleteLot from "../../Hooks/useDeleteLot";
import FormNumberInput from "Layout/FormComponents/FormNumberInput";

export interface LotDetailsFormProps {}

const GeneralHeader = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 20px;
`;
const MapWrapper = styled.div`
  width: 100%;
  height: 180px;
  ${(_) => _.theme.breakpoints.up("sm")} {
    height: 240px;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    height: 100%;
  }
`;

const LotDetailsForm: React.FC<LotDetailsFormProps> = () => {
  const t = useTranslate();
  const { goBack } = useHistory();
  const { down } = useBreakpoints();
  const { lot, id, updateSchema, schema } = useLotContext();
  const saveLot = useSaveLot();
  const [DeleteDialog, openDeleteDialog] = useDeleteLot();

  React.useEffect(() => {
    if (lot)
      updateSchema(lot.address.addressConfiguration as AddressConfiguration);
  }, [lot, updateSchema]);

  const mapItem = () => (
    <MapWrapper>
      <Map />
    </MapWrapper>
  );

  return (
    <>
      <AppPaper elevation={3} padding={down("md") ? 3 : 2} fullWidth>
        <Formik
          initialValues={lotResponseToForm(lot)}
          onSubmit={saveLot}
          validationSchema={schema}
          enableReinitialize
        >
          {({ handleSubmit, values }) => (
            <>
              <GeneralHeader
                container
                item
                justifyContent="space-between"
                alignItems="center"
              >
                <AppTypography
                  variant="h2"
                  translation="COMMON_PAGES_GENERAL"
                />
                {lot && (
                  <Wrapper>
                    {lot.isEnabledRedirectToValet && down("md") && (
                      <RedirectToValetButton
                        variant={RedirectVariant.Lot}
                        id={id}
                      />
                    )}
                    <Tooltip
                      title={t("DIALOGS_ATLEASTONELOT")}
                      disableHoverListener={lot.isRemovable}
                      arrow
                      placement="top"
                    >
                      <span>
                        <DeleteButton
                          onClick={openDeleteDialog}
                          translation="COMMON_BUTTONS_DELETELOT"
                          disabled={!lot.isRemovable}
                        />
                      </span>
                    </Tooltip>
                  </Wrapper>
                )}
              </GeneralHeader>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid container item>
                    <FileUpload id={id} type="lot" />
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid container item spacing={2} xs={12} md={6}>
                      <Grid item xs={12} md={12} xl={6}>
                        <FormNameField inputRequired />
                      </Grid>
                      <Grid item xs={6} xl={3}>
                        <FormTextField
                          fieldName="lotNumber"
                          labelTranslation="COMMON_FORMS_LOTNUMBER"
                        />
                      </Grid>
                      <Grid item xs={6} xl={3}>
                        <FormNumberInput
                          fieldName="capacity"
                          labelTranslation="COMMON_FORMS_CAPACITY"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {!down("md") && id && mapItem()}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormAddressAutocomplete updateSchema={updateSchema} />
                      </Grid>
                      {down("md") && <Grid item xs={12} md={6} />}
                      <Grid item xs={12} md={6}>
                        <FormCountriesField updateSchema={updateSchema} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormStreet1WithAddressConfig inputRequired />
                      </Grid>
                      <Grid item xs={12}>
                        <FormStreet2WithAddressConfig />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormTextField
                          fieldName="lat"
                          labelTranslation="COMMON_FORMS_LATITUDE"
                          endAdornmentIcon={<LocationOn color="disabled" />}
                          disabled
                          value={values.markerPosition.lat}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormTextField
                          fieldName="lng"
                          labelTranslation="COMMON_FORMS_LONGITUDE"
                          endAdornmentIcon={<LocationOn color="disabled" />}
                          value={values.markerPosition.lng}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={5} sm={4} md={4} lg={4}>
                        <FormPostalCodeFieldWithAddressConfig inputRequired />
                      </Grid>
                      <Grid item xs={7} sm={8} md={8} lg={4}>
                        <FormCountyFieldWithAddressConfig inputRequired />
                      </Grid>
                      <Grid item xs={7} sm={8} md={8} lg={4}>
                        <FormCityFieldWithAddressConfig inputRequired />
                      </Grid>
                      <Grid item xs={5} sm={4} md={4} lg={4}>
                        <FormStateFieldWithAddressConfig inputRequired />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormTimeZonesField />
                      </Grid>
                    </Grid>
                    {down("md") && (
                      <Grid item xs={12} sm={6} md={5} alignItems="center">
                        {mapItem()}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ParkingManagement />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <ButtonWrapper
                    topSpacing={down("md") ? 4 : 1}
                    marginBottom={2}
                  >
                    <FormCancelButton onClick={() => goBack()} />
                    <FormSubmitButton />
                  </ButtonWrapper>
                </Grid>
              </form>
            </>
          )}
        </Formik>
      </AppPaper>
      <DeleteDialog id={id} name={lot?.name || ""} />
    </>
  );
};

export default LotDetailsForm;
