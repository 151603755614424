import React, { useState } from "react";
import styled from "styled-components";
import {
  FileImportIcon,
  CheckmarkFile,
  CrossIcon,
  FileImportErrorIcon,
} from "assets/icons/Icons";
import { Typography, FormHelperText } from "@material-ui/core";
import { useTranslate } from "Hooks";
import { useDropzone } from "react-dropzone";
import { bytesToSize } from "Utils/utils";
import { format } from "date-fns";
import { useCsvImportContext } from "../Contexts/CsvImportContext";
import Papa from "papaparse";

export interface DragFileProps {
  setIsFileCorrect: (arg: boolean) => void;
}

const DragContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${(_) => _.theme.palette.secondary.main}0D;
  border-radius: 10px;
  /* Border is generated from web app: https://kovart.github.io/dashed-border-generator/ */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%237B59FBFF' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  > * {
    margin: 0 5px;
  }
`;

const FileBox = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0px 4px 10px #d3d6e1;
  border-radius: 10px;
  min-width: 450px;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
  > :nth-child(1) {
    margin: 0 10px;
  }
`;

const FileText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DeleteIcoon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const DragFile: React.FC<DragFileProps> = ({ setIsFileCorrect }) => {
  const t = useTranslate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const { handleLoadCsv } = useCsvImportContext();
  const handleFiles = (files: File[]) => {
    setFiles(
      files.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      })
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    maxFiles: 1,
    maxSize: 500000,
    minSize: 1,
    multiple: false,
    onDropRejected: (rej) => {
      setFiles([rej[0].file]);
      setIsFileCorrect(false);
      if (rej[0].errors[0]?.code === "file-too-large") {
        setError(t("COMMON_FILELARGERTHAN50KB"));
      } else {
        setError(rej[0].errors[0].message);
      }
    },
    onDrop: (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;
      Papa.parse(acceptedFiles[0], {
        header: true,
        delimiter: ";",
        skipEmptyLines: true,
        preview: 1,
        complete: ({ data, meta }) => {
          if (data.length < 1 || !meta?.fields || meta.fields?.length < 3) {
            setIsFileCorrect(false);
            setError(t("COMMON_FORMS_INCORRECTCSVFILEFORMAT"));
          } else {
            setError("");
            handleLoadCsv(acceptedFiles[0]);
            setIsFileCorrect(true);
          }
        },
        error: (e) => {
          setIsFileCorrect(false);
          setError(e.message);
        },
      });
      handleFiles(acceptedFiles);
    },
  });

  return (
    <DragContainer {...getRootProps()} style={{ cursor: "pointer" }}>
      <input {...getInputProps()} disabled={!!files.length} />
      <Wrapper>
        {!files.length && (
          <>
            {error ? <FileImportErrorIcon /> : <FileImportIcon />}
            <Typography
              variant="subtitle2"
              color={error ? "textPrimary" : "secondary"}
            >
              {error || t("COMMON_PAGES_DRAGANDDROPCSVFILE")}
            </Typography>
          </>
        )}
        {!!files.length && (
          <FileBox>
            {error ? <FileImportErrorIcon /> : <CheckmarkFile />}
            <FileText>
              <Typography variant="subtitle2">{files[0].name}</Typography>
              <FormHelperText>
                {error
                  ? error
                  : `${bytesToSize(files[0].size)}, ${format(
                      files[0].lastModified,
                      "dd/MM/YYY"
                    )}`}
              </FormHelperText>
            </FileText>
            <DeleteIcoon
              onClick={(e) => {
                setFiles([]);
                setError("");
                setIsFileCorrect(false);
                handleLoadCsv(null);
                e.preventDefault();
              }}
            >
              <CrossIcon />
            </DeleteIcoon>
          </FileBox>
        )}
      </Wrapper>
    </DragContainer>
  );
};

export default DragFile;
