import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { ICorporationDto } from "API/Interfaces/ICorporation";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppButton, AppTypography } from "Layout/Components";
import IntegrationStatusChip from "Layout/Components/IntegrationStatusChip";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { generateRoute } from "Routing/routes";
import styled from "styled-components";
import { ExpandetArrow } from "../../../assets/icons/Icons";
import { themeComponents } from "../../../Layout/theme";
import LocationList from "./LocationList";

interface ICorporationItemProp {
  corporations: ICorporationDto[];
}

const CorpAddress = styled.div`
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  user-select: text;
  word-break: break-word;
`;

const LocationCount = styled.p`
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  min-width: 120px;
  text-align: right;
  user-select: text;
`;

const CorporationData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AccordionSummaryContent = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  ${(_) => _.theme.breakpoints.up("lg")} {
    padding: 16px 0px 16px 20px;
  }
`;

const LocationDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 351px;
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  display: flex;
  margin: 0;
  min-height: unset;
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const CorporationItem: React.FC<ICorporationItemProp> = ({ corporations }) => {
  const [expanded, setExpanded] = useState("");
  const t = useTranslate();
  const { down } = useBreakpoints();
  const { push } = useHistory();

  const handleChange = (panel: any) => (_: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {corporations.map((corporation, i) => (
        <Accordion
          key={corporation.id}
          square
          expanded={expanded === `panel-${corporation.id}`}
          onChange={handleChange(`panel-${corporation.id}`)}
        >
          <AccordionSummaryStyled
            expandIcon={
              <ExpandetArrow
                expanded={expanded === `panel-${corporation.id}`}
              />
            }
            aria-controls={`panel${i}bh-content`}
            id={`panel${i}bh-header`}
          >
            <AccordionSummaryContent>
              <CorporationData>
                <Box display="flex" alignItems="center">
                  <AppTypography
                    size={1.125}
                    wordBreak="break-word"
                    customColor={themeComponents.titleText}
                    paddingRight={1}
                    onClick={() =>
                      push(generateRoute.corporation(corporation.id))
                    }
                  >
                    {corporation.name}
                  </AppTypography>
                  <IntegrationStatusChip
                    integrationStatus={corporation.integrationStatus}
                  />
                </Box>
                <CorpAddress>{corporation.address}</CorpAddress>
              </CorporationData>
              {down("md") && (
                <LocationDetails>
                  {expanded === `panel-${corporation.id}` && (
                    <AppButton
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={() =>
                        push(generateRoute.location("new"), {
                          corporationId: corporation.id,
                        })
                      }
                      translation="COMMON_BUTTONS_ADDNEWLOCATION"
                    />
                  )}

                  <LocationCount>
                    {corporation.numberOfLocations}{" "}
                    {t(
                      corporation.numberOfLocations === 1
                        ? "COMMON_PAGES_LOCATION"
                        : "COMMON_PAGES_LOCATIONS"
                    )}
                  </LocationCount>
                </LocationDetails>
              )}
            </AccordionSummaryContent>
          </AccordionSummaryStyled>
          <AccordionDetails>
            <LocationList corporation={corporation} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CorporationItem;
