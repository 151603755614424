import { apiClient, IResponseApi } from "API/Auth/apiClient";
import { GetBillingInfoResp } from "API/Interfaces/IBillingInfo";
import urls from "./urls";

const getBillingInfo = async (id: string) => {
  const res = await apiClient.get<IResponseApi<GetBillingInfoResp>>(
    urls.billingInfo(id)
  );
  if (res.data.data.tezServiceFee.feeName === null)
    res.data.data.tezServiceFee.feeName = "";
  return res.data.data;
};
const saveBillingInfo = async (id: string, params: GetBillingInfoResp) => {
  const res = await apiClient.put<IResponseApi<boolean>>(
    urls.billingInfo(id),
    params
  );
  return res.data.data;
};

export const BillingServiceApi = {
  getBillingInfo,
  saveBillingInfo,
};
export default BillingServiceApi;
