import { AppButton, AppTypography } from "Layout/Components";
import LinesForLineList from "Layout/Components/LinesForLineList";
import React from "react";
import { useHistory } from "react-router";
import { generateRoute } from "Routing/routes";
import styled from "styled-components";
import { ILotList, ICorporationDto } from "API/Interfaces";
import useTranslation from "Hooks/useTranslations";
import AppEmptyInformation from "Layout/Components/AppEmptyInformation";
import { themeComponents } from "Layout/theme";
import { Box } from "@material-ui/core";
import ProductType from "Layout/Components/ProductType";
import IntegrationStatusChip from "Layout/Components/IntegrationStatusChip";

interface ILotListProps {
  locationId: string;
  lots: ILotList[];
  numberOfLots: number;
  corporation: ICorporationDto;
}

const LotName = styled(AppTypography)`
  cursor: pointer;
`;

const LotContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineList = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  padding-left: 10px;
`;

const LotInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 6px;
`;

const LotStatus = styled.div`
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 400;
`;

const MoreButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 6px;
`;

const LotsList: React.FC<ILotListProps> = (props) => {
  const { lots, locationId, numberOfLots } = props;
  const t = useTranslation();
  const { push } = useHistory();

  const translations = {
    seeMoreLots: t("COMMON_BUTTONS_SEEMORELOTS"),
    noLots: t("COMMON_NOLOTS"),
    active: t("COMMON_ACTIVE"),
    noActive: t("COMMON_NOACTIVE"),
  };

  return (
    <LotContent>
      {lots.map((lot: ILotList, i: Number) => (
        <LineList key={lot.id}>
          {lots.length > 1 && (
            <LinesForLineList
              isFirstElement={i === 0}
              isLastElement={i === lots.length - 1 && numberOfLots <= 5}
              dashed
              showLines={lots.length > 1}
            />
          )}
          <LotInformation>
            <Box display="flex" alignItems="center">
              <LotName
                size={1}
                wordBreak="break-word"
                customColor={themeComponents.titleText}
                paddingRight={1}
                onClick={() => push(generateRoute.lot(lot.id))}
              >
                {lot.name}
              </LotName>
              <IntegrationStatusChip
                integrationStatus={lot.integrationStatus}
              />
              <ProductType integratedWith={lot.integratedWithSystem} />
            </Box>

            <LotStatus>
              {lot.isActive ? translations.active : translations.noActive}
            </LotStatus>
          </LotInformation>
        </LineList>
      ))}
      {numberOfLots > 5 && (
        <LineList>
          <LinesForLineList
            isLastElement={true}
            isFirstElement={false}
            dashed
          />
          <MoreButton>
            <AppButton
              translation="COMMON_BUTTONS_SEEMORELOTS"
              onClick={() =>
                push(generateRoute.location(locationId), {
                  currentTab: 2,
                  corporation: props.corporation,
                })
              }
              variant="outlined"
            />
          </MoreButton>
        </LineList>
      )}
      {numberOfLots === 0 && <AppEmptyInformation text={translations.noLots} />}
    </LotContent>
  );
};

export default LotsList;
