import ProfileMenu from "Layout/Components/ProfileMenu";
import React from "react";
import styled from "styled-components";
import { GlobalSearch } from "./GlobalSearch";
import { usePageNameContext } from "Contexts";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppTypography, MobileMenu } from "./Components";
import MenuIcon from "@material-ui/icons/Menu";
import { useExpandedPanelContext } from "Contexts/ExpandedPanelContext";

export interface ApplicationTopBarProps {}

const ApplicationBar = styled.div`
  display: flex;
  padding: 17px 10px;
  align-items: center;
  background-color: #fff;
  ${(_) => _.theme.breakpoints.up("xxl")} {
    border-top-left-radius: 35px;
    justify-content: space-between;
    padding: 0 75px;
    height: 90px;
  }
`;

const OptionsSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  ${(_) => _.theme.breakpoints.up("lg")} {
    > :nth-child(1) {
      margin: 0 20px;
    }
  }
  ${(_) => _.theme.breakpoints.up("xxl")} {
    > :nth-child(1) {
      margin-right: 100px;
    }
  }
`;

const BurgerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;

const ApplicationTopBar: React.FC<ApplicationTopBarProps> = () => {
  const { setShowMobileMenu } = useExpandedPanelContext();

  const { pageName } = usePageNameContext();
  const { down } = useBreakpoints();

  return (
    <ApplicationBar>
      {!down("lg") && (
        <BurgerIconWrapper onClick={() => setShowMobileMenu(true)}>
          <MenuIcon color="secondary" />
        </BurgerIconWrapper>
      )}
      <AppTypography variant="h1" noWrap>
        {pageName}
      </AppTypography>
      {down("lg") && (
        <OptionsSection>
          <GlobalSearch />
          <ProfileMenu />
        </OptionsSection>
      )}
      {!down("lg") && <MobileMenu />}
    </ApplicationBar>
  );
};

export default ApplicationTopBar;
