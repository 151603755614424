import * as yup from "yup";
import { AddressConfiguration } from "API/Interfaces";
import { FormComponentsFields } from "Layout/FormComponents/types";
import { i18n, Translations } from "Translation";
import { YupSchemaFormComponentsKeys } from "API/Interfaces";
import { generateAddressConfigurationSchema } from "Utils/AddressConfiguration/addressConfigurationSchema";

type Schema = YupSchemaFormComponentsKeys;
const schema: Schema = {
  name: yup
    .string()
    .max(100, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER100))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  country: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_COUNTRYISREQUIRED)),
  timezone: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_TIMEZONEISREQUIRED)),
  adminName: yup
    .string()
    .max(200, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER200))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  adminEmail: yup
    .string()
    .max(50, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER50))
    .email(i18n.t(Translations.COMMON_FORMS_ENTERVALIDEMAIL))
    .required(i18n.t(Translations.COMMON_FORMS_EMAILISREQUIRED)),
  adminOfficePhone: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t(Translations.COMMON_FORMS_FIELDMUSTBEANUMBER))
    .max(15, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER15))
    .required(i18n.t(Translations.COMMON_FORMS_OFFICEPHONEISREQUIRED)),
  adminMobilePhone: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t(Translations.COMMON_FORMS_FIELDMUSTBEANUMBER))
    .max(15, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER15))
    .required(i18n.t(Translations.COMMON_FORMS_MOBILEPHONEISREQUIRED)),
  logoId: yup.string(),
};

const schemaObj: YupSchemaFormComponentsKeys = {
  ...schema,
  ...generateAddressConfigurationSchema(),
};

export const initSchema = yup.object(schemaObj);

export const schemaGenerator = (arg: AddressConfiguration) =>
  yup.object({
    ...schema,
    ...generateAddressConfigurationSchema(arg),
  });
export type CorporationForm = FormComponentsFields;
