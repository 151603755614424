import { YupSchemaKeys } from "API/Interfaces";
import { AddressConfiguration } from "API/Interfaces";
import { i18n, Translations } from "Translation";
import {
  AddressYupSchemaKeys,
  generateAddressConfigurationSchema,
} from "Utils";
import { LocationFormFields } from "Utils/locationForm";
import * as yup from "yup";

type PartialSchema = Partial<YupSchemaKeys<LocationFormFields>>;
type Schema = PartialSchema & AddressYupSchemaKeys;

export const schema: PartialSchema = {
  name: yup
    .string()
    .max(100, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER100))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  country: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_COUNTRYISREQUIRED)),
  timezone: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_TIMEZONEISREQUIRED)),
  contactName: yup
    .string()
    .max(200, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER200))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  contactPhone: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t(Translations.COMMON_FORMS_FIELDMUSTBEANUMBER))
    .max(15, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER15))
    .required(i18n.t(Translations.COMMON_FORMS_PHONEREQUIRED)),
  contactEmail: yup
    .array()
    .of(yup.string().email(i18n.t(Translations.COMMON_FORMS_ENTERVALIDEMAIL)))
    .min(1, i18n.t(Translations.COMMON_FORMS_THISFIELDMUSTHAVE))
    .required(i18n.t(Translations.COMMON_FORMS_EMAILISREQUIRED))
    .test(
      "Maximum emails length",
      i18n.t(Translations.COMMON_FORMS_THISFIELDINCLUDESTOOMANY),
      (value) => (value ? value.join().length + value.length - 1 < 500 : true)
    ),
  billingName: yup
    .string()
    .max(200, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER200))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  billingPhone: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t(Translations.COMMON_FORMS_FIELDMUSTBEANUMBER))
    .max(15, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER15))
    .required(i18n.t(Translations.COMMON_FORMS_PHONEREQUIRED)),
  billingEmail: yup
    .array()
    .of(yup.string().email(i18n.t(Translations.COMMON_FORMS_ENTERVALIDEMAIL)))
    .min(1, i18n.t(Translations.COMMON_FORMS_THISFIELDMUSTHAVE))
    .required(i18n.t(Translations.COMMON_FORMS_EMAILISREQUIRED))
    .test(
      "Maximum emails length",
      i18n.t(Translations.COMMON_FORMS_THISFIELDINCLUDESTOOMANY),
      (value) => (value ? value.join().length + value.length - 1 < 500 : true)
    ),
  logoId: yup.string(),
};

const initSchemaObj: Schema = {
  ...schema,
  ...(generateAddressConfigurationSchema() as AddressYupSchemaKeys),
};

export const initSchema = yup.object(initSchemaObj);

export const locationSchemaGenerator = (arg: AddressConfiguration) =>
  yup.object({
    ...schema,
    ...generateAddressConfigurationSchema(arg),
  });
