import { Account } from "assets/icons";
import { useFormikContext } from "formik";
import { InputOutsideIcon, InputOutsideIconProps } from "Layout/Components";
import React from "react";
import { getFieldName } from "Utils";
import { AuthFields } from "../fields";

export const UsernameInput: React.FC<Partial<InputOutsideIconProps>> = (
  props
) => {
  const { handleBlur, handleChange, errors, touched } =
    useFormikContext<AuthFields>();
  const name = getFieldName<AuthFields>("username");

  return (
    <InputOutsideIcon
      {...props}
      name={name}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      translatedPlaceholder="COMMON_AUTH_USERNAME"
      onChange={handleChange}
      onBlur={handleBlur}
      IconComponent={() => <Account color="disabled" />}
    />
  );
};

export default UsernameInput;
