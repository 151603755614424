import AppDialog from "Layout/Components/AppDialog";
import {
  FormCancelButton,
  FormSubmitButton,
} from "Layout/FormComponents/FormButtons";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import { schemaObj, initial, EdtiFormFields } from "./Shema";
import { ImportLotsResponse } from "API/Services/ImportCsvServiceApi";
import { useTranslate } from "Hooks";
import useEditImportLot from "../../Hooks/useEditImportLot";
import { FormToApi } from "../../Utils";
import React, { useState, useEffect } from "react";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";
import { useCsvImportContext } from "../../Contexts/CsvImportContext";
import * as F from "./Fields";
import CountrySelect from "./CountrySelect";

export interface EditDialogFormProps {
  open: boolean;
  selectedItem: ImportLotsResponse | null;
  onClose: any;
}

const EditImportedLotFrom: React.FC<EditDialogFormProps> = ({
  open,
  selectedItem,
  onClose,
}) => {
  const t = useTranslate();
  const [saveLot] = useEditImportLot();
  const [errorList, setErrorList] = useState<ImportErrors[]>([]);
  const { handleEditLot } = useCsvImportContext();

  const onSubmit = (e?: EdtiFormFields) => {
    if (!e) return;
    saveLot(e?.id, FormToApi(e)).then((res) => {
      if (!res) return;
      if (!res.length) {
        handleEditLot({ id: e.id, errors: res, ...FormToApi(e) });
        onClose(false);
      } else {
        setErrorList(res);
      }
    });
  };

  useEffect(() => {
    selectedItem?.errors && setErrorList(selectedItem?.errors);
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <AppDialog
      open={open}
      title={t("COMMON_PAGES_EDITDATA")}
      onClose={() => onClose(false)}
      maxWidth="md"
    >
      <Formik
        initialValues={initial(selectedItem)}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={schemaObj}
        validateOnMount={true}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <F.Name errors={errorList} />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <F.LotNumber errors={errorList} />
              </Grid>
              <Grid item xs={6}>
                <F.Capacity errors={errorList} />
              </Grid>
              <Grid item xs={6}>
                <F.Latitude errors={errorList} />
              </Grid>
              <Grid item xs={6}>
                <F.Longitude errors={errorList} />
              </Grid>
              <Grid item xs={6}>
                <CountrySelect errors={errorList} />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <F.Street1 errors={errorList} />
              </Grid>
              <Grid item xs={6}>
                <F.Street2 />
              </Grid>
              <Grid item xs={4}>
                <F.ZipCode errors={errorList} />
              </Grid>
              <Grid item xs={8}>
                <F.County errors={errorList} />
              </Grid>
              <Grid item xs={8}>
                <F.City errors={errorList} />
              </Grid>
              <Grid item xs={4}>
                <F.State errors={errorList} />
                {false && <F.Id />}
                {false && <F.RowNumber />}
                {false && <F.IsBasedOnCoordinates />}
              </Grid>
              <Grid item xs={12}>
                <ButtonWrapper>
                  <FormCancelButton onClick={() => onClose(false)} />
                  <FormSubmitButton />
                </ButtonWrapper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AppDialog>
  );
};

export default EditImportedLotFrom;
