import {
  DefaultFormAddressConfigurationFieldProps,
  FormComponentsAddressFields,
  FormTextFieldComponent,
} from "API/Interfaces";
import React from "react";
import { getFieldName } from "Utils";
import FormTextField from "./FormTextField";
import { FormTextFieldWithAddressConfiguration } from "./FormTextFieldWithAddressConfiguration";

const defaultProps: DefaultFormAddressConfigurationFieldProps = {
  fieldName: getFieldName<FormComponentsAddressFields>("addressState"),
  labelTranslation: "COMMON_FORMS_STATE",
};

export const FormStateField: FormTextFieldComponent = (props) => (
  <FormTextField {...defaultProps} {...props} />
);

export const FormStateFieldWithAddressConfig: FormTextFieldComponent = (
  props
) => <FormTextFieldWithAddressConfiguration {...defaultProps} {...props} />;

export default FormStateField;
