import React from "react";
import { PageBreadcrumbs } from "Layout/Components";
import { generateRoute } from "Routing/routes";
import { useLotContext } from "../Contexts/LotContext";

const LotBreadcrumbs: React.FC = () => {
  const { breadcrumbs } = useLotContext();

  return breadcrumbs ? (
    <PageBreadcrumbs
      path={[
        {
          ...breadcrumbs.corporation,
          id: generateRoute.corporation(breadcrumbs.corporation.id),
        },
        {
          ...breadcrumbs.location,
          id: generateRoute.location(breadcrumbs.location.id),
        },
      ]}
      current={breadcrumbs.lot}
    />
  ) : (
    <div />
  );
};

export default LotBreadcrumbs;
