import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFormikContext } from "formik";

import React from "react";
import { convertBytesToKB } from "Utils";
import { FilePreviewOverlay, ImagePreview } from "./FileUpload.styles";

export interface FilePreviewProps {
  file: File;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  isPhoto: boolean;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  setFile,
  isPhoto,
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <FilePreviewOverlay>
        <Box display="flex" width="100%">
          <span>{file.name}</span>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <span>{convertBytesToKB(file.size)} kb</span>
          <DeleteIcon
            onClick={() => {
              setFieldValue(isPhoto ? "fileId" : "logoId", "");
              setFile(undefined);
            }}
          />
        </Box>
      </FilePreviewOverlay>
      <ImagePreview src={URL.createObjectURL(file)} alt={file.name} />
    </>
  );
};

export default FilePreview;
