import React from "react";
import styled from "styled-components";
import {
  NewEnforcementProductIcon,
  T2PProductIcon,
  ValetProductIcon,
} from "assets/icons/Icons";
import { TezProductType } from "API/Interfaces";
const { VDB, T2P, NewEnforcement } = TezProductType;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 6px;
  > svg:nth-child(2) {
    margin: 0 6px;
  }
`;

export interface ProductTypeProps {
  integratedWith: (TezProductType & number) | null | undefined;
}

const ProductType: React.FC<ProductTypeProps> = ({ integratedWith }) => {
  return integratedWith ? (
    <Wrapper>
      {integratedWith & VDB ? <ValetProductIcon /> : null}
      {integratedWith & T2P ? <T2PProductIcon /> : null}
      {integratedWith & NewEnforcement ? <NewEnforcementProductIcon /> : null}
    </Wrapper>
  ) : null;
};

export default ProductType;
