import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageBreadcrumbs } from "Layout/Components";
import { useParams } from "react-router-dom";
import LocationServiceApi from "API/Services/LocationServiceApi";
import { ILocationBreadcrumbs } from "API/Interfaces";
import { generateRoute } from "Routing/routes";
import { useLoaderContext } from "Contexts";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

const ImportBreadcrumbs: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useLoaderContext();

  const [breadcrumbs, setBreadcrumbs] = useState<ILocationBreadcrumbs>();
  useEffect(() => {
    setLoading(true);
    LocationServiceApi.getBreadcrumbs(id)
      .then(setBreadcrumbs)
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  return breadcrumbs ? (
    <Wrapper>
      <PageBreadcrumbs
        path={[
          {
            ...breadcrumbs.corporation,
            id: generateRoute.corporation(breadcrumbs.corporation.id),
          },
        ]}
        current={breadcrumbs.location}
      />
    </Wrapper>
  ) : (
    <div />
  );
};

export default ImportBreadcrumbs;
