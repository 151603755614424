import {
  DefaultFormAddressConfigurationFieldProps,
  FormComponentsAddressFields,
  FormTextFieldComponent,
} from "API/Interfaces";
import React from "react";
import { getFieldName } from "Utils";
import FormTextField from "./FormTextField";
import { FormTextFieldWithAddressConfiguration } from "./FormTextFieldWithAddressConfiguration";

const defaultProps: DefaultFormAddressConfigurationFieldProps = {
  fieldName: getFieldName<FormComponentsAddressFields>("addressCounty"),
  labelTranslation: "COMMON_FORMS_COUNTY",
};

export const FormCountyField: FormTextFieldComponent = (props) => (
  <FormTextField {...defaultProps} {...props} />
);

export const FormCountyFieldWithAddressConfig: FormTextFieldComponent = (
  props
) => <FormTextFieldWithAddressConfiguration {...defaultProps} {...props} />;

export default FormCountyField;
