import React, { createContext, useContext, useState } from "react";

export type PageNameContextType = {
  setPageName: (arg: string) => void;
  pageName?: string;
};

export const PageNameContext = createContext<PageNameContextType>({
  setPageName: () => undefined,
  pageName: "",
});

export const usePageNameContext = () => useContext(PageNameContext);

export const PageNameProvider: React.FC = (props) => {
  const [pageName, setPageName] = useState("");
  return (
    <PageNameContext.Provider value={{ setPageName, pageName }}>
      {props.children}
    </PageNameContext.Provider>
  );
};

export default PageNameContext;
