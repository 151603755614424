import React from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  Suggestion,
} from "react-places-autocomplete";
import { InputAdornment, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { getAddressFields, useAddressConfiguration } from "Utils";
import { AddressConfiguration, GeocodeResult } from "API/Interfaces";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { SearchIcon } from "assets/icons";
import { TranslationKeys } from "Translation";
import useMap from "./Map/useMap";
import { useAddressContext, useLoaderContext } from "Contexts";
import { useJsApiLoader } from "@react-google-maps/api";
import { config } from "API/Services";

interface AutocompleteProps {
  updateSchema: (arg: AddressConfiguration) => void;
  placeholder?: TranslationKeys;
}

const FormAddressAutocomplete: React.FC<AutocompleteProps> = ({
  updateSchema,
  placeholder = "COMMON_FORMS_ADDRESSLOOKUP",
}) => {
  const [value, setValue] = React.useState<Suggestion | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const { setFieldValue } = useFormikContext();
  const { setCenter, setMarkerPosition } = useMap();
  const [getAddressConfiguration] = useAddressConfiguration(updateSchema);
  const { countries } = useAddressContext();
  const t = useTranslate();
  const { setLoading } = useLoaderContext();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GEOCODE_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (value) {
      setLoading(true);
      geocodeByPlaceId(value.placeId)
        .then(async (res) => {
          const {
            street,
            state,
            postalCode,
            county,
            city,
            location,
            countryCode,
          } = getAddressFields(res[0] as unknown as GeocodeResult);
          const country = countries.find((c) => c.countryCode === countryCode);
          if (country) {
            const config = await getAddressConfiguration(country.id);
            updateSchema(config);
            setFieldValue("country", country.id);
            config.county.isAvailable && setFieldValue("addressCounty", county);
            config.city.isAvailable && setFieldValue("addressCity", city);
            config.state.isAvailable && setFieldValue("addressState", state);
            config.street1.isAvailable &&
              setFieldValue("addressStreet1", street);
            config.zipCode.isAvailable &&
              setFieldValue("addressPostalCode", postalCode);
          }
          if (location) {
            setCenter(location);
            setMarkerPosition(location);
            setFieldValue("lat", location.lat);
            setFieldValue("lng", location.lng);
          }
        })
        .finally(() => setLoading(false));
    }
    //TO DO: Refactor autocomplete logic (as it's done in Enforcement Portal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return isLoaded ? (
    <PlacesAutocomplete value={inputValue} onChange={setInputValue}>
      {({ getInputProps, suggestions, loading }) => {
        return (
          <Autocomplete
            autoComplete
            includeInputInList
            filterSelectedOptions
            filterOptions={(x) => x}
            size="small"
            loading={loading}
            options={suggestions.map((suggestion) => suggestion)}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            value={value}
            onChange={(_: any, newValue: any) => {
              setValue(newValue);
            }}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment style={{ paddingLeft: 8 }} position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                {...getInputProps({
                  placeholder: t(placeholder),
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />
        );
      }}
    </PlacesAutocomplete>
  ) : (
    <></>
  );
};

export default FormAddressAutocomplete;
