import React from "react";
import { NumberFormatProps } from "react-number-format";
import { FormTextFieldProps } from ".";
import { FormNumberInput } from "./FormNumberInput";

export interface FormCurrencyInputProps {
  fieldName: string;
  numberFieldProps?: NumberFormatProps<any>;
}

export const FormCurrencyInput: React.FC<
  FormCurrencyInputProps & FormTextFieldProps
> = ({ fieldName, numberFieldProps, ...rest }) => {
  return (
    <FormNumberInput
      fieldName={fieldName}
      numberFieldProps={{
        allowedDecimalSeparators: [".", ","],
        decimalScale: 2,
        allowNegative: false,
        fixedDecimalScale: true,
        prefix: "$",
        placeholder: "$0.00",
        ...numberFieldProps,
      }}
      {...rest}
    />
  );
};

export default FormCurrencyInput;
