import useBreakpoints from "Hooks/useBreakpoints";
import React from "react";
import styled from "styled-components";
import ApplicationDrawer from "./ApplicationDrawer";
import ApplicationTopBar from "./ApplicationTopBar";
import { themeComponents } from "./theme";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow: auto;
  font-family: "Montserrat", sans-serif;
  ${(_) => _.theme.breakpoints.up("md")} {
    &::-webkit-scrollbar {
      width: 0.4em;
    }
    &::-webkit-scrollbar-track {
      background-color: #c4c4c4;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${themeComponents.primary};
      border-radius: 20px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Content = styled.div`
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  height: 100%;
  ${(_) => _.theme.breakpoints.up("lg")} {
    padding: 34px 75px;
  }
`;

interface Props {}
const ApplicationLayout: React.FC<Props> = (props) => {
  const { down } = useBreakpoints();

  return (
    <Layout>
      {down("lg") && <ApplicationDrawer />}
      <Wrapper>
        <ApplicationTopBar />
        <Content>{props.children}</Content>
      </Wrapper>
    </Layout>
  );
};

export default ApplicationLayout;
