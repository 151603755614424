import React, { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { AddressConfiguration } from "API/Interfaces";
import {
  ILocationBreadcrumbs,
  ILocationDetails,
} from "API/Interfaces/ILocation";
import {
  initSchema,
  locationSchemaGenerator,
} from "Pages/LocationDetails/Components/LocationDetailsForm/LocationDetails.schema";

import useBillingInfo from "../Hooks/useBillingInfo";
import useLots from "../Hooks/useLots";
import { useAddressConfigurationContext } from "Utils";
import useGetLocation from "../Hooks/useGetLocation";

export type LocationContextType = {
  id: string;
  location: ILocationDetails | null;
  breadcrumbs: ILocationBreadcrumbs | null;
  updateSchema: (arg: AddressConfiguration) => void;
  schema: typeof initSchema;
  billingInfo: ReturnType<typeof useBillingInfo>;
  lots: ReturnType<typeof useLots>;
  getLocation: () => void;
};
export const LocationContext = React.createContext<LocationContextType>(
  {} as LocationContextType
);
export const useLocationContext = () => useContext(LocationContext);

export const LocationProvider: React.FC = ({ children }) => {
  const { id }: { id: string } = useParams();
  const [schema, setSchema] = React.useState(initSchema);
  const { location, breadcrumbs, getLocation } = useGetLocation();
  const billingInfo = useBillingInfo();
  const lots = useLots();
  const { setAddressConfiguration } = useAddressConfigurationContext();

  const updateSchema = useCallback(
    (arg: AddressConfiguration) => {
      setSchema(locationSchemaGenerator(arg) as typeof initSchema);
      setAddressConfiguration(arg);
    },
    [setAddressConfiguration]
  );

  return location !== undefined ? (
    <LocationContext.Provider
      value={{
        id: id === "new" ? "" : id,
        location,
        breadcrumbs,
        schema,
        updateSchema,
        billingInfo,
        lots,
        getLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  ) : null;
};

export default LocationContext;
