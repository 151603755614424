import { AddressConfiguration } from "API/Interfaces";
import { AddressServiceApi } from "API/Services";
import { useFetchStatus } from "Hooks";

export const useAddressConfiguration = (
  setAddressConfiguration: (arg: AddressConfiguration) => void
) => {
  const [setStatus, status] = useFetchStatus();

  const handler = async (id: number) => {
    try {
      setStatus.loading();
      const res = await AddressServiceApi.getAddressConfiguration(id);
      setStatus.success();
      setAddressConfiguration(res);
      return res;
    } catch (error) {
      setStatus.error();
      throw Error;
    }
  };

  return [handler, status] as const;
};

export default useAddressConfiguration;
