import { AuthTokenTypes } from "API/Interfaces/Enums";
import { IAuthTokens } from "API/Services";
import { jwtDecode } from "jwt-decode";
import { User } from "..";

export interface IDecodedAuthToken {
  exp: number;
  FirstName: string;
  LastName: string;
  SessionId: string;
  TokenType: AuthTokenTypes;
  UserId: string;
}

export const decodeToken = (token: string) => {
  const {
    exp,
    FirstName,
    LastName,
    SessionId,
    TokenType,
    UserId,
  }: IDecodedAuthToken = jwtDecode(token);

  return {
    exp: exp,
    firstName: FirstName,
    lastName: LastName,
    sessionId: SessionId,
    tokenType: TokenType,
    userId: UserId,
  };
};

export const convertUserInfo = (
  tokens: Pick<IAuthTokens, "auth" | "refresh">
) => {
  const refresh = decodeToken(tokens.refresh);
  const auth = decodeToken(tokens.auth);
  return {
    firstName: auth.firstName,
    lastName: auth.lastName,
    auth: tokens.auth,
    authExp: auth.exp,
    refresh: tokens.refresh,
    refreshExp: refresh.exp,
    sessionId: auth.sessionId,
  };
};

export const isAuthTokenActive = (tokens: User): boolean => {
  const { authExp } = tokens;
  return authExp > Math.floor(Date.now() / 1000);
};

export const isRefreshTokenActive = (tokens: User): boolean => {
  const { refreshExp } = tokens;
  return refreshExp > Math.floor(Date.now() / 1000);
};
