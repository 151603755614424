import { ErrorMark } from "assets/icons/Icons";
import { Typography } from "@material-ui/core";
import { useCsvImportContext } from "../Contexts/CsvImportContext";
import { useTranslate } from "Hooks";
import styled from "styled-components";

export interface ErrorCounterProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin: 0 2px;
  }
`;

const ErrorCounter: React.FC<ErrorCounterProps> = () => {
  const { errorsCount } = useCsvImportContext();
  const t = useTranslate();

  return (
    <>
      {errorsCount > 0 && (
        <Wrapper>
          <ErrorMark />
          <Typography color="error" variant="subtitle2">
            {`${errorsCount}  ${t("COMMON_PAGES_ERRORS")}`}
          </Typography>
        </Wrapper>
      )}
    </>
  );
};

export default ErrorCounter;
