import React, { createContext, useCallback, useContext } from "react";
import { useParams } from "react-router";
import { ILotBreadcrumbs, ILotDetails } from "API/Interfaces/ILot";
import { AddressConfiguration } from "API/Interfaces";
import {
  lotSchemaGenerator,
  initSchema,
} from "Pages/LotDetails/Components/LotDetailsForm/LotDetails.schema";
import { useAddressConfigurationContext } from "Utils";
import useGetLot from "../Hooks/useGetLot";

export type LotContextType = {
  lot: ILotDetails | null;
  breadcrumbs: ILotBreadcrumbs | null;
  id: string;
  updateSchema: (arg: AddressConfiguration) => void;
  schema: typeof initSchema;
  getLot: () => void;
};

export const LotContext = createContext<LotContextType>({} as LotContextType);

export const useLotContext = () => useContext(LotContext);

export interface LotProviderProps {}

export const LotProvider: React.FC<LotProviderProps> = (props) => {
  const { id }: { id: string } = useParams();
  const [schema, setSchema] = React.useState(initSchema);
  const { lot, breadcrumbs, getLot } = useGetLot();
  const { setAddressConfiguration } = useAddressConfigurationContext();

  const updateSchema = useCallback(
    (arg: AddressConfiguration) => {
      setSchema(lotSchemaGenerator(arg) as typeof initSchema);
      setAddressConfiguration(arg);
    },
    [setAddressConfiguration]
  );

  return lot !== undefined ? (
    <LotContext.Provider
      value={{
        id: id === "new" ? "" : id,
        lot,
        breadcrumbs,
        schema,
        updateSchema,
        getLot,
      }}
    >
      {props.children}
    </LotContext.Provider>
  ) : null;
};

export default LotProvider;
