import {
  RadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from "@material-ui/core";

import styled from "styled-components";
export interface AppRadioGroupProps extends MUIRadioGroupProps {
  /** MUI spacing */
  gutterBetween?: number;
}

const StyledRadioGroup = styled(RadioGroup)<{
  $gutterBetween?: number;
}>`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  > *:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(_.$gutterBetween)}px;
  }
`;

const AppRadioGroup: React.FC<AppRadioGroupProps> = ({
  gutterBetween,
  ...props
}) => {
  return (
    <StyledRadioGroup $gutterBetween={gutterBetween} {...props}>
      {props.children}
    </StyledRadioGroup>
  );
};
AppRadioGroup.defaultProps = {
  gutterBetween: 2,
};
export default AppRadioGroup;
