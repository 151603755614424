import { fade } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import AppTypography, { AppTypographyProps } from "./AppTyphography";

const createHighlightedString = (arg: string, searchPhrase: string) => {
  const re = new RegExp(searchPhrase, "gi");
  return arg.replace(re, (match) => {
    return `<span class="highlighted">${match}</span>`;
  });
};

export interface HighlightedTypographyProps
  extends Omit<AppTypographyProps, "translation" | "children"> {
  highlightPhrase: string;
  text: string;
  pointer?: boolean;
}

const StyledAppTypography = styled(AppTypography)<{ $pointer?: boolean }>`
  ${(_) =>
    _.$pointer &&
    css`
      cursor: pointer;
    `}
  .highlighted {
    background: ${(_) => fade(_.theme.palette.primary.light, 0.2)};
  }
`;
export const HighlightedTypography: React.FC<HighlightedTypographyProps> = ({
  highlightPhrase,
  text,
  pointer,
  ...props
}) => {
  const phrase = highlightPhrase?.trim() || "";
  return (
    <StyledAppTypography
      $pointer={pointer}
      dangerouslySetInnerHTML={{
        __html: phrase ? createHighlightedString(text, phrase) : text,
      }}
      {...props}
    />
  );
};

export default HighlightedTypography;
