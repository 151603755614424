import CorporationServiceApi from "API/Services/CorporationServiceApi";
import { useFetchStatus } from "Hooks";
import { useState } from "react";

export const useGlobalSearch = () => {
  const [setStatus, status] = useFetchStatus();
  const [searchPhrase, setSearchPhrase] = useState("");
  const [numberOfPagesTotal, setNumberOfPagesTotal] = useState(0);

  const handler = async (
    searchPhrase: string,
    pageNumber: number = 1,
    pageSize: number = 10
  ) => {
    try {
      setStatus.loading();
      setSearchPhrase(searchPhrase);
      const res = await CorporationServiceApi.searchAll({
        searchPhrase,
        pageNumber,
        pageSize,
      });
      setNumberOfPagesTotal(res.numberOfPagesTotal);
      setStatus.success();
      return res;
    } catch (error) {
      setStatus.error();
      throw error;
    }
  };

  return [handler, status, { searchPhrase, numberOfPagesTotal }] as const;
};

export default useGlobalSearch;
