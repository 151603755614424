import React from "react";
import { getFieldName } from "Utils";
import { FormComponentsFields } from "./types";
import { FormTextField, FormTextFieldProps } from "./FormTextField";

export const FormNameField: React.FC<Partial<FormTextFieldProps>> = (props) => {
  const fieldName = getFieldName<FormComponentsFields>("name");
  return (
    <FormTextField
      labelTranslation="COMMON_FORMS_NAME"
      fieldName={fieldName}
      {...props}
    />
  );
};

export default FormNameField;
