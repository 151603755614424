import { apiClient } from "../Auth/apiClient";
import { urls } from "./urls";

export interface IImportRequest {
  fileName: string;
  lots: IImportLots[];
}

export interface IImportLots {
  name: string;
  number?: string;
  companyParking?: string;
  gpsCoordinates: IGPSCoordinates;
  capacity?: number;
  address: IAddressRequest;
  isBasedOnCoordinates: boolean;
  rowNumber?: number;
}

export interface IAddressRequest {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  countryName: string;
}

export interface IGPSCoordinates {
  longitude: string | number | null;
  latitude: string | number | null;
}

export interface PageResult {
  data: {
    importedFileId: string;
    lots: ImportLotsResponse[];
  };
}

export interface EditResult {
  data: ImportLotsResponse;
}

export interface ImportLotsResponse extends IImportLots {
  id: string;
  errors: ImportErrors[];
}

export interface ImportErrors {
  fieldName: string;
  error: number;
}

export const ImportCsvServiceApi = {
  sendImportData: async (
    locaId: string,
    data: IImportRequest
  ): Promise<PageResult> => {
    const response = await apiClient.post(
      urls.locations.importLots(locaId),
      data
    );
    return response.data;
  },
  editImportLot: async (
    locId: string,
    lotId: string,
    data: IImportLots
  ): Promise<EditResult> => {
    const response = await apiClient.put(
      urls.locations.changeImportLot(locId, lotId),
      data
    );
    return response.data;
  },
  deleteImportLot: async (locId: string, lotId: string) => {
    const response = await apiClient.delete(
      urls.locations.changeImportLot(locId, lotId)
    );
    return response.data;
  },
  saveImportLot: async (locId: string, fileId: string) => {
    const response = await apiClient.put(
      urls.locations.saveImportLots(locId, fileId)
    );
    return response.data;
  },
  cancelImportProcess: async (locId: string, fileId: string) => {
    const response = await apiClient.delete(
      urls.locations.cancelImportProcess(locId, fileId)
    );
    return response.data;
  },
};

export default ImportCsvServiceApi;
