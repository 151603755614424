import Lock from "@material-ui/icons/Lock";
import { useFormikContext } from "formik";
import { InputOutsideIcon, InputOutsideIconProps } from "Layout/Components";
import React from "react";
import { getFieldName } from "Utils";
import { AuthFields } from "../fields";

export const PasswordInput: React.FC<Partial<InputOutsideIconProps>> = (
  props
) => {
  const { handleBlur, handleChange, errors, touched } =
    useFormikContext<AuthFields>();
  const name = getFieldName<AuthFields>("password");

  return (
    <InputOutsideIcon
      {...props}
      name={name}
      type="password"
      autoComplete="password"
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      translatedPlaceholder="COMMON_AUTH_PASSWORD"
      onChange={handleChange}
      onBlur={handleBlur}
      IconComponent={() => <Lock color="disabled" />}
    />
  );
};

export default PasswordInput;
