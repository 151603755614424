import { apiClient } from "API/Auth/apiClient";
import { EntityType, IFunctionalitiesData } from "API/Interfaces";
import urls from "./urls";

export const FunctionalitiesAndProductsServiceApi = {
  getFunctionalitiesData: async (
    id: string,
    entityType: EntityType
  ): Promise<IFunctionalitiesData> => {
    const params = new URLSearchParams({ entityType: entityType.toString() });
    const res = await apiClient.get(urls.functionalities(id), { params });
    return res.data.data;
  },
  saveFunctionalitiesData: async (
    id: string,
    params: IFunctionalitiesData & { entityType: EntityType }
  ): Promise<void> => {
    const res = await apiClient.put(urls.functionalities(id), params);
    return res.data;
  },
};
