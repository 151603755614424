export const getMapInitValues = (
  { latitude: lat, longitude: lng } = {
    latitude: 37.09024,
    longitude: -95.712891,
  }
) => ({
  markerPosition: {
    lat,
    lng,
  },
  center: {
    lat,
    lng,
  },
  zoom: 15,
});
