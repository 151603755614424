import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useTranslate } from "Hooks";
import GeneralInfo from "./GeneralInfo";
import Lots from "./Lots";
import BillingInfo from "./BillingInfo";
import { useHistory, useLocation } from "react-router-dom";
import { PageDetailsHeader } from "Layout/Components";
import LocationBreadcrumbs from "./Components/LocationBreadcrumbs";
import usePageName from "Hooks/usePageName";
import {
  LocationProvider,
  useLocationContext,
} from "./Contexts/LocationContext";
import { EntityType, LocationTabs as LTabs } from "API/Interfaces";
import { AddressConfigurationProvider } from "Utils";
import FunctionalitiesAndProducts from "Layout/FormComponents/FunctionalitiesAndProducts";

interface StateProps {
  corporationId: string;
  currentTab: LTabs;
  goBackToList?: boolean;
}

const LocationDetailsPage: React.FC = () => {
  usePageName("COMMON_PAGES_LOCATIONDETAILS");
  const t = useTranslate();
  const { replace } = useHistory();
  const { pathname, state } = useLocation<StateProps>();
  const { location, getLocation } = useLocationContext();
  const [currentTab, setCurrentTab] = useState(
    state?.currentTab || LTabs.GeneralInfo
  );
  useEffect(
    () =>
      replace(pathname, {
        corporationId: state?.corporationId,
        goBackToList: state?.goBackToList,
        currentTab,
      }),
    [currentTab, pathname, replace, state?.corporationId, state?.goBackToList]
  );

  return (
    <>
      <PageDetailsHeader hideBorder={currentTab === LTabs.Lots}>
        <LocationBreadcrumbs />
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setCurrentTab(v)}
        >
          <Tab label={t("COMMON_PAGES_GENERALINFO")} />
          {location && <Tab label={t("COMMON_PAGES_BILLINGINFO")} />}
          {location && (
            <Tab label={t("COMMON_PAGES_FUNCTIONALITIESANDPRODUCTS")} />
          )}
          {location && <Tab label={t("COMMON_PAGES_LOTS")} />}
        </Tabs>
      </PageDetailsHeader>
      {currentTab === LTabs.GeneralInfo && <GeneralInfo />}
      {currentTab === LTabs.BillingInfo && <BillingInfo />}
      {currentTab === LTabs.FunctionalitiesAndProducts && (
        <FunctionalitiesAndProducts
          getData={getLocation}
          entityType={EntityType.Location}
        />
      )}
      {currentTab === LTabs.Lots && <Lots />}
    </>
  );
};

const LocationDetails: React.FC = () => (
  <AddressConfigurationProvider>
    <LocationProvider>
      <LocationDetailsPage />
    </LocationProvider>
  </AddressConfigurationProvider>
);

export default LocationDetails;
