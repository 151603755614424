import { locationTypeLabel, mainLotTypeLabel } from "API/Interfaces";
import { AppTypography } from "Layout/Components";
import FormSeparatorText from "Layout/Components/FormSeparator";
import { FC } from "react";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useLocationContext } from "../Contexts/LocationContext";

interface ParkingManagementProps {}

const TextWrapper = styled.div`
  display: flex;
  > :not(:first-child) {
    margin-left: 10px;
  }
`;

export const ParkingManagement: FC<ParkingManagementProps> = () => {
  const { location } = useLocationContext();

  const locationLabel: TranslationKeys = location?.parkingManagement
    ? locationTypeLabel[location.parkingManagement.locationType]
    : "COMMON_NONE";
  const lotLabel: TranslationKeys = location?.parkingManagement
    ? mainLotTypeLabel[location.parkingManagement.mainLotType]
    : "COMMON_NONE";

  return (
    <>
      <FormSeparatorText translateLabel="COMMON_PAGES_PARKINGMANAGEMENT" />
      <TextWrapper>
        <AppTypography translation="COMMON_PAGES_LOCATIONTYPE" />
        <AppTypography weight="bold" translation={locationLabel} />
      </TextWrapper>
      <TextWrapper>
        <AppTypography translation="COMMON_PAGES_MAINLOTTYPE" />
        <AppTypography weight="bold" translation={lotLabel}></AppTypography>
      </TextWrapper>
    </>
  );
};

export default ParkingManagement;
