import background from "assets/images/background.svg";
import React from "react";
import styled from "styled-components";
import { themeComponents } from "./theme";
const Layout = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #7b59fb;
  background-image: url(${background});
  background-position: center;
  background-size: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-size: cover;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-track {
    background-color: #c4c4c4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${themeComponents.primary};
    border-radius: 20px;
  }
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;
`;

interface Props {}
const AuthLayout: React.FC<Props> = (props) => {
  return (
    <Layout>
      <Content>{props.children}</Content>
    </Layout>
  );
};

export default AuthLayout;
