import { IDataLevel } from "API/Interfaces";
import { config } from ".";
import { apiClient, IResponseApi } from "../Auth/apiClient";
import urls from "./urls";

export type ISaveFileRequest = FormData;
export interface ISaveFileResponse {
  id: string;
}

/*
  API: [POST] /files
  DOC: https://confluence.lbpro.pl/x/-YffBg

  API: [POST] _/files/photo
  DOC: https://confluence.lbpro.pl/x/lpVhBw
  */
const saveFile = async (formData: ISaveFileRequest, photo = false) => {
  const res = await apiClient.post<IResponseApi<ISaveFileResponse>>(
    photo ? urls.files.photos : urls.files.root,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data.data;
};

/*
  API: [GET] /files/{fileId}
  DOC: https://confluence.lbpro.pl/x/C4jfBg
  */
const getFile = async (field: string) => {
  const res = await apiClient.get<File>(`${urls.files.root}/${field}`, {
    responseType: "blob",
  });
  return res.data;
};

/*
API: [GET] /files/corporation/{corporationId}
DOC: https://confluence.lbpro.pl/x/vKQfBw

API: [GET] /files/location/{locationId}
DOC: https://confluence.lbpro.pl/x/waQfBw

API: [GET] /files/lot/{id}
DOC: https://confluence.lbpro.pl/x/oJdhBw
*/
const getFileById = async (id: string, type: IDataLevel) => {
  const response = await apiClient.get<File>(
    `${urls.files.root}${type}/${id}`,
    {
      responseType: "blob",
      headers: {
        SecretKey: config.EXTERNAL_SECRET_KEY,
      },
    }
  );
  return response.data;
};

export const FileServiceApi = {
  saveFile,
  getFile,
  getFileById,
};
export default FileServiceApi;
