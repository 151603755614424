import DragFile from "./Components/DragFile";
import AppButton from "Layout/Components/AppButton";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { useCsvImportContext } from "./Contexts/CsvImportContext";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export interface ImportPageProps {}

const ImportPage: React.FC<ImportPageProps> = () => {
  const { handleImportCsv } = useCsvImportContext();
  const [isFileCorrect, setIsFileCorrect] = useState(false);
  const { goBack } = useHistory();

  return (
    <>
      <DragFile setIsFileCorrect={setIsFileCorrect} />
      <ButtonWrapper topSpacing={2}>
        <AppButton
          translation="DIALOGS_CANCEL"
          variant="outlined"
          onClick={goBack}
        />
        <AppButton
          translation="COMMON_BUTTONS_IMPORT"
          onClick={handleImportCsv}
          disabled={!isFileCorrect}
        />
      </ButtonWrapper>
    </>
  );
};

export default ImportPage;
