import React from "react";
import { getFieldName } from "Utils";
import { FormComponentsFields } from "./types";
import { FormTextField, FormTextFieldProps } from "./FormTextField";

export const FormAdminNameField: React.FC<Partial<FormTextFieldProps>> = (
  props
) => {
  const fieldName = getFieldName<FormComponentsFields>("adminName");
  return (
    <FormTextField
      labelTranslation="COMMON_FORMS_NAME"
      fieldName={fieldName}
      {...props}
    />
  );
};

export default FormAdminNameField;
