import { Typography, TypographyProps } from "@material-ui/core";
import { useTranslate } from "Hooks";
import React from "react";
import styled, { css } from "styled-components";
import { TranslationKeys } from "Translation/translations";

export interface AppTypographyProps extends TypographyProps {
  weight?: string | number;
  /** MUI spacing */
  paddingLeft?: number;
  /** MUI spacing */
  paddingRight?: number;
  /** in rems */
  size?: number;
  line?: number | string;
  disabled?: boolean;
  textCase?: "uppercase" | "lowercase" | "inherit";
  component?: any; //can't get proper type for Typography
  customColor?: string;
  translation?: TranslationKeys;
  wordBreak?: React.CSSProperties["wordBreak"];
}

export const StyledTypographyWrapper = styled.div<AppTypographyProps>`
  > * {
    ${(_) =>
      _.weight &&
      css`
        font-weight: ${_.weight};
      `}
    text-transform: ${(_) => _.textCase};
    padding-left: ${(_) => _.theme.spacing(_.paddingLeft)}px;
    padding-right: ${(_) => _.theme.spacing(_.paddingRight)}px;
    ${(_) =>
      _.customColor &&
      css`
        color: ${_.customColor};
      `}
    ${(_) =>
      _.disabled &&
      css`
        color: ${_.theme.palette.text.disabled};
      `}
    ${(_) =>
      _.size &&
      css`
        font-size: ${_.size}rem;
      `}
    ${(_) =>
      _.line &&
      css`
        line-height: ${_.line};
      `}
    ${(_) =>
      _.wordBreak &&
      css`
        word-break: ${_.wordBreak};
      `}
  }
`;
export const AppTypography: React.FC<AppTypographyProps> = ({
  weight,
  paddingLeft,
  paddingRight,
  textCase,
  size,
  line,
  disabled,
  customColor,
  translation,
  wordBreak,
  ...props
}) => {
  const t = useTranslate();
  return (
    <StyledTypographyWrapper
      weight={weight}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      disabled={disabled}
      size={size}
      textCase={textCase}
      line={line}
      customColor={customColor}
      wordBreak={wordBreak}
    >
      <Typography {...props}>
        {translation ? t(translation) : props.children}
      </Typography>
    </StyledTypographyWrapper>
  );
};
AppTypography.defaultProps = {
  paddingLeft: 0,
  paddingRight: 0,
  textCase: "inherit",
};
export default AppTypography;
