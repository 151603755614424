import {
  AddressConfiguration,
  ICorporationDetailsRequest,
  ICorporationDetailsResponse,
} from "API/Interfaces";
import { FormAddressConfiguration } from "Pages/CorporationDetails/Contexts/CorporationContext";
import { CorporationForm } from "Pages/CorporationDetails/Components/CoporationForm/CorporationDetailsForm.schema";

export const getCorporationToForm = (
  res: ICorporationDetailsResponse | null | undefined
): CorporationForm => {
  const { address, administrator, destinationSystems, logoId, name } =
    res || {};
  const { addressConfiguration, countryId, timeZoneName } = address || {};
  const { zipCode, city, county, state, street1, street2 } =
    addressConfiguration || {};
  const {
    emailAddresses,
    mobilePhoneNumber,
    name: adminName,
    officePhoneNumber,
  } = administrator || {};
  return {
    addressCity: city?.value ?? "",
    addressCounty: county?.value ?? "",
    addressPostalCode: zipCode?.value ?? "",
    addressState: state?.value ?? "",
    addressStreet1: street1?.value ?? "",
    addressStreet2: street2?.value ?? "",
    adminEmail: emailAddresses ? emailAddresses[0] : "",
    adminMobilePhone: mobilePhoneNumber ?? "",
    adminName: adminName ?? "",
    adminOfficePhone: officePhoneNumber ?? "",
    logoId: logoId ?? "",
    country: countryId ?? 236,
    name: name ?? "",
    destinationSystems: destinationSystems ?? [1],
    timezone: timeZoneName || "",
  };
};

export const formToSaveCorporation = (
  res: CorporationForm
): ICorporationDetailsRequest => {
  const {
    addressCity,
    addressCounty,
    addressPostalCode,
    addressState,
    addressStreet1,
    addressStreet2,
    adminEmail,
    adminMobilePhone,
    adminName,
    adminOfficePhone,
    country,
    destinationSystems,
    logoId,
    name,
    timezone,
  } = res;
  return {
    name,
    logoId: logoId ? logoId : null,
    destinationSystems,
    administrator: {
      emailAddresses: [adminEmail],
      mobilePhoneNumber: adminMobilePhone,
      name: adminName,
      officePhoneNumber: adminOfficePhone,
    },
    address: {
      countryId: country,
      timeZoneName: timezone || "",
      addressConfiguration: {
        zipCode: addressPostalCode,
        city: addressCity,
        county: addressCounty,
        state: addressState,
        street1: addressStreet1,
        street2: addressStreet2,
      },
    },
  };
};

export const apiToFormAddressConfiguration = (
  arg: AddressConfiguration
): FormAddressConfiguration => ({
  addressCity: arg.city,
  addressCounty: arg.county,
  addressPostalCode: arg.zipCode,
  addressState: arg.state,
  addressStreet1: arg.street1,
  addressStreet2: arg.street2,
});
