import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useCsvImportContext } from "../Contexts/CsvImportContext";
import { useTranslate } from "Hooks";

export interface OnlyErrorsCheckProps {}

const OnlyErrorsCheck: React.FC<OnlyErrorsCheckProps> = () => {
  const { onlyWithErrors, togleOnlyWithErrors } = useCsvImportContext();
  const t = useTranslate();

  const handleChange = (e: any) => {
    togleOnlyWithErrors();
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={onlyWithErrors}
          onChange={handleChange}
          name="checkedB"
          color="primary"
        />
      }
      label={t("COMMON_FORMS_SHOWONLYDATAWITHERRORS")}
    />
  );
};

export default OnlyErrorsCheck;
