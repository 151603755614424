import CorporationServiceApi from "API/Services/CorporationServiceApi";
import LocationServiceApi from "API/Services/LocationServiceApi";
import LotServiceApi from "API/Services/LotServiceApi";
import { usePopupContext } from "Contexts";
import { useLoader, useTranslate } from "Hooks";
import { AppDialog, AppTypography } from "Layout/Components";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "Routing/routes";
import { TranslationKeys } from "Translation";
import { isErrorCode } from "Utils";

const SERVICES = {
  corporation: {
    delete: CorporationServiceApi.deleteCorporation,
    title: "DIALOGS_DELETECORPDIALOGTITLE" as TranslationKeys,
    info: "DIALOGS_DELETECORPINFORMATION" as TranslationKeys,
    exists: "DIALOGS_CORPORATIONCANNOTBEDELETED" as TranslationKeys,
  },
  location: {
    delete: LocationServiceApi.deleteLocation,
    title: "DIALOGS_DELETELOCATIONDIALOGTITLE" as TranslationKeys,
    info: "DIALOGS_DELETELOCATIONINFORMATION" as TranslationKeys,
    exists: "DIALOGS_LOCATIONCANNOTBEDELETED" as TranslationKeys,
  },
  lot: {
    delete: LotServiceApi.deleteLot,
    title: "DIALOGS_DELETELOTDIALOGTITLE" as TranslationKeys,
    info: "DIALOGS_DELETELOTINFORMATION" as TranslationKeys,
    exists: "DIALOGS_LOTCANNOTBEDELETED" as TranslationKeys,
  },
};

type PushType = "none" | "goBack" | "goRoot";

export const useDeleteDialog = (
  mode: keyof typeof SERVICES,
  pushBack: PushType = "goBack",
  callBack?: (id: string) => any
) => {
  const service = SERVICES[mode];
  const { goBack, push } = useHistory();
  const t = useTranslate();
  const [setLoading] = useLoader();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { showPopup, setPopup } = usePopupContext();
  const handleDelete = (id: string) => {
    setLoading(true);
    setDialogOpen(false);
    service
      .delete(id)
      .then(() => {
        if (pushBack === "goBack") goBack();
        if (pushBack === "goRoot") push(routes.root);
        callBack && callBack(id);
      })
      .catch((err: any) => {
        setPopup({
          variant: "error",
          titleTranslation: "DIALOGS_ERROR",
          descriptionTranslation: isErrorCode(err, "CannotModify")
            ? service.exists
            : "DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN",
        });
        showPopup();
      })
      .finally(() => setLoading(false));
  };

  const Dialog: React.FC<{ id: string; name: string }> = (props) => (
    <AppDialog
      open={dialogOpen}
      agreeAction={() => handleDelete(props.id)}
      handleOpen={setDialogOpen}
      title={t(service.title)}
      cancelButton={t("DIALOGS_CANCEL")}
      agreeButton={t("DIALOGS_DELETE")}
    >
      <AppTypography align="left" variant="body1" weight="bold">
        {props.name}
      </AppTypography>
      <AppTypography align="left" variant="body1" translation={service.info} />
    </AppDialog>
  );

  return [Dialog, () => setDialogOpen(true)] as const;
};

export default useDeleteDialog;
