import { i18n, Translations } from "Translation";
import * as Yup from "yup";

export const usernameSchema = Yup.string().required(
  i18n.t(Translations.COMMON_AUTH_USERNAMECANNOTBEEMPTY)
);

export const passwordSchema = Yup.string().required(
  i18n.t(Translations.COMMON_AUTH_PASSWORDCANNOTBEEMPTY)
);
