import { ImportCsvServiceApi } from "API/Services/ImportCsvServiceApi";
import { useParams } from "react-router-dom";
import { useLoaderContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";

const useDeleteImportLot = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, setLoading } = useLoaderContext();
  const { setError } = useErrorContext();
  const handler = async (lotId: string) => {
    setLoading(true);
    ImportCsvServiceApi.deleteImportLot(id, lotId)
      .catch(() => setError())
      .finally(() => setLoading(false));
  };

  return [handler, isLoading] as const;
};

export default useDeleteImportLot;
