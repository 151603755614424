import { mainLotTypeLabel } from "API/Interfaces";
import { AppTypography } from "Layout/Components";
import FormSeparatorText from "Layout/Components/FormSeparator";
import { FC } from "react";
import styled from "styled-components";
import { useLotContext } from "../Contexts/LotContext";

interface ParkingManagementProps {}

const TextWrapper = styled.div`
  display: flex;
  > :not(:first-child) {
    margin-left: 10px;
  }
`;

export const ParkingManagement: FC<ParkingManagementProps> = () => {
  const { lot } = useLotContext();
  return lot ? (
    <>
      <FormSeparatorText translateLabel="COMMON_PAGES_PARKINGMANAGEMENT" />
      <TextWrapper>
        <AppTypography translation="COMMON_PAGES_LOTTYPE" />
        <AppTypography
          weight="bold"
          translation={
            lot.parkingManagement
              ? mainLotTypeLabel[lot.parkingManagement.mainLotType]
              : "COMMON_NONE"
          }
        />
      </TextWrapper>
    </>
  ) : null;
};

export default ParkingManagement;
