import React from "react";
import { getFieldName } from "Utils";
import { FormComponentsFields } from "./types";
import { FormTextField, FormTextFieldProps } from "./FormTextField";

export const FormAdminEmailField: React.FC<Partial<FormTextFieldProps>> = (
  props
) => {
  const fieldName = getFieldName<FormComponentsFields>("adminEmail");
  return (
    <FormTextField
      labelTranslation="COMMON_FORMS_EMAIL"
      fieldName={fieldName}
      {...props}
    />
  );
};

export default FormAdminEmailField;
