import styled from "styled-components";
import { SuccessFileIcon } from "assets/icons/Icons";
import AppTypography from "Layout/Components/AppTyphography";
import AppButton from "Layout/Components/AppButton";
import { useHistory } from "react-router-dom";

export interface SuccessImportProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 400px;
`;

const SuccessImport: React.FC<SuccessImportProps> = () => {
  const { push } = useHistory();

  return (
    <Container>
      <SuccessFileIcon />
      <AppTypography
        color="secondary"
        variant="h2"
        translation="COMMON_PAGES_YOURFILEHASBEENSUCCESSFULLYIMPORTED"
      ></AppTypography>
      <AppButton
        onClick={() => push("/")}
        translation="DIALOGS_OK"
      ></AppButton>
    </Container>
  );
};

export default SuccessImport;
