import { YupSchemaKeys } from "API/Interfaces";
import { i18nt } from "Translation";
import { ImportLotsResponse } from "API/Services/ImportCsvServiceApi";
import { requiredText, regexNumberDot, regexNumber } from "Utils/formUtils";
import * as yup from "yup";

export interface EdtiFormFields {
  name: string;
  number?: string;
  longitude: number | string;
  latitude: number | string;
  capacity?: number | null;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  countryName: string;
  id: string;
  rowNumber: number | null;
  isBasedOnCoordinates: boolean;
}

export const initial = (l: ImportLotsResponse | null): EdtiFormFields => ({
  name: l?.name || "",
  number: l?.number || "",
  latitude: l?.gpsCoordinates.latitude || "",
  longitude: l?.gpsCoordinates.longitude || "",
  capacity: l?.capacity || null,
  street1: l?.address.street1 || "",
  street2: l?.address.street2 || "",
  city: l?.address.city || "",
  state: l?.address.state || "",
  zipCode: l?.address.zipCode || "",
  county: l?.address.county || "",
  countryName: l?.address.countryName || "",
  rowNumber: l?.rowNumber || null,
  id: l?.id || "",
  isBasedOnCoordinates: l?.isBasedOnCoordinates || false,
});

type PartialSchema = YupSchemaKeys<EdtiFormFields>;

export const schemaObj = yup.object<PartialSchema>({
  name: yup
    .string()
    .max(200, i18nt("COMMON_FORMS_MAXCHARACTER200"))
    .required(requiredText),
  number: yup
    .string()
    .matches(regexNumber, i18nt("COMMON_FORMS_INCORRECTNUMBERFORMAT"))
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  latitude: yup
    .string()
    .required(requiredText)
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50"))
    .matches(regexNumberDot, i18nt("COMMON_FORMS_INCORRECTNUMBERFORMAT"))
    .test(
      "isCorrectLatitude",
      i18nt("COMMON_FORMS_INCORRECTLATITUDE"),
      (value) => Number(value) >= -90 && Number(value) <= 90
    ),
  longitude: yup
    .string()
    .required(requiredText)
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50"))
    .matches(regexNumberDot, i18nt("COMMON_FORMS_INCORRECTNUMBERFORMAT"))
    .test(
      "isCorrectLongitude",
      i18nt("COMMON_FORMS_INCORRECTLONGITUDE"),
      (value) => Number(value) >= -180 && Number(value) <= 180
    ),
  capacity: yup
    .string()
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50"))
    .matches(regexNumber, i18nt("COMMON_FORMS_FIELDMUSTBEANUMBER")),
  countryName: yup.string().max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  city: yup.string().max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  county: yup.string().max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  state: yup.string().max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  street1: yup.string().max(100, i18nt("COMMON_FORMS_MAXCHARACTER100")),
  street2: yup.string().max(100, i18nt("COMMON_FORMS_MAXCHARACTER100")),
  zipCode: yup.string().max(50, i18nt("COMMON_FORMS_MAXCHARACTER50")),
  id: yup.string(),
  rowNumber: yup.string(),
  isBasedOnCoordinates: yup.boolean(),
});
