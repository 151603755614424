import React from "react";
import { useTranslate } from "Hooks";
import { AppDialog, AppTypography } from "Layout/Components";
import { ImportLotsResponse } from "API/Services/ImportCsvServiceApi";
import useDeleteImportLot from "../../Hooks/useDeleteImportLot";
import { useCsvImportContext } from "../../Contexts/CsvImportContext";

export interface DeleteCorporationDialogProps {
  open: boolean;
  selectedItem: ImportLotsResponse | null;
  onClose: any;
}

export const DeleteImportLotDialog: React.FC<DeleteCorporationDialogProps> = ({
  open,
  selectedItem,
  onClose,
}) => {
  const t = useTranslate();
  const [DeleteLot] = useDeleteImportLot();
  const { handleDeleteLot } = useCsvImportContext();

  const handleDeleteCorporation = () => {
    selectedItem?.id &&
      DeleteLot(selectedItem.id).then(() => {
        onClose(false);
        handleDeleteLot(selectedItem);
      });
  };

  return (
    <AppDialog
      open={open}
      agreeAction={() => handleDeleteCorporation()}
      handleOpen={onClose}
      onClose={() => onClose(false)}
      title={t("COMMON_PAGES_DELETEDATA")}
      cancelButton={t("DIALOGS_CANCEL")}
      agreeButton={t("DIALOGS_DELETE")}
    >
      <AppTypography
        variant="body1"
        weight="bold"
        translation="DIALOGS_WANTTODELETETHESELECTEDDATA"
      />
    </AppDialog>
  );
};

export default DeleteImportLotDialog;
