import { ILocationDto } from "API/Interfaces";
import LocationServiceApi from "API/Services/LocationServiceApi";
import { useGenericList } from "Hooks";
import { useParams } from "react-router-dom";

const useGetLocations = () => {
  const { id }: { id: string } = useParams();

  return useGenericList<ILocationDto>(
    LocationServiceApi.getLocations,
    id,
    false
  );
};

export default useGetLocations;
