import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { ICorporationDto, ILocationsList } from "API/Interfaces/ICorporation";
import { ExpandetArrow } from "assets/icons/Icons";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppButton, AppTypography } from "Layout/Components";
import AppEmptyInformation from "Layout/Components/AppEmptyInformation";
import IntegrationStatusChip from "Layout/Components/IntegrationStatusChip";
import LinesForLineList from "Layout/Components/LinesForLineList";
import ProductType from "Layout/Components/ProductType";
import { themeComponents } from "Layout/theme";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { generateRoute } from "Routing/routes";
import styled from "styled-components";
import LotsList from "./LotsList";

interface LocationListProps {
  corporation: ICorporationDto;
}

const LocationsSummaryContent = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px 15px 6px;
`;

const LocationData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  max-width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    max-width: 60%;
  }
`;

const LocAddress = styled.div`
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  user-select: text;
`;

const LotCount = styled.p`
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  min-width: 120px;
  text-align: right;
  user-select: text;
`;

const LineList = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  padding-left: 10px;
`;

const ExpandedButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 0 8px;
  }
  ${(_) => _.theme.breakpoints.down("lg")} {
    flex-direction: column;
    > div {
      padding: 8px 0px;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;
`;

const LotDetails = styled.div`
  display: flex;
`;

const AccordionSummaryStyled = styled(AccordionSummary)<{
  $hideBorder?: boolean;
}>`
  display: flex;
  min-height: unset;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: ${({ theme, $hideBorder }) =>
    !$hideBorder && `1px solid ${theme.palette.neutral.main}`};

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const MoreButton = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 6px;
`;

const LocationList: React.FC<LocationListProps> = ({ corporation }) => {
  const [expanded, setExpanded] = useState(null);
  const { listOfLocations, numberOfLocations } = corporation;
  const t = useTranslate();
  const { down } = useBreakpoints();
  const { push } = useHistory();

  const handleChange = (panel: any) => (_: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {listOfLocations.map((location: ILocationsList, i: Number) => (
        <LineList key={location.id}>
          <LinesForLineList
            isFirstElement={i === 0}
            isLastElement={
              i === listOfLocations.length - 1 && numberOfLocations <= 5
            }
            showLines={listOfLocations.length > 1}
          />
          <StyledAccordion
            key={location.id}
            expanded={expanded === `panel${i}-b`}
            onChange={handleChange(`panel${i}-b`)}
          >
            <AccordionSummaryStyled
              $hideBorder={i === listOfLocations.length - 1}
              expandIcon={
                <ExpandetArrow expanded={expanded === `panel${i}-b`} />
              }
            >
              <LocationsSummaryContent>
                <LocationData>
                  <Box display="flex" alignItems="center">
                    <AppTypography
                      size={1}
                      wordBreak="break-word"
                      customColor={themeComponents.titleText}
                      paddingRight={1}
                      onClick={() => push(generateRoute.location(location.id))}
                    >
                      {location.name}
                    </AppTypography>
                    <IntegrationStatusChip
                      integrationStatus={location.integrationStatus}
                    />
                    <ProductType
                      integratedWith={location.integratedWithSystem}
                    />
                  </Box>
                  <LocAddress>{location.address}</LocAddress>
                </LocationData>
                {down("md") && (
                  <LotDetails>
                    {expanded === `panel${i}-b` && (
                      <ExpandedButtonsWrapper>
                        <AppButton
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={() =>
                            push(generateRoute.lot("new"), {
                              locationId: location.id,
                            })
                          }
                          translation="COMMON_BUTTONS_ADDNEWLOT"
                        />
                        <AppButton
                          variant="outlined"
                          onClick={() =>
                            push(generateRoute.import(location.id))
                          }
                          translation="COMMON_BUTTONS_IMPORTFROMCSV"
                        />
                      </ExpandedButtonsWrapper>
                    )}
                    <LotCount>
                      {location.numberOfLots}{" "}
                      {t(
                        location.numberOfLots === 1
                          ? "COMMON_PAGES_LOT"
                          : "COMMON_PAGES_LOTS"
                      )}
                    </LotCount>
                  </LotDetails>
                )}
              </LocationsSummaryContent>
            </AccordionSummaryStyled>
            <AccordionDetails style={{ borderTop: "none" }}>
              <LotsList
                locationId={location.id}
                lots={location.listOfLots}
                numberOfLots={location.numberOfLots}
                corporation={corporation}
              />
            </AccordionDetails>
          </StyledAccordion>
        </LineList>
      ))}

      {numberOfLocations > 5 && (
        <LineList>
          <LinesForLineList isLastElement={true} isFirstElement={false} />
          <MoreButton>
            <AppButton
              onClick={() =>
                push(generateRoute.corporation(corporation.id), {
                  currentTab: 2,
                })
              }
              translation="COMMON_BUTTONS_SEEMORELOCATIONS"
              variant="outlined"
            />
          </MoreButton>
        </LineList>
      )}
      {numberOfLocations === 0 && (
        <AppEmptyInformation text={t("COMMON_NOLOCATIONS")} />
      )}
    </>
  );
};

export default LocationList;
