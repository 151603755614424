import { FormComponentsAddressFields } from "API/Interfaces";
import { useFormikContext } from "formik";
import { FormTextFieldProps } from "Layout/FormComponents";
import React from "react";
import { AddressFieldsKeys } from "Utils/AddressConfiguration/addressConfigurationSchema";
import { useAddressConfigurationContext } from "./AddressConfigurationContext";
export interface WithConfigurationProps {}

export function withAddressConfiguration<P extends Partial<FormTextFieldProps>>(
  Component: React.ComponentType<P>
) {
  const WithAddressConfiguration = (
    props: Partial<FormTextFieldProps> &
      Required<Pick<FormTextFieldProps, "fieldName">>
  ) => {
    const { setFieldValue } = useFormikContext<FormComponentsAddressFields>();

    const { addressConfiguration } = useAddressConfigurationContext();
    const configuration =
      addressConfiguration[props.fieldName as AddressFieldsKeys];
    if (!configuration) {
      throw Error("Missing error configuration");
    }
    const disabled = !configuration.isAvailable;
    const required = configuration.isRequired;
    React.useEffect(() => {
      if (!configuration.isAvailable) {
        setFieldValue(props.fieldName, "");
      }
    }, [configuration.isAvailable, props.fieldName, setFieldValue]);
    return (
      <Component
        {...(props as P)}
        disabled={disabled}
        inputRequired={required}
      />
    );
  };
  return WithAddressConfiguration;
}

export default withAddressConfiguration;
