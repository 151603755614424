import { FormTextField, FormCheckbox } from "Layout/FormComponents";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";
import InputWithError from "./InputWithError";
export interface FieldsProps {
  errors?: ImportErrors[];
}

export const Name: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="name"
    labelTranslation="COMMON_FORMS_NAME"
    errors={errors}
  />
);

export const LotNumber: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="number"
    labelTranslation="COMMON_FORMS_LOTNUMBER"
    errors={errors}
  />
);

export const Latitude: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="latitude"
    labelTranslation="COMMON_FORMS_LATITUDE"
    errors={errors}
  />
);

export const Longitude: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="longitude"
    labelTranslation="COMMON_FORMS_LONGITUDE"
    errors={errors}
  />
);

export const Capacity: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="capacity"
    labelTranslation="COMMON_FORMS_CAPACITY"
    errors={errors}
  />
);

export const Street1: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="street1"
    labelTranslation="COMMON_FORMS_STREET1"
    errors={errors}
  />
);

export const Street2: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="street2"
    labelTranslation="COMMON_FORMS_STREET2"
    errors={errors}
  />
);

export const City: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="city"
    labelTranslation="COMMON_FORMS_CITY"
    errors={errors}
  />
);

export const State: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="state"
    labelTranslation="COMMON_FORMS_STATE"
    errors={errors}
  />
);

export const ZipCode: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="zipCode"
    labelTranslation="COMMON_FORMS_POSTALCODE"
    errors={errors}
  />
);

export const County: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="county"
    labelTranslation="COMMON_FORMS_COUNTY"
    errors={errors}
  />
);
export const CountryName: React.FC<FieldsProps> = ({ errors }) => (
  <InputWithError
    fieldName="countryName"
    labelTranslation="COMMON_FORMS_COUNTRY"
    errors={errors}
  />
);

//Technical fields
export const Id: React.FC<FieldsProps> = () => (
  <FormTextField fieldName="id" hidden />
);

export const RowNumber: React.FC<FieldsProps> = () => (
  <FormTextField fieldName="rowNumber" hidden />
);

export const IsBasedOnCoordinates: React.FC<FieldsProps> = () => (
  <FormCheckbox fieldName="isBasedOnCoordinates" />
);
