import { MenuItem } from "@material-ui/core";
import { useTimeZonesCountryChange } from "Hooks";
import { FormComponentsFields } from "Layout/FormComponents/types";
import React from "react";
import { getFieldName } from "Utils";
import FormSelectField, { FormSelectFieldProps } from "./FormSelectField";

export const FormTimeZonesField: React.FC<
  Partial<FormSelectFieldProps>
> = () => {
  const fieldName = getFieldName<FormComponentsFields>("timezone");
  const { timezones, isLoading } = useTimeZonesCountryChange();

  return (
    <FormSelectField
      fieldName={fieldName}
      labelTranslation="COMMON_FORMS_TIMEZONE"
      disabled={isLoading}
      inputRequired
    >
      <MenuItem disabled value="" />
      {timezones.map((timezone) => (
        <MenuItem value={timezone.name} key={timezone.name}>
          {timezone.displayName}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

export default FormTimeZonesField;
