import React from "react";

export const Logo = () => {
  return (
    <svg
      width="102"
      height="68"
      viewBox="0 0 102 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.1449 0H5.85295C4.30065 0 2.81193 0.616649 1.71429 1.71429C0.616649 2.81193 0 4.30065 0 5.85295L0 54.1446C-1.04613e-07 55.6971 0.61658 57.1861 1.71416 58.2841C2.81174 59.382 4.30046 59.9992 5.85295 59.9997H15.5964V66.13C15.5964 66.6716 15.8715 68.2429 17.316 66.8543C18.7604 65.4658 24.1727 59.9997 24.1727 59.9997H96.1449C97.6976 59.9992 99.1865 59.3821 100.284 58.2842C101.382 57.1863 101.999 55.6973 102 54.1446V5.85295C101.999 4.30046 101.382 2.81174 100.284 1.71416C99.1863 0.61658 97.6974 -1.04613e-07 96.1449 0"
        fill="white"
      />
      <path
        d="M49.6861 26.6121C51.1907 26.6121 52.7104 26.6121 54.2236 26.6121C54.7997 26.6121 55.0533 26.4487 54.8169 25.834C54.0237 23.7748 51.9474 22.3949 49.6131 22.3949C47.2788 22.3949 45.4926 23.6695 44.6349 25.834C44.4006 26.4294 44.5103 26.6314 45.1443 26.625C46.649 26.6099 48.1708 26.625 49.6861 26.625V26.6121ZM60.4162 30.5778H44.3447C44.5597 33.4774 46.9671 35.4592 49.9011 35.2572C51.5519 35.1476 52.949 34.55 53.985 33.1873C54.1488 32.9601 54.3949 32.8058 54.6707 32.7574C56.502 32.7208 58.026 32.7359 59.8487 32.7359C60.2464 32.7359 60.1712 32.9508 60.1669 33.0475C60.0486 35.2808 56.7277 38.7393 53.6648 39.8162C48.5276 41.6195 43.0379 39.8398 40.1619 35.4377C37.243 30.9755 37.7588 24.7507 41.3678 20.8881C44.8284 17.1824 50.8253 16.2904 55.2983 18.8311C59.5972 21.2686 61.4522 26.2273 60.7429 30.2232C60.7214 30.3457 60.687 30.5907 60.4269 30.5907"
        fill="#7A59FA"
      />
      <path
        d="M71.6314 34.4491H80.3174C80.5452 34.4491 80.7215 34.6232 80.7215 34.965V39.3026C80.7251 39.3628 80.7161 39.4232 80.6949 39.4797C80.6737 39.5362 80.6409 39.5876 80.5985 39.6306C80.5561 39.6736 80.5052 39.7072 80.449 39.7292C80.3927 39.7512 80.3325 39.7611 80.2722 39.7582H63.3561C62.6037 39.7367 62.9906 38.8727 63.2013 38.5094C65.9827 33.7663 68.7748 29.026 71.5777 24.2886C71.7196 24.0479 71.8421 23.7943 72.0549 23.3966C71.6056 23.3644 71.3047 23.3257 71.0038 23.3257C68.9339 23.3257 67.5346 23.3257 65.4625 23.3257C64.3405 23.3257 64.6694 22.8958 64.0933 21.7953C63.0594 19.8156 62.3179 18.9193 62.3179 18.6592C62.3179 18.1305 62.5027 17.9456 63.0336 17.9478C68.2847 17.9478 74.875 17.9478 80.1261 17.9478C80.8461 17.9478 81.0267 18.3777 80.5946 19.1579C79.8703 20.4476 79.1029 21.7244 78.3463 23.0011C76.3402 26.4102 74.329 29.8185 72.3128 33.2261C72.1237 33.5463 71.9517 33.8709 71.6379 34.4384"
        fill="#7A59FA"
      />
      <path
        d="M20.3494 22.6614C19.3908 22.6056 19.6874 21.8747 20.1345 21.3137C22.7762 18.0896 25.6586 14.8353 28.0531 12.0861C28.7409 11.293 29.7855 10.8373 29.7855 12.2538C29.7855 13.6703 29.7855 15.3426 29.7855 16.8192C29.7855 17.9498 29.7855 17.952 30.9419 17.952C32.2853 17.952 35.2967 17.952 36.638 17.952C37.027 17.952 37.3881 18.1949 37.0958 18.6936C36.9668 18.9085 35.7287 20.6109 35.1978 22.3283C35.1739 22.4348 35.1146 22.5301 35.0294 22.5984C34.9443 22.6668 34.8384 22.7041 34.7292 22.7044C33.2031 22.7044 32.6443 22.6829 30.8194 22.6829C29.9962 22.6829 29.7748 22.928 29.7877 23.7233C29.8328 26.2596 29.7877 28.8003 29.8092 31.3366C29.8092 33.3163 30.9462 34.4877 32.9259 34.5758C33.6545 34.6081 34.3853 34.6167 35.114 34.5909C35.6986 34.5715 35.9738 34.7628 35.9523 35.3905C35.9222 36.6436 35.9673 37.8989 35.9243 39.152C35.9243 39.3842 35.8018 39.7969 35.1011 39.7969C32.6701 39.8721 30.7614 40.1472 28.47 39.2939C26.1185 38.4191 24.601 36.7468 24.2442 34.2857C23.9562 32.2824 24.0035 30.2318 23.9476 28.2006C23.9068 26.6831 23.8982 25.1655 23.9476 23.6502C23.9777 22.857 23.694 22.6937 22.9417 22.6786C21.9056 22.6593 21.2952 22.6593 20.3322 22.6593"
        fill="#7A59FA"
      />
      <path
        d="M25.3332 46.093H24.2176C24.181 46.0924 24.146 46.0775 24.1202 46.0513C24.0945 46.0252 24.0801 45.99 24.0801 45.9533V45.3536C24.0801 45.3169 24.0945 45.2817 24.1202 45.2555C24.146 45.2294 24.181 45.2144 24.2176 45.2139H27.3988C27.4359 45.2139 27.4714 45.2286 27.4976 45.2548C27.5238 45.281 27.5385 45.3165 27.5385 45.3536V45.949C27.5385 45.986 27.5238 46.0216 27.4976 46.0478C27.4714 46.074 27.4359 46.0887 27.3988 46.0887H26.2854V50.1726C26.2844 50.209 26.2696 50.2436 26.2441 50.2695C26.2186 50.2954 26.1842 50.3107 26.1478 50.3124H25.4708C25.4344 50.3107 25.4001 50.2954 25.3745 50.2695C25.349 50.2436 25.3343 50.209 25.3332 50.1726V46.093Z"
        fill="black"
      />
      <path
        d="M29.4614 45.3536C29.4614 45.3169 29.4758 45.2817 29.5016 45.2555C29.5273 45.2294 29.5623 45.2144 29.599 45.2139H32.5631C32.5998 45.2144 32.6347 45.2294 32.6605 45.2555C32.6862 45.2817 32.7006 45.3169 32.7006 45.3536V45.949C32.7006 45.9857 32.6862 46.0209 32.6605 46.047C32.6347 46.0732 32.5998 46.0881 32.5631 46.0887H30.4136V47.2881H32.2127C32.2487 47.2897 32.2828 47.3047 32.3082 47.3301C32.3337 47.3556 32.3487 47.3897 32.3503 47.4256V48.0318C32.3503 48.0683 32.3358 48.1033 32.31 48.1291C32.2842 48.1549 32.2492 48.1694 32.2127 48.1694H30.4136V49.4375H32.5631C32.5996 49.4375 32.6346 49.452 32.6604 49.4778C32.6862 49.5036 32.7006 49.5386 32.7006 49.5751V50.1726C32.7006 50.2093 32.6862 50.2445 32.6605 50.2707C32.6347 50.2968 32.5998 50.3118 32.5631 50.3124H29.599C29.5623 50.3118 29.5273 50.2968 29.5016 50.2707C29.4758 50.2445 29.4614 50.2093 29.4614 50.1726V45.3536Z"
        fill="black"
      />
      <path
        d="M36.8523 45.1388C37.5062 45.1226 38.1399 45.366 38.6148 45.8158C38.6291 45.8287 38.6405 45.8445 38.6483 45.8621C38.6562 45.8797 38.6602 45.8987 38.6602 45.9179C38.6602 45.9372 38.6562 45.9562 38.6483 45.9738C38.6405 45.9914 38.6291 46.0071 38.6148 46.02L38.172 46.48C38.161 46.4935 38.147 46.5044 38.1312 46.5119C38.1154 46.5193 38.0982 46.5232 38.0807 46.5232C38.0632 46.5232 38.046 46.5193 38.0302 46.5119C38.0144 46.5044 38.0004 46.4935 37.9893 46.48C37.6792 46.2065 37.2809 46.0539 36.8673 46.0501C36.434 46.072 36.0256 46.2595 35.7267 46.574C35.4277 46.8884 35.261 47.3057 35.261 47.7396C35.261 48.1735 35.4277 48.5908 35.7267 48.9052C36.0256 49.2197 36.434 49.4072 36.8673 49.4291C37.276 49.4254 37.6702 49.2777 37.9807 49.0121C38.0059 48.9897 38.0384 48.9774 38.0721 48.9774C38.1058 48.9774 38.1383 48.9897 38.1634 49.0121L38.6148 49.4785C38.6278 49.4915 38.6381 49.5069 38.6452 49.5239C38.6522 49.5408 38.6558 49.559 38.6558 49.5774C38.6558 49.5958 38.6522 49.6139 38.6452 49.6309C38.6381 49.6479 38.6278 49.6633 38.6148 49.6763C38.1413 50.1355 37.5054 50.3889 36.8458 50.3813C36.4953 50.3913 36.1463 50.3309 35.8195 50.2037C35.4927 50.0765 35.1947 49.885 34.9432 49.6406C34.6917 49.3962 34.4918 49.1039 34.3553 48.7809C34.2188 48.4578 34.1484 48.1107 34.1484 47.76C34.1484 47.4093 34.2188 47.0622 34.3553 46.7392C34.4918 46.4162 34.6917 46.1238 34.9432 45.8794C35.1947 45.635 35.4927 45.4435 35.8195 45.3163C36.1463 45.1891 36.4953 45.1287 36.8458 45.1388"
        fill="black"
      />
      <path
        d="M40.4507 45.3536C40.4517 45.3172 40.4665 45.2826 40.492 45.2567C40.5175 45.2308 40.5519 45.2155 40.5882 45.2139H41.2696C41.3067 45.2139 41.3422 45.2286 41.3684 45.2548C41.3946 45.281 41.4093 45.3165 41.4093 45.3536V47.2881H43.7737V45.3536C43.7737 45.3169 43.7882 45.2817 43.8139 45.2555C43.8396 45.2294 43.8746 45.2144 43.9113 45.2139H44.5819C44.6183 45.2155 44.6526 45.2308 44.6782 45.2567C44.7037 45.2826 44.7184 45.3172 44.7195 45.3536V50.1726C44.7184 50.209 44.7037 50.2436 44.6782 50.2695C44.6526 50.2954 44.6183 50.3107 44.5819 50.3124H43.9113C43.8746 50.3118 43.8396 50.2968 43.8139 50.2707C43.7882 50.2445 43.7737 50.2093 43.7737 50.1726V48.1629H41.4093V50.1726C41.4093 50.191 41.4057 50.2092 41.3987 50.2261C41.3917 50.2431 41.3814 50.2585 41.3684 50.2714C41.3554 50.2844 41.34 50.2947 41.3231 50.3017C41.3061 50.3087 41.288 50.3124 41.2696 50.3124H40.5925C40.5562 50.3107 40.5218 50.2954 40.4963 50.2695C40.4708 50.2436 40.456 50.209 40.455 50.1726L40.4507 45.3536Z"
        fill="black"
      />
      <path
        d="M46.9253 45.2765C46.9258 45.259 46.9298 45.2418 46.9371 45.2258C46.9443 45.2099 46.9546 45.1955 46.9673 45.1835C46.9801 45.1715 46.9951 45.1622 47.0115 45.156C47.0279 45.1498 47.0454 45.147 47.0629 45.1475H47.2456L50.2741 48.3717V45.3539C50.2741 45.3168 50.2889 45.2813 50.3151 45.2551C50.3413 45.2289 50.3768 45.2142 50.4138 45.2142H51.0823C51.1189 45.2157 51.1535 45.231 51.1794 45.2568C51.2052 45.2827 51.2205 45.3173 51.222 45.3539V50.2525C51.2204 50.2882 51.2048 50.3219 51.1788 50.3464C51.1527 50.3708 51.1181 50.3842 51.0823 50.3836H50.9082L47.8646 47.0412V50.1729C47.864 50.2096 47.8491 50.2446 47.8229 50.2704C47.7968 50.2961 47.7616 50.3105 47.7249 50.3105H47.0736C47.0375 50.3094 47.0031 50.2946 46.9775 50.269C46.952 50.2435 46.9371 50.2091 46.936 50.1729L46.9253 45.2765Z"
        fill="black"
      />
      <path
        d="M55.6704 49.4376C56.0021 49.4389 56.3267 49.3416 56.6031 49.1581C56.8794 48.9746 57.095 48.7131 57.2226 48.4069C57.3501 48.1007 57.3838 47.7635 57.3194 47.4381C57.255 47.1127 57.0954 46.8137 56.8608 46.5792C56.6263 46.3446 56.3273 46.185 56.0019 46.1206C55.6765 46.0562 55.3393 46.0899 55.0331 46.2174C54.7269 46.345 54.4654 46.5606 54.2819 46.8369C54.0984 47.1133 54.0011 47.4379 54.0024 47.7696C54.0052 48.2111 54.1819 48.6337 54.4941 48.9459C54.8063 49.2581 55.2289 49.4348 55.6704 49.4376ZM55.6704 45.1387C56.1891 45.137 56.6967 45.2892 57.1289 45.576C57.5612 45.8628 57.8986 46.2714 58.0986 46.75C58.2986 47.2287 58.3521 47.7559 58.2524 48.2649C58.1527 48.774 57.9043 49.2421 57.5386 49.6099C57.1728 49.9778 56.7062 50.2289 56.1977 50.3315C55.6892 50.4341 55.1617 50.3836 54.6819 50.1864C54.2021 49.9892 53.7916 49.6541 53.5023 49.2235C53.213 48.7929 53.0579 48.2862 53.0567 47.7675C53.0552 47.4231 53.1218 47.0819 53.2524 46.7633C53.3831 46.4447 53.5753 46.155 53.8181 45.9108C54.0609 45.6666 54.3495 45.4727 54.6673 45.3402C54.9852 45.2077 55.326 45.1392 55.6704 45.1387Z"
        fill="black"
      />
      <path
        d="M59.981 45.3536C59.981 45.3169 59.9954 45.2817 60.0211 45.2555C60.0469 45.2294 60.0818 45.2144 60.1185 45.2139H60.7892C60.8255 45.2155 60.8599 45.2308 60.8854 45.2567C60.9109 45.2826 60.9257 45.3172 60.9267 45.3536V49.4375H62.7838C62.8203 49.4375 62.8553 49.452 62.8811 49.4778C62.9069 49.5036 62.9214 49.5386 62.9214 49.5751V50.1726C62.9214 50.2093 62.907 50.2445 62.8813 50.2707C62.8555 50.2968 62.8205 50.3118 62.7838 50.3124H60.1185C60.0818 50.3118 60.0469 50.2968 60.0211 50.2707C59.9954 50.2445 59.981 50.2093 59.981 50.1726V45.3536Z"
        fill="black"
      />
      <path
        d="M66.4389 49.4376C66.7707 49.4389 67.0953 49.3416 67.3716 49.1581C67.648 48.9746 67.8636 48.7131 67.9911 48.4069C68.1186 48.1007 68.1523 47.7635 68.0879 47.4381C68.0235 47.1127 67.8639 46.8137 67.6294 46.5792C67.3948 46.3446 67.0959 46.185 66.7705 46.1206C66.4451 46.0562 66.1079 46.0899 65.8016 46.2174C65.4954 46.345 65.234 46.5606 65.0505 46.8369C64.867 47.1133 64.7697 47.4379 64.771 47.7696C64.7738 48.2111 64.9504 48.6337 65.2626 48.9459C65.5748 49.2581 65.9974 49.4348 66.4389 49.4376ZM66.4389 45.1387C66.9577 45.137 67.4653 45.2892 67.8975 45.576C68.3297 45.8628 68.6672 46.2714 68.8672 46.75C69.0671 47.2287 69.1207 47.7559 69.021 48.2649C68.9213 48.774 68.6729 49.2421 68.3071 49.6099C67.9414 49.9778 67.4747 50.2289 66.9662 50.3315C66.4578 50.4341 65.9303 50.3836 65.4505 50.1864C64.9707 49.9892 64.5602 49.6541 64.2709 49.2235C63.9816 48.7929 63.8265 48.2862 63.8252 47.7675C63.8238 47.4231 63.8903 47.0819 64.021 46.7633C64.1517 46.4447 64.3439 46.155 64.5867 45.9108C64.8295 45.6666 65.1181 45.4727 65.4359 45.3402C65.7537 45.2077 66.0946 45.1392 66.4389 45.1387Z"
        fill="black"
      />
      <path
        d="M73.1364 45.1387C73.7905 45.1464 74.4205 45.3864 74.914 45.8157C74.928 45.8288 74.9391 45.8446 74.9468 45.8622C74.9544 45.8798 74.9583 45.8987 74.9583 45.9178C74.9583 45.937 74.9544 45.9559 74.9468 45.9735C74.9391 45.9911 74.928 46.0069 74.914 46.0199L74.4648 46.495C74.4525 46.5081 74.4377 46.5185 74.4212 46.5256C74.4048 46.5327 74.387 46.5364 74.3691 46.5364C74.3512 46.5364 74.3334 46.5327 74.317 46.5256C74.3005 46.5185 74.2857 46.5081 74.2735 46.495C73.9731 46.2208 73.5818 46.0676 73.1751 46.0651C72.7299 46.0651 72.3029 46.242 71.9881 46.5568C71.6732 46.8716 71.4964 47.2986 71.4964 47.7438C71.4964 48.189 71.6732 48.616 71.9881 48.9308C72.3029 49.2457 72.7299 49.4225 73.1751 49.4225C73.4709 49.4184 73.763 49.3563 74.0349 49.2398V48.6208H73.4524C73.4346 48.6217 73.4169 48.6189 73.4002 48.6127C73.3836 48.6065 73.3683 48.597 73.3555 48.5847C73.3426 48.5725 73.3324 48.5577 73.3254 48.5414C73.3184 48.525 73.3148 48.5074 73.3148 48.4897V47.933C73.3142 47.9147 73.3174 47.8966 73.3241 47.8796C73.3308 47.8627 73.3409 47.8472 73.3538 47.8344C73.3667 47.8215 73.3821 47.8113 73.399 47.8046C73.416 47.7979 73.4341 47.7948 73.4524 47.7954H74.8517C74.887 47.7971 74.9204 47.8123 74.9448 47.8379C74.9692 47.8635 74.9828 47.8976 74.9828 47.933V49.7965C74.9832 49.8194 74.9782 49.8421 74.9681 49.8627C74.958 49.8832 74.9431 49.9011 74.9247 49.9147C74.3728 50.225 73.7502 50.3879 73.1171 50.3876C72.4227 50.3876 71.7568 50.1118 71.2658 49.6208C70.7749 49.1298 70.499 48.4639 70.499 47.7696C70.499 47.0753 70.7749 46.4093 71.2658 45.9184C71.7568 45.4274 72.4227 45.1516 73.1171 45.1516"
        fill="black"
      />
      <path
        d="M77.9216 47.8615L76.2623 45.4304C76.2472 45.4098 76.2383 45.3853 76.2366 45.3598C76.2349 45.3343 76.2404 45.3089 76.2526 45.2864C76.2647 45.2639 76.283 45.2453 76.3052 45.2328C76.3275 45.2202 76.3528 45.2143 76.3783 45.2155H77.122C77.1454 45.2162 77.1682 45.2226 77.1884 45.2343C77.2087 45.2459 77.2257 45.2623 77.2381 45.2821L78.4031 46.9565L79.5681 45.2821C79.5805 45.2623 79.5975 45.2459 79.6178 45.2343C79.638 45.2226 79.6608 45.2162 79.6842 45.2155H80.4343C80.4598 45.2143 80.4852 45.2202 80.5074 45.2328C80.5297 45.2453 80.5479 45.2639 80.5601 45.2864C80.5722 45.3089 80.5777 45.3343 80.576 45.3598C80.5743 45.3853 80.5654 45.4098 80.5504 45.4304L78.8674 47.855V50.1764C78.8663 50.2122 78.8518 50.2463 78.8267 50.2718C78.8016 50.2973 78.7677 50.3124 78.732 50.314H78.0613C78.0247 50.314 77.9894 50.2996 77.9633 50.2738C77.9372 50.2481 77.9222 50.2131 77.9216 50.1764V47.8615Z"
        fill="black"
      />
      <path
        d="M32.4231 8.75448C32.2961 8.75213 32.1732 8.79964 32.0807 8.88683C31.9883 8.97403 31.9337 9.09395 31.9287 9.22091C31.9287 9.69594 32.3328 9.75612 32.5735 9.78191C35.2518 10.0807 37.1003 12.2946 37.2271 14.4699C37.2486 14.8353 37.4571 15.1276 37.8032 15.1276C38.1492 15.1276 38.2911 14.6569 38.2739 14.285C38.1449 11.5316 35.3721 8.79962 32.4231 8.75448Z"
        fill="black"
      />
      <path
        d="M35.9978 14.6163C35.9634 12.6818 34.1772 11.0289 32.4577 11.0225C32.2427 11.0225 31.9332 11.2181 31.9332 11.4717C31.9228 11.6138 31.965 11.7547 32.0518 11.8677C32.1386 11.9807 32.2639 12.0578 32.4039 12.0843C33.9666 12.2992 34.7468 13.116 34.9983 14.6636C35.0119 14.7942 35.0749 14.9145 35.1744 15.0001C35.274 15.0856 35.4024 15.1298 35.5335 15.1236C35.7979 15.1236 35.9634 14.9603 36.0043 14.6142"
        fill="black"
      />
      <path
        d="M33.932 14.6314C33.932 13.6254 33.227 13.0902 32.4037 13.1268C32.2901 13.1315 32.1811 13.1728 32.0928 13.2444C32.0046 13.3161 31.9417 13.4142 31.9136 13.5244C31.892 13.6306 31.9103 13.7411 31.965 13.8347C32.0198 13.9283 32.1071 13.9984 32.2103 14.0317C32.7283 14.1585 32.8938 14.2251 33.0292 14.7625C33.0667 14.8635 33.1341 14.9508 33.2224 15.0126C33.3108 15.0744 33.4158 15.1079 33.5236 15.1085C33.6335 15.085 33.7328 15.0264 33.8065 14.9415C33.8801 14.8565 33.9242 14.75 33.932 14.6378"
        fill="black"
      />
    </svg>
  );
};

export default Logo;
