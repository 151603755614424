import { useCallback, useEffect, useState } from "react";
import { ILotBreadcrumbs, ILotDetails } from "API/Interfaces";
import { useParams } from "react-router-dom";
import LotServiceApi from "API/Services/LotServiceApi";
import { useLoaderContext } from "Contexts";

const useGetLot = () => {
  const { id }: { id: string } = useParams();
  const { setLoading } = useLoaderContext();
  const [lot, setLot] = useState<ILotDetails | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<ILotBreadcrumbs | null>(null);

  const getLot = useCallback(() => {
    setLoading(true);
    Promise.allSettled([
      LotServiceApi.getLot(id),
      LotServiceApi.getBreadcrumbs(id),
    ])
      .then(([lot, breadcrumbs]) => {
        if (lot.status === "fulfilled") setLot(lot.value);
        if (breadcrumbs.status === "fulfilled")
          setBreadcrumbs(breadcrumbs.value);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  useEffect(() => {
    id !== "new" ? getLot() : setLot(null);
  }, [getLot, id]);

  return { lot, breadcrumbs, getLot } as const;
};

export default useGetLot;
