import {
  ImportCsvServiceApi,
  IImportLots,
} from "API/Services/ImportCsvServiceApi";
import { useParams } from "react-router-dom";
import { useLoaderContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";

const useEditImportLot = () => {
  const { isLoading, setLoading } = useLoaderContext();
  const { setError } = useErrorContext();
  const { editImportLot } = ImportCsvServiceApi;
  const { id } = useParams<{ id: string }>();

  const handler = async (lotId: string, data: IImportLots) => {
    setLoading(true);
    return await editImportLot(id, lotId, data)
      .then((res) => res.data.errors)
      .catch(() => setError())
      .finally(() => setLoading(false));
  };

  return [handler, isLoading] as const;
};

export default useEditImportLot;
