import * as yup from "yup";
import { LotFormFields } from "Utils/lotForm";
import { YupSchemaKeys } from "API/Interfaces";
import { i18n, i18nt, Translations } from "Translation";
import { AddressConfiguration } from "API/Interfaces";
import {
  AddressYupSchemaKeys,
  generateAddressConfigurationSchema,
} from "Utils";

type PartialSchema = YupSchemaKeys<LotFormFields>;
type Schema = PartialSchema & AddressYupSchemaKeys;

export const schema: PartialSchema = {
  name: yup
    .string()
    .max(100, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER100))
    .required(i18n.t(Translations.COMMON_FORMS_NAMEISREQUIRED)),
  country: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_COUNTRYISREQUIRED)),
  lotNumber: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t(Translations.COMMON_FORMS_FIELDMUSTBEANUMBER))
    .max(50, i18n.t(Translations.COMMON_FORMS_MAXCHARACTER50)),
  capacity: yup.number().lessThan(
    10000,
    i18nt("COMMON_FORMS_MAXVALUE", {
      value: 9999,
    })
  ),
  timezone: yup
    .string()
    .required(i18n.t(Translations.COMMON_FORMS_TIMEZONEISREQUIRED)),
  hoursOfOperation: yup.array(),
  fileId: yup.string().nullable(),
};

const initSchemaObj: Schema = {
  ...schema,
  ...(generateAddressConfigurationSchema() as AddressYupSchemaKeys),
};

export const initSchema = yup.object(initSchemaObj);

export const lotSchemaGenerator = (arg: AddressConfiguration) =>
  yup.object({
    ...schema,
    ...generateAddressConfigurationSchema(arg),
  });
