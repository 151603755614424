import {
  ILocationBreadcrumbs,
  ILocationDetails,
  ILocationDto,
} from "../Interfaces/ILocation";
import { apiClient } from "../Auth/apiClient";
import urls from "./urls";
import { IPages } from "API/Interfaces";

interface IResponseDataId {
  data: { id: string };
}

const LocationServiceApi = {
  getLocations: async (
    page: number = 1,
    search: string = "",
    corporationId: string
  ): Promise<IPages<ILocationDto>> => {
    const response = await apiClient.get(
      urls.corporations.locations(corporationId),
      {
        params: {
          searchPhrase: search,
          startingPageNumber: page,
        },
      }
    );
    return response.data.data;
  },
  getLocation: async (locationId: string): Promise<ILocationDetails> => {
    const response = await apiClient.get(urls.locations.location(locationId));
    return response.data.data;
  },
  addLocation: async (
    corporationId: string,
    location: ILocationDetails
  ): Promise<IResponseDataId> => {
    const response = await apiClient.post(
      urls.locations.locationDetails("", corporationId),
      location
    );
    return response.data;
  },
  editLocation: async (
    locationId: string,
    location: ILocationDetails
  ): Promise<null> => {
    const response = await apiClient.put(
      urls.locations.location(locationId),
      location
    );
    return response.data;
  },
  deleteLocation: async (locationId: string): Promise<null> => {
    const response = await apiClient.delete(
      urls.locations.location(locationId)
    );
    return response.data;
  },
  getBreadcrumbs: async (locationId: string): Promise<ILocationBreadcrumbs> => {
    const response = await apiClient.get(
      urls.locations.breadcrumbs(locationId)
    );
    return response.data.data;
  },
};

export default LocationServiceApi;
