import AppStepper from "Pages/ImportFromCsv/Components/AppStepper";
import { useCsvImportContext } from "../Contexts/CsvImportContext";
export interface ImportStepperProps {}

const steps = ["import from CSV", "Column mapping", "import result"];

const ImportStepper: React.FC<ImportStepperProps> = () => {
  const { currentStep } = useCsvImportContext();
  return <AppStepper stepsLabels={steps} activeStep={currentStep}></AppStepper>;
};

export default ImportStepper;
