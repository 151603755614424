import { AppButton, AppButtonProps } from "Layout/Components";
import React from "react";

export const FormSubmitButton: React.FC<Partial<AppButtonProps>> = (props) => {
  return (
    <AppButton type="submit" translation="COMMON_BUTTONS_SAVE" {...props} />
  );
};
export const FormCancelButton: React.FC<Partial<AppButtonProps>> = (props) => {
  return (
    <AppButton
      type="button"
      variant="outlined"
      translation="COMMON_BUTTONS_CANCEL"
      {...props}
    />
  );
};
