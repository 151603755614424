import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { TezServiceFeeType } from "API/Interfaces";
import { Formik } from "formik";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPaper, AppTypography } from "Layout/Components";
import AppRadioButton from "Layout/Components/AppRadioButton";
import AppRadioGroup from "Layout/Components/AppRadioGroup";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import {
  FormCancelButton,
  FormSubmitButton,
  FormTextField,
} from "Layout/FormComponents";
import FormNumberInput from "Layout/FormComponents/FormNumberInput";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { billingInfoSchema } from "./Components/BillingInfo/BillingInfo.schema";
import DollarInput from "./Components/BillingInfo/DollarInput";
import { useLocationContext } from "./Contexts/LocationContext";

export interface BillingInfoProps {}

export const BillingInfo: React.FC<BillingInfoProps> = (props) => {
  const t = useTranslate();
  const {
    billingInfo: { billingInfo, getBillingInfo, saveBillingInfo },
  } = useLocationContext();
  const { goBack } = useHistory();
  const { down } = useBreakpoints();

  useEffect(() => {
    !billingInfo && getBillingInfo();
  }, [billingInfo, getBillingInfo]);

  return billingInfo ? (
    <Formik
      initialValues={billingInfo}
      onSubmit={saveBillingInfo}
      validationSchema={billingInfoSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, handleChange, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <AppPaper elevation={3} padding={down("md") ? 3 : 1} fullWidth>
            <AppTypography
              variant="h2"
              translation="COMMON_FORMS_ENFORCEMENTFEES"
              gutterBottom
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DollarInput
                  fieldName="device5InchFee"
                  text="COMMON_FORMS_5DEVICESFEE"
                >
                  <FormNumberInput
                    fieldName="device5InchQuantity"
                    placeholder="QTY"
                  />
                </DollarInput>
              </Grid>
              <Grid item xs={6} md={12}>
                <DollarInput
                  fieldName="softwareFee"
                  text="COMMON_FORMS_SOFTWAREFEE"
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <DollarInput
                  fieldName="priorityLetterFee"
                  text="COMMON_FORMS_PRIORITYLETTERFEE"
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <DollarInput
                  fieldName="plateLookupFee"
                  text="COMMON_FORMS_PLATELOOKUPFEE"
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <DollarInput
                  fieldName="certifiedLettersFee"
                  text="COMMON_FORMS_CERTIFIEDLETTERSFEE"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <FormControlLabel
                  value="end"
                  name="tezServiceFee"
                  onChange={handleChange}
                  control={
                    <Checkbox color="primary" checked={values.tezServiceFee} />
                  }
                  label={
                    <AppTypography
                      variant="h2"
                      translation="COMMON_FORMS_TEZSERVICEFEE"
                    />
                  }
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  fieldName="feeName"
                  labelTranslation="COMMON_FORMS_NAME"
                  disabled={!values.tezServiceFee}
                />
              </Grid>
              <Grid item xs={6}>
                <DollarInput
                  fieldName="feeValue"
                  text="COMMON_VALUE"
                  typographyProps={{ style: { minWidth: 0 }, paddingLeft: 2 }}
                  disabled={!values.tezServiceFee}
                />
              </Grid>
              <Grid item xs={12}>
                <AppRadioGroup
                  name="tezServiceFeeType"
                  value={values.tezServiceFeeType}
                  gutterBetween={6}
                  onChange={(e) =>
                    setFieldValue("tezServiceFeeType", Number(e.target.value))
                  }
                >
                  <AppRadioButton
                    name="tezServiceFeeType"
                    value={TezServiceFeeType.PerCitation}
                    label={t("COMMON_FORMS_PERCITATION")}
                    disabled={!values.tezServiceFee}
                  />
                  <AppRadioButton
                    name="tezServiceFeeType"
                    value={TezServiceFeeType.PerPayment}
                    label={t("COMMON_FORMS_PERPAYMENT")}
                    disabled={!values.tezServiceFee}
                  />
                </AppRadioGroup>
              </Grid>
            </Grid>
            <ButtonWrapper>
              <FormCancelButton onClick={() => goBack()} />
              <FormSubmitButton />
            </ButtonWrapper>
          </AppPaper>
        </form>
      )}
    </Formik>
  ) : null;
};

export default BillingInfo;
