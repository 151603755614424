import { FormControl, Select, MenuItem } from "@material-ui/core";
import { useCsvImportContext } from "../Contexts/CsvImportContext";
import { useTranslate } from "Hooks";
import styled from "styled-components";

export interface ColumnSelectProps {
  name?: string;
  value?: string;
  handleChange?: (arg: any) => void;
  disable?: boolean;
}

const DefaultItemText = styled.span`
  color: ${(_) => _.theme.palette.neutral.main};
`;

const ColumnSelect: React.FC<ColumnSelectProps> = (props) => {
  const { getColumnList } = useCsvImportContext();
  const t = useTranslate();

  return (
    <FormControl fullWidth size="small">
      <Select
        id="columnSelect"
        labelId={props.name}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        variant="outlined"
        disabled={props.disable || false}
        fullWidth
      >
        <MenuItem value="">
          <DefaultItemText>{t("COMMON_FORMS_SELECT")}</DefaultItemText>
        </MenuItem>
        {getColumnList().map((column, index) => (
          <MenuItem key={`col-${index}`} value={column}>
            {column}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ColumnSelect;
