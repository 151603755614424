import { useLoaderContext, usePopupContext } from "Contexts";
import { useTranslate } from "Hooks";
import { useHistory, useLocation } from "react-router-dom";
import LotServiceApi from "API/Services/LotServiceApi";
import { isErrorCode } from "Utils";
import { useLotContext } from "../Contexts/LotContext";
import { LotFormFieldsWithAddress, lotFormToRequest } from "Utils/lotForm";

export const useSaveLot = () => {
  const { id } = useLotContext();
  const { goBack } = useHistory();
  const t = useTranslate();
  const { setPopup, showPopup } = usePopupContext();
  const { setLoading } = useLoaderContext();
  const { state } = useLocation<{ locationId: string }>();

  const addLot = (lot: LotFormFieldsWithAddress) => {
    setLoading(true);
    LotServiceApi.addLot(state?.locationId || "", lotFormToRequest(lot))
      .then(() =>
        setPopup({
          titleTranslation: "DIALOGS_CREATELOTTITLE",
          description: `${lot.name} ${t("DIALOGS_CREATESUCCESS")}`,
          variant: "info",
          buttonOkClick: () => goBack(),
        })
      )
      .catch((err) =>
        setPopup({
          titleTranslation: "DIALOGS_CREATELOTTITLE",
          description: `${lot.name} ${t(
            isErrorCode(err, "ResourceExists")
              ? "DIALOGS_ALREADYEXISTS"
              : "DIALOGS_CREATEFAIL"
          )}`,
          variant: "error",
        })
      )
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  const editLot = async (lot: LotFormFieldsWithAddress) => {
    setLoading(true);
    LotServiceApi.editLot(id, lotFormToRequest(lot))
      .then(() =>
        setPopup({
          titleTranslation: "DIALOGS_EDITLOCATIONTITLE",
          description: `${lot.name} ${t("DIALOGS_EDITSUCCESS")}`,
          variant: "info",
          buttonOkClick: () => goBack(),
        })
      )
      .catch(() =>
        setPopup({
          titleTranslation: "DIALOGS_EDITLOCATIONTITLE",
          variant: "error",
        })
      )
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  return id ? editLot : addLot;
};

export default useSaveLot;
