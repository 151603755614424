import ButtonWrapper from "Layout/Components/ButtonWrapper";
import FileUpload from "Layout/FormComponents/FileUpload/FileUpload";
import FormAddressAutocomplete from "Layout/FormComponents/FormAddressAutocomplete";
import FormSeparator from "Layout/Components/FormSeparator";
import useBreakpoints from "Hooks/useBreakpoints";
import useTranslate from "Hooks/useTranslations";
import { FC, useEffect } from "react";
import { Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  FormAdminEmailField,
  FormAdminMobilePhoneField,
  FormAdminNameField,
  FormAdminOfficePhoneField,
  FormCancelButton,
  FormCityFieldWithAddressConfig,
  FormCountriesField,
  FormCountyFieldWithAddressConfig,
  FormNameField,
  FormPostalCodeFieldWithAddressConfig,
  FormStateFieldWithAddressConfig,
  FormStreet1WithAddressConfig,
  FormStreet2WithAddressConfig,
  FormSubmitButton,
  FormTimeZonesField,
} from "Layout/FormComponents";
import { routes } from "Routing/routes";
import { getCorporationToForm } from "Utils/corporationForm";
import useSaveCorporation from "Pages/CorporationDetails/Hooks/useSaveCorporation";
import { useCorporationContext } from "Pages/CorporationDetails/Contexts/CorporationContext";

const CorporationDetailsForm: FC = () => {
  const t = useTranslate();
  const { push } = useHistory();
  const { down } = useBreakpoints();
  const saveCorporation = useSaveCorporation();
  const { corporation, corporationSchema, updateSchema, id } =
    useCorporationContext();

  useEffect(() => {
    if (corporation) {
      updateSchema(corporation.address.addressConfiguration);
    }
  }, [corporation, updateSchema]);

  return (
    <Formik
      initialValues={getCorporationToForm(corporation)}
      onSubmit={saveCorporation}
      validationSchema={corporationSchema}
      enableReinitialize
    >
      <Form>
        <Grid
          container
          item
          xs={12}
          lg={10}
          xl={8}
          spacing={2}
          style={{ display: `${down("md") ? "" : "contents"}` }}
        >
          <Grid container item>
            <FileUpload id={id} type="corporation" />
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormNameField inputRequired />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormAddressAutocomplete
                updateSchema={updateSchema}
                placeholder="COMMON_FORMS_STARTTYPINGADDRESSLOOKUP"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormCountriesField updateSchema={updateSchema} />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormStreet1WithAddressConfig />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormStreet2WithAddressConfig />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={5} sm={4} md={2}>
              <FormPostalCodeFieldWithAddressConfig />
            </Grid>
            <Grid item xs={7} sm={8} md={4}>
              <FormCountyFieldWithAddressConfig />
            </Grid>
            <Grid item xs={7} sm={8} md={4}>
              <FormCityFieldWithAddressConfig />
            </Grid>
            <Grid item xs={5} sm={4} md={2}>
              <FormStateFieldWithAddressConfig />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormTimeZonesField />
            </Grid>
          </Grid>
          <Grid container item>
            <FormSeparator>{t("COMMON_PAGES_ADMINISTRATOR")}</FormSeparator>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormAdminNameField inputRequired />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormAdminEmailField inputRequired />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <FormAdminOfficePhoneField inputRequired />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormAdminMobilePhoneField inputRequired />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <ButtonWrapper topSpacing={down("md") ? 4 : 1} marginBottom={2}>
            <FormCancelButton onClick={() => push(routes.corporationList)} />
            <FormSubmitButton />
          </ButtonWrapper>
        </Grid>
      </Form>
    </Formik>
  );
};

export default CorporationDetailsForm;
