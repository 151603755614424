import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";

export interface AppStepperProps {
  stepsLabels?: string[];
  activeStep?: number;
}

const AppStepper: React.FC<AppStepperProps> = (props) => {
  const steps: string[] = props.stepsLabels || [];

  return (
    <div>
      <Stepper activeStep={props.activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default AppStepper;
