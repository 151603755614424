import styled from "styled-components";
import { AppTypography } from "Layout/Components";
import { FC } from "react";
import useBreakpoints from "Hooks/useBreakpoints";

interface LotCounterProps {
  numberOfLots: number;
}

const CounterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(_) => _.theme.palette.neutral.light};
  width: 44px;
  height: 44px;
  padding: 10px 0;
  ${(_) => _.theme.breakpoints.up("md")} {
    background-color: unset;
    width: unset;
    height: unset;
    flex-direction: row;
    > * {
      margin-left: 5px;
    }
  }
`;

export const LotCounter: FC<LotCounterProps> = ({ numberOfLots }) => {
  const { down } = useBreakpoints();
  return (
    <CounterBox>
      <AppTypography line="10px">{numberOfLots}</AppTypography>
      <AppTypography
        variant={!down("md") ? "caption" : undefined}
        translation={
          numberOfLots === 1 ? "COMMON_PAGES_LOT" : "COMMON_PAGES_LOTS"
        }
      />
    </CounterBox>
  );
};

export default LotCounter;
