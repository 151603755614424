export const BackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#00C3FF" />
      <path
        d="M7 7V11C7 12.1046 7.89543 13 9 13H17"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9L18 13L14 17"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteIcon: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const fill = disabled ? "#C4C4C4" : "#00C3FF";
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8H20V23C20 24.6569 18.6569 26 17 26H5C3.34315 26 2 24.6569 2 23V8Z"
        fill={fill}
      />
      <rect y="3" width="22" height="3" rx="1" fill={fill} />
      <rect x="7" width="8" height="6" rx="2" fill={fill} />
      <rect x="5" y="11" width="2" height="12" rx="1" fill="white" />
      <rect x="10" y="11" width="2" height="12" rx="1" fill="white" />
      <rect x="15" y="11" width="2" height="12" rx="1" fill="white" />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12L15 15"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="7" cy="7" r="6" stroke="#00C3FF" strokeWidth="2" />
    </svg>
  );
};

export const CheckIcon = (props: any) => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 14L14 19L28 5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C16.7612 2 19.3046 2.9326 21.3324 4.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const AddIcon = (props: any) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        width="2"
        height="10"
        fill={props.fill ? props.fill : "white"}
      />
      <rect
        x="10"
        y="4"
        width="2"
        height="10"
        transform="rotate(90 10 4)"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
};

export const MessageIcon = () => {
  return (
    <svg
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.65 0C0.73873 0 0 0.738729 0 1.65V11.35C0 12.2613 0.738729 13 1.65 13H16.55C17.4613 13 18.2 12.2613 18.2 11.35V1.65C18.2 0.73873 17.4613 0 16.55 0H1.65ZM2.94381 2.17046C2.70662 1.98071 2.36051 2.01917 2.17075 2.25636C1.981 2.49355 2.01945 2.83966 2.25665 3.02942L7.38232 7.12996C8.38667 7.93344 9.81379 7.93344 10.8181 7.12996L15.9438 3.02942C16.181 2.83966 16.2195 2.49355 16.0297 2.25636C15.84 2.01917 15.4938 1.98071 15.2566 2.17046L10.131 6.271C9.52837 6.75309 8.67209 6.75309 8.06948 6.271L2.94381 2.17046Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};

export const PersonIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23932 11.7299C2.60982 10.6925 3.59248 10 4.69406 10C5.36301 10 6.02078 10.1716 6.60444 10.4985L8.04559 11.3055C9.25981 11.9855 10.7402 11.9855 11.9544 11.3055L13.3956 10.4985C13.9792 10.1716 14.637 10 15.3059 10C16.4075 10 17.3902 10.6925 17.7607 11.7299L19.0455 15.3273C19.5107 16.6298 18.5451 18 17.162 18H2.83801C1.45495 18 0.489351 16.6298 0.954526 15.3273L2.23932 11.7299Z"
        fill="#9B9B9B"
      />
      <circle cx="10" cy="4" r="4" fill="#9B9B9B" />
    </svg>
  );
};

export const SmartphoneIcon = () => {
  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.65C0 0.738728 0.73873 0 1.65 0H10.3504C11.2617 0 12.0004 0.73873 12.0004 1.65V17.0173C12.0004 17.9285 11.2617 18.6673 10.3504 18.6673H1.65C0.73873 18.6673 0 17.9285 0 17.0173V1.65ZM3.45038 16.6675C3.45038 16.3638 3.69662 16.1175 4.00038 16.1175H8.00051C8.30427 16.1175 8.55051 16.3638 8.55051 16.6675C8.55051 16.9713 8.30427 17.2175 8.00051 17.2175H4.00038C3.69662 17.2175 3.45038 16.9713 3.45038 16.6675ZM9.33387 0.000295937H2.66699C2.66699 0.7367 3.26396 1.33367 4.00037 1.33367H8.0005C8.7369 1.33367 9.33387 0.7367 9.33387 0.000295937Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};

export const ExpandetArrow = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15"
        stroke="#00C3FF"
        strokeWidth="2"
        fill={props.expanded ? "#00C3FF" : "#fff"}
        fillOpacity={props.expanded ? "0.2" : "1"}
      />
      <path
        d="M9 14L16 21L23 14"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaginationArrowRight = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L6 6L1 1"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaginationArrowLeft = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 5.9999586,11 0.99995859,6 5.9999586,1"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ComputerIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="5"
        width="24"
        height="14"
        rx="3"
        stroke="#00C3FF"
        strokeWidth="2"
      />
      <rect x="9" y="20" width="10" height="4" fill="#00C3FF" />
      <path
        d="M7 24H21"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 3H13C15.2091 3 17 4.79086 17 7V8.5M7 25H13C15.2091 25 17 23.2091 17 21V19.5"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 14L24 14"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 10L25 14L21 18"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L13 13"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 1L1 13"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const FileImportIcon = () => {
  return (
    <svg
      width="68"
      height="83"
      viewBox="0 0 68 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" filter="url(#filter0_d)">
        <rect
          x="26.5884"
          y="39.3584"
          width="35.7903"
          height="35.7903"
          rx="7.9534"
          stroke="#7B59FB"
          strokeWidth="1.7043"
          strokeLinecap="round"
          strokeDasharray="3.41 6.82"
        />
        <path
          d="M27.7241 3C37.1367 3 44.7671 10.6304 44.7671 20.043L44.7671 51.8566C44.7671 54.9941 42.2236 57.5376 39.0861 57.5376L10.6811 57.5376C7.54357 57.5376 5.0001 54.9941 5.0001 51.8566L5.0001 8.681C5.0001 5.54347 7.54357 3 10.6811 3L27.7241 3Z"
          fill="#D7CDFE"
        />
        <rect
          x="37.9521"
          y="35.9492"
          width="1.7043"
          height="26.1326"
          rx="0.85215"
          transform="rotate(90 37.9521 35.9492)"
          fill="#7B59FB"
        />
        <rect
          x="24.8862"
          y="28.5645"
          width="1.7043"
          height="13.0663"
          rx="0.85215"
          transform="rotate(90 24.8862 28.5645)"
          fill="#7B59FB"
        />
        <rect
          x="37.9521"
          y="43.3359"
          width="1.7043"
          height="26.1326"
          rx="0.85215"
          transform="rotate(90 37.9521 43.3359)"
          fill="#7B59FB"
        />
        <path
          d="M27.7261 3C37.1387 3 44.7691 10.6304 44.7691 20.043H33.4071C30.2695 20.043 27.7261 17.4995 27.7261 14.362V3Z"
          fill="#7B59FB"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.455201"
          y="0.727601"
          width="67.3205"
          height="82.09"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2.2724" />
          <feGaussianBlur stdDeviation="2.2724" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.88825 0 0 0 0 0.90125 0 0 0 0 0.95 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const FileImportErrorIcon = () => {
  return (
    <svg
      width="58"
      height="65"
      viewBox="0 0 58 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M25.331 0.249999C34.3221 0.25 41.6108 7.53872 41.6108 16.5298L41.6108 46.9188C41.6108 49.9158 39.1813 52.3454 36.1842 52.3454L9.05121 52.3454C6.05418 52.3454 3.6246 49.9158 3.62461 46.9188L3.62461 5.6766C3.62461 2.67957 6.05418 0.249998 9.05121 0.249999L25.331 0.249999Z"
          fill="#F9B0BD"
        />
        <rect
          x="35.0977"
          y="31.7246"
          width="1.62798"
          height="24.9624"
          rx="0.813991"
          transform="rotate(90 35.0977 31.7246)"
          fill="#ED1C42"
        />
        <rect
          x="22.6172"
          y="24.6699"
          width="1.62798"
          height="12.4812"
          rx="0.813991"
          transform="rotate(90 22.6172 24.6699)"
          fill="#ED1C42"
        />
        <rect
          x="35.0977"
          y="38.7793"
          width="1.62798"
          height="24.9624"
          rx="0.813991"
          transform="rotate(90 35.0977 38.7793)"
          fill="#ED1C42"
        />
        <path
          d="M25.3311 0.25C34.3221 0.25 41.6109 7.53872 41.6109 16.5298H30.7577C27.7606 16.5298 25.3311 14.1002 25.3311 11.1032V0.25Z"
          fill="#ED1C42"
        />
        <path
          d="M42.1498 38.3104C42.529 37.6482 43.471 37.6482 43.8502 38.3104L54.3004 56.5612C54.6873 57.2368 54.1969 58.0613 53.4501 58.0613H32.5499C31.8031 58.0613 31.3127 57.2368 31.6996 56.5612L42.1498 38.3104Z"
          fill="white"
          stroke="#ED1C24"
          strokeWidth="1.6275"
        />
        <path
          d="M43.8755 50.6237C43.8755 51.0732 43.5112 51.4375 43.0617 51.4375C42.6123 51.4375 42.248 51.0732 42.248 50.6238L42.248 46.5174C42.248 46.068 42.6123 45.7037 43.0617 45.7037C43.5112 45.7037 43.8755 46.068 43.8755 46.5174L43.8755 50.6237Z"
          fill="#ED1C24"
        />
        <path
          d="M42.249 53.6257C42.249 53.1763 42.6133 52.812 43.0627 52.812C43.5121 52.812 43.8765 53.1763 43.8765 53.6257C43.8765 54.0751 43.5121 54.4395 43.0627 54.4395C42.6133 54.4395 42.249 54.0751 42.249 53.6257Z"
          fill="#ED1C24"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.912012"
          y="0.25"
          width="57.0505"
          height="64.05"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.7125" />
          <feGaussianBlur stdDeviation="1.35625" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const CheckmarkFile = () => {
  return (
    <svg
      width="70"
      height="71"
      viewBox="0 0 70 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" filter="url(#filter0_d)">
        <path
          d="M31.3313 5.25C40.3223 5.25 47.6111 12.5387 47.6111 21.5298L47.6111 51.9188C47.6111 54.9158 45.1815 57.3454 42.1845 57.3454L15.0515 57.3454C12.0544 57.3454 9.62485 54.9158 9.62485 51.9188L9.62485 10.6766C9.62485 7.67957 12.0544 5.25 15.0515 5.25L31.3313 5.25Z"
          fill="#D7CDFE"
        />
        <rect
          x="41.0984"
          y="36.7246"
          width="1.62798"
          height="24.9624"
          rx="0.813991"
          transform="rotate(90 41.0984 36.7246)"
          fill="#7B59FB"
        />
        <rect
          x="28.6187"
          y="29.6699"
          width="1.62798"
          height="12.4812"
          rx="0.813991"
          transform="rotate(90 28.6187 29.6699)"
          fill="#7B59FB"
        />
        <rect
          x="41.0984"
          y="43.7793"
          width="1.62798"
          height="24.9624"
          rx="0.813991"
          transform="rotate(90 41.0984 43.7793)"
          fill="#7B59FB"
        />
        <path
          d="M31.3325 5.25C40.3236 5.25 47.6123 12.5387 47.6123 21.5298H36.7591C33.7621 21.5298 31.3325 19.1002 31.3325 16.1032V5.25Z"
          fill="#7B59FB"
        />
        <circle
          cx="49.2386"
          cy="55.178"
          r="8.9539"
          fill="white"
          stroke="#7B59FB"
          strokeWidth="1.62798"
        />
        <path
          d="M44.355 53.5497L48.04 57.3618C48.8933 58.2444 50.3081 58.2444 51.1614 57.3618L60.0921 48.123"
          stroke="#7B59FB"
          strokeWidth="1.62798"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="6.91145"
          y="5.25"
          width="56.708"
          height="65.1225"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2.7133" />
          <feGaussianBlur stdDeviation="1.35665" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const ErrorMark = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#FF3D3D" />
      <path
        d="M12 5C12 7.02015 10.9296 10.366 10.355 12.0168C10.2342 12.3636 9.76576 12.3636 9.64502 12.0168C9.07036 10.366 8 7.02015 8 5C8 4 8.89543 3 10 3C11.1046 3 12 4 12 5Z"
        fill="white"
      />
      <path
        d="M11.5 15.4141C11.5 16.2425 10.8284 16.9141 10 16.9141C9.17157 16.9141 8.5 16.2425 8.5 15.4141C8.5 14.5856 9.17157 13.9141 10 13.9141C10.8284 13.9141 11.5 14.5856 11.5 15.4141Z"
        fill="white"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00021 1.14258H4.00021C2.42225 1.14258 1.14307 2.42176 1.14307 3.99972V13.9997C1.14307 15.5777 2.42225 16.8569 4.00021 16.8569H14.0002C15.5782 16.8569 16.8574 15.5777 16.8574 13.9997V8.99972"
        stroke="#00C3FF"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80127 13.4731H7.83173L14.4334 6.87143L11.403 3.84097L4.80127 10.4427V13.4731ZM12.4131 2.83082L15.4436 5.86128L16.8364 4.46841C17.3943 3.91052 17.3943 3.00599 16.8364 2.4481L15.8263 1.43795C15.2684 0.880059 14.3639 0.880058 13.806 1.43795L12.4131 2.83082Z"
        fill="#00C3FF"
      />
    </svg>
  );
};
export const SmallDeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.57178 6.42969H16.4289V17.144C16.4289 18.3274 15.4695 19.2868 14.2861 19.2868H5.71463C4.53117 19.2868 3.57178 18.3274 3.57178 17.144V6.42969Z"
        fill="currentColor"
      />
      <rect
        x="2.14307"
        y="2.85742"
        width="15.7143"
        height="2.14286"
        rx="0.714286"
        fill="currentColor"
      />
      <rect
        x="7.14307"
        y="0.714844"
        width="5.71429"
        height="4.28571"
        rx="1.42857"
        fill="currentColor"
      />
      <rect
        x="5.71436"
        y="8.57227"
        width="1.42857"
        height="8.57143"
        rx="0.714286"
        fill="white"
      />
      <rect
        x="9.28613"
        y="8.57227"
        width="1.42857"
        height="8.57143"
        rx="0.714286"
        fill="white"
      />
      <rect
        x="12.8574"
        y="8.57227"
        width="1.42857"
        height="8.57143"
        rx="0.714286"
        fill="white"
      />
    </svg>
  );
};

export const SuccessFileIcon = () => {
  return (
    <svg
      width="180"
      height="181"
      viewBox="0 0 180 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" filter="url(#filter0_d)">
        <path
          d="M82.5663 13.5C105.686 13.5 124.429 32.2424 124.429 55.3624L124.429 133.506C124.429 141.212 118.181 147.46 110.475 147.46L40.7039 147.46C32.9973 147.46 26.7498 141.212 26.7498 133.506L26.7498 27.4541C26.7498 19.7475 32.9973 13.5 40.704 13.5L82.5663 13.5Z"
          fill="#D7CDFE"
        />
        <rect
          x="107.683"
          y="94.4355"
          width="4.18624"
          height="64.189"
          rx="2.09312"
          transform="rotate(90 107.683 94.4355)"
          fill="#7B59FB"
        />
        <rect
          x="75.5923"
          y="76.2949"
          width="4.18624"
          height="32.0945"
          rx="2.09312"
          transform="rotate(90 75.5923 76.2949)"
          fill="#7B59FB"
        />
        <rect
          x="107.683"
          y="112.576"
          width="4.18624"
          height="64.189"
          rx="2.09312"
          transform="rotate(90 107.683 112.576)"
          fill="#7B59FB"
        />
        <path
          d="M82.5703 13.5C105.69 13.5 124.433 32.2424 124.433 55.3624H96.5244C88.8178 55.3624 82.5703 49.1149 82.5703 41.4083V13.5Z"
          fill="#7B59FB"
        />
        <circle
          cx="128.615"
          cy="141.887"
          r="23.0243"
          fill="white"
          stroke="#7B59FB"
          strokeWidth="4.18624"
        />
        <path
          d="M116.057 137.698L125.532 147.501C127.727 149.771 131.365 149.771 133.559 147.501L156.524 123.744"
          stroke="#7B59FB"
          strokeWidth="4.18624"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="19.7729"
          y="13.5"
          width="145.821"
          height="167.459"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97706" />
          <feGaussianBlur stdDeviation="3.48853" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const BigLocationIcon = () => {
  return (
    <svg
      width="115"
      height="115"
      viewBox="0 0 115 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" filter="url(#filter0_d_4669:2844)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.1378 15.9116C61.3216 0.0294533 35.6784 0.0294533 19.8622 15.9116C4.04595 31.7938 4.04595 57.544 19.8622 73.4262L39.6884 93.3351C44.5546 98.2216 52.4454 98.2216 57.3116 93.3351L77.1378 73.4262C92.9541 57.544 92.9541 31.7938 77.1378 15.9116ZM37.4854 55.7294C43.5682 61.8376 53.4318 61.8376 59.5146 55.7294C65.5973 49.6213 65.5973 39.7166 59.5146 33.6084C53.4318 27.5003 43.5682 27.5003 37.4854 33.6084C31.4027 39.7166 31.4027 49.6213 37.4854 55.7294Z"
          fill="#D7CDFE"
        />
        <circle
          cx="80.75"
          cy="77"
          r="18.5"
          fill="white"
          stroke="#7B59FB"
          strokeWidth="3"
        />
        <path
          d="M92.75 77C92.75 70.3726 87.3774 65 80.75 65"
          stroke="#D7CDFE"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M94.75 91L104.75 101"
          stroke="#7B59FB"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4669:2844"
          x="0"
          y="0"
          width="114.25"
          height="114.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4669:2844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4669:2844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const ValetProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="9" fill="#60DFDF"></circle>
      <path
        fill="#fff"
        d="M11.663 5.1l-2.592 6-2.556-6h-2.1l3.624 8.4h1.92l3.636-8.4h-1.932z"
      ></path>
    </svg>
  );
};

export const T2PProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="9" fill="#CC78FF"></circle>
      <path
        fill="#fff"
        d="M8.027 13.5h1.944V6.684h2.688V5.1h-7.32v1.584h2.688V13.5z"
      ></path>
    </svg>
  );
};

export const NewEnforcementProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="9" fill="#FFC32A"></circle>
      <path
        fill="#fff"
        d="M8.278 11.94V9.996h3.9V8.484h-3.9V6.66h4.416V5.1H6.346v8.4h6.504v-1.56H8.278z"
      ></path>
    </svg>
  );
};
