import { useCallback, useEffect, useState } from "react";
import { ILocationBreadcrumbs, ILocationDetails } from "API/Interfaces";
import { useParams } from "react-router-dom";
import LocationServiceApi from "API/Services/LocationServiceApi";
import { useLoaderContext } from "Contexts";

const useGetLocation = () => {
  const { id }: { id: string } = useParams();
  const { setLoading } = useLoaderContext();
  const [location, setLocation] = useState<ILocationDetails | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<ILocationBreadcrumbs | null>(
    null
  );

  const getLocation = useCallback(() => {
    setLoading(true);
    Promise.allSettled([
      LocationServiceApi.getLocation(id),
      LocationServiceApi.getBreadcrumbs(id),
    ])
      .then(([location, breadcrumbs]) => {
        if (location.status === "fulfilled") setLocation(location.value);
        if (breadcrumbs.status === "fulfilled")
          setBreadcrumbs(breadcrumbs.value);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  useEffect(() => {
    id !== "new" ? getLocation() : setLocation(null);
  }, [getLocation, id, setLoading]);

  return { location, breadcrumbs, getLocation } as const;
};

export default useGetLocation;
