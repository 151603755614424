import { AuthServiceApi, IAuthenticateRequest } from "API/Services";
import { useAuthContext } from "Pages/Auth/Contexts/AuthContext";
import { useTranslate } from "Hooks";
import React, { useCallback } from "react";
import { convertUserInfo, tokensStorageService } from "../utils";
import { isErrorCode } from "Utils";
import { setAuthHeader } from "API/Auth/apiClient";

export interface useLoginProps {}

export const useLogin = () => {
  const t = useTranslate();
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUser, setStatus } = useAuthContext();

  const login = useCallback(
    async (params: IAuthenticateRequest) => {
      setIsLoading(true);
      setErrorMsg("");
      try {
        const resp = await AuthServiceApi.authenticate(params);
        const user = convertUserInfo(resp.tokens);
        setAuthHeader(user.auth);
        tokensStorageService.set({ ...user, roles: resp.roles });
        setUser({ ...user, roles: resp.roles });
        setStatus.success();
      } catch (err) {
        setErrorMsg(
          isErrorCode(err, "UnauthorizedAccess")
            ? t("COMMON_AUTH_YOUHAVEENTEREDANINVALIDUSERNAMEORPASSWORD")
            : t("DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN")
        );
        setStatus.error();
      }
    },
    [setStatus, setUser, t]
  );

  return [login, isLoading, errorMsg] as const;
};

export default useLogin;
