import AppTooltip from "Layout/Components/AppTooltip";
import { TooltipProps } from "@material-ui/core/Tooltip";
import { ErrorMark } from "assets/icons/Icons";
import React, { useState, useEffect } from "react";
import { ImportErrors } from "API/Services/ImportCsvServiceApi";
import { ImportErrorEnum } from "../Utils";

export interface ErrorMarkInformationProps {
  fieldName: string;
  errors?: ImportErrors[];
  placement?: TooltipProps["placement"];
}

const ErrorMarkInformation: React.FC<ErrorMarkInformationProps> = ({
  errors,
  fieldName,
  placement,
}) => {
  const [errorList, seterrorList] = useState<string[]>([]);

  useEffect(() => {
    seterrorList(
      errors
        ?.filter((el) => el.fieldName.toUpperCase() === fieldName.toUpperCase())
        .map((el) => ImportErrorEnum[el.error]) || []
    );
    // eslint-disable-next-line
  }, [errors]);

  return (
    <>
      {!!errorList.length && (
        <AppTooltip
          title={errorList.map((e, i) => (
            <span key={`err-${i}`}>{e}</span>
          ))}
          arrow
          placement={placement || "right"}
        >
          <ErrorMark />
        </AppTooltip>
      )}
    </>
  );
};

export default ErrorMarkInformation;
