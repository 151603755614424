import { Country, IFetchStatusObj } from "API/Interfaces";
import AddressServiceApi from "API/Services/AddressServiceApi";
import { useFetchStatus } from "Hooks";
import React, { useCallback, useContext, useEffect } from "react";

export type AddressContextType = {
  countries: Country[];
  getCountries: () => void;
  getCountriesStatus: IFetchStatusObj;
};
export const AddressContext = React.createContext<AddressContextType>({
  countries: [],
  getCountries: async () => undefined,
  getCountriesStatus: {
    init: true,
    loading: false,
    success: false,
    error: false,
  },
});

export const useAddressContext = () => useContext(AddressContext);

export const AddressProvider: React.FC = (props) => {
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [setFetchStatus, getCountriesStatus] = useFetchStatus();

  const getCountries = useCallback(() => {
    setFetchStatus.loading();
    AddressServiceApi.getCountries()
      .then((countries) => {
        setCountries(countries);
        setFetchStatus.success();
      })
      .catch(() => setFetchStatus.error());
  }, [setFetchStatus]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <AddressContext.Provider
      value={{
        countries,
        getCountries,
        getCountriesStatus,
      }}
    >
      {props.children}
    </AddressContext.Provider>
  );
};

export default AddressContext;
