import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { TranslationKeys } from "Translation";

interface Props {
  fieldName: string;
  labelTranslation?: TranslationKeys;
  inputRequired?: boolean;
}
export type FormSelectFieldProps = Props & SelectProps;

export const FormSelectField: React.FC<FormSelectFieldProps> = ({
  fieldName,
  labelTranslation,
  inputRequired,
  ...props
}) => {
  const t = useTranslate();
  const { values, errors, handleChange } = useFormikContext<any>();

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={!!errors[fieldName]}
    >
      <InputLabel required={!!inputRequired} id={fieldName}>
        {t(labelTranslation)}
      </InputLabel>
      <Select
        labelId={fieldName}
        id={fieldName}
        name={fieldName}
        label={t(labelTranslation)}
        value={values[fieldName]}
        onChange={handleChange}
        {...props}
      >
        {props.children}
      </Select>
      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelectField;
