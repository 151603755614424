import { SvgIconTypeMap, TextField, TextFieldProps } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useTranslate } from "Hooks";
import React from "react";
import styled from "styled-components";
import Translations from "Translation/translations";

const Wrapper = styled.div`
  position: relative;
  padding-left: 26px;
`;
const IconWrapper = styled.div`
  position: absolute;
  left: -16px;
  height: 40px;
  display: flex;
  align-items: center;
  svg {
    font-size: 2rem;
  }
`;
interface Props {
  IconComponent: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  translatedPlaceholder?: keyof typeof Translations;
}
export type InputOutsideIconProps = Props & TextFieldProps;
export const InputOutsideIcon: React.FC<InputOutsideIconProps> = ({
  IconComponent,
  translatedPlaceholder,
  ...props
}) => {
  const t = useTranslate();
  return (
    <Wrapper>
      <IconWrapper>
        <IconComponent />
      </IconWrapper>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        placeholder={t(translatedPlaceholder)}
        {...props}
      />
    </Wrapper>
  );
};

export default InputOutsideIcon;
