import { useLoaderContext, usePopupContext } from "Contexts";
import { useTranslate } from "Hooks";
import { useHistory, useLocation } from "react-router-dom";
import LocationServiceApi from "API/Services/LocationServiceApi";
import { isErrorCode } from "Utils";
import { useLocationContext } from "../Contexts/LocationContext";
import {
  LocationFormFieldsWithAddress,
  locationFormToRequest,
} from "Utils/locationForm";

export const useSaveLocation = () => {
  const { id } = useLocationContext();
  const { goBack } = useHistory();
  const t = useTranslate();
  const { setPopup, showPopup } = usePopupContext();
  const { setLoading } = useLoaderContext();
  const { state } = useLocation<{ corporationId: string }>();

  const addLocation = (location: LocationFormFieldsWithAddress) => {
    setLoading(true);
    LocationServiceApi.addLocation(
      state?.corporationId || "",
      locationFormToRequest(location)
    )
      .then(() =>
        setPopup({
          titleTranslation: "DIALOGS_CREATELOCATIONTITLE",
          description: `${location.name} ${t("DIALOGS_CREATESUCCESS")}`,
          variant: "info",
          buttonOkClick: () => goBack(),
        })
      )
      .catch((err) =>
        setPopup({
          titleTranslation: "DIALOGS_CREATELOCATIONTITLE",
          description: `${location.name} ${t(
            isErrorCode(err, "ResourceExists")
              ? "DIALOGS_ALREADYEXISTS"
              : "DIALOGS_CREATEFAIL"
          )}`,
          variant: "error",
        })
      )
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  const editLocation = async (location: LocationFormFieldsWithAddress) => {
    setLoading(true);
    LocationServiceApi.editLocation(id, locationFormToRequest(location))
      .then(() =>
        setPopup({
          titleTranslation: "DIALOGS_EDITLOCATIONTITLE",
          description: `${location.name} ${t("DIALOGS_EDITSUCCESS")}`,
          variant: "info",
          buttonOkClick: () => goBack(),
        })
      )
      .catch(() =>
        setPopup({
          titleTranslation: "DIALOGS_EDITLOCATIONTITLE",
          variant: "error",
        })
      )
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  return id ? editLocation : addLocation;
};

export default useSaveLocation;
