import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { AppTypography } from "Layout/Components/AppTyphography";
import ColumnSelect from "./Components/ColumnSelect";
import AppButton from "Layout/Components/AppButton";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { useCsvImportContext } from "./Contexts/CsvImportContext";
import ColumnLabel from "./Components/ColumnLabel";
import CoordinatesCheckbox from "./Components/CoordinatesCheckbox";
import { useTranslate } from "Hooks";
import { CsvImportErrors, mapCsvToForm } from "./Utils";
import { useHistory } from "react-router-dom";
import { useAddressContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";

export interface MappingPageProps {}

export interface IColumnData {
  name: string;
  companyParking: string;
  number: string;
  capacity: string;
  latitude: string;
  longitude: string;
  countryName: string;
  street1: string;
  street2: string;
  zipCode: string;
  county: string;
  city: string;
  state: string;
}

const MappingPage: React.FC<MappingPageProps> = () => {
  const { setError } = useErrorContext();
  const { importedData, handleApplyMapping, getColumnList } =
    useCsvImportContext();
  const [isBasedOnCoordinates, setIsBasedOnCoordinates] = useState(false);
  const [requiredIsValid, setRequiredIsValid] = useState(false);
  const [mapingColumn, setMapingColumn] = useState<IColumnData>({
    name: getColumnList().find((el) => el === "Name") || "",
    companyParking:
      getColumnList().find((el) => el === "Parking Company") || "",
    number: getColumnList().find((el) => el === "Lot Number") || "",
    capacity: getColumnList().find((el) => el === "Capacity") || "",
    latitude: getColumnList().find((el) => el === "Latitude") || "",
    longitude: getColumnList().find((el) => el === "Longitude") || "",
    street1: getColumnList().find((el) => el === "Street 1") || "",
    street2: getColumnList().find((el) => el === "Street 2") || "",
    zipCode: getColumnList().find((el) => el === "Postal Code") || "",
    county: getColumnList().find((el) => el === "County") || "",
    city: getColumnList().find((el) => el === "City") || "",
    state: getColumnList().find((el) => el === "State") || "",
    countryName: getColumnList().find((el) => el === "Country") || "",
  });
  const t = useTranslate();
  const { goBack } = useHistory();
  const { countries } = useAddressContext();

  const handleMaping = (e: any) => {
    setMapingColumn({ ...mapingColumn, [e.target.name]: e.target.value });
  };

  const isRequiredValid = () => {
    if (
      isBasedOnCoordinates &&
      mapingColumn.name &&
      mapingColumn.latitude &&
      mapingColumn.longitude
    )
      setRequiredIsValid(true);
    else if (
      !isBasedOnCoordinates &&
      mapingColumn.name &&
      mapingColumn.countryName &&
      mapingColumn.street1 &&
      mapingColumn.zipCode &&
      mapingColumn.county &&
      mapingColumn.city &&
      mapingColumn.state
    )
      setRequiredIsValid(true);
    else setRequiredIsValid(false);
  };

  useEffect(() => {
    isRequiredValid();
    // eslint-disable-next-line
  }, [mapingColumn, isBasedOnCoordinates]);

  const handleApplyMaping = async () => {
    mapCsvToForm(importedData, mapingColumn, isBasedOnCoordinates, countries)
      .then(handleApplyMapping)
      .catch((e) =>
        setError(
          e?.code === CsvImportErrors.InvalidValue ? e.toString() : undefined
        )
      );
  };

  return (
    <>
      <Grid container item xs={8} spacing={2} alignItems="flex-start">
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={4}>
            <AppTypography variant="subtitle1">
              {t("COMMON_PAGES_LOTDATA")}
            </AppTypography>
          </Grid>
          <Grid item xs={8}>
            <AppTypography variant="subtitle1">
              {t("COMMON_PAGES_IMPORTCOLUMNS")}
            </AppTypography>
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel translateLabel="COMMON_FORMS_NAME" required />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="name"
              handleChange={handleMaping}
              value={mapingColumn.name || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel translateLabel="COMMON_FORMS_PARKINGCOMPANY" />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="companyParking"
              handleChange={handleMaping}
              value={mapingColumn.companyParking || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel translateLabel="COMMON_FORMS_LOTNUMBER" />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="number"
              handleChange={handleMaping}
              value={mapingColumn.number || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel translateLabel="COMMON_FORMS_CAPACITY" />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="capacity"
              handleChange={handleMaping}
              value={mapingColumn.capacity || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_LATITUDE"
              required={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="latitude"
              handleChange={handleMaping}
              value={mapingColumn.latitude || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_LONGITUDE"
              required={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="longitude"
              handleChange={handleMaping}
              value={mapingColumn.longitude || ""}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <CoordinatesCheckbox
              fieldName="isBasedOnCoordinates"
              checked={isBasedOnCoordinates}
              onChange={(e) => setIsBasedOnCoordinates(e.target.checked)}
              labelTranslation="COMMON_FORMS_BASEONGEOCOORDINATES"
            />
          </Grid>
        </Grid>

        {/*
         * ADDRESS DATA
         */}

        <Grid container item xs={6} spacing={1}>
          <Grid item xs={4}>
            <AppTypography variant="subtitle1">
              {t("COMMON_PAGES_LOTDATA")}
            </AppTypography>
          </Grid>
          <Grid item xs={8}>
            <AppTypography variant="subtitle1">
              {t("COMMON_PAGES_IMPORTCOLUMNS")}
            </AppTypography>
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_COUNTRY"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="countryName"
              handleChange={handleMaping}
              value={mapingColumn.countryName || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_STREET1"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="street1"
              handleChange={handleMaping}
              value={mapingColumn.street1 || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel translateLabel="COMMON_FORMS_STREET2" />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="street2"
              handleChange={handleMaping}
              value={mapingColumn.street2 || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_POSTALCODE"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="zipCode"
              handleChange={handleMaping}
              value={mapingColumn.zipCode || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_COUNTY"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="county"
              handleChange={handleMaping}
              value={mapingColumn.county || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_CITY"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="city"
              handleChange={handleMaping}
              value={mapingColumn.city || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={4}>
            <ColumnLabel
              translateLabel="COMMON_FORMS_STATE"
              required={!isBasedOnCoordinates}
            />
          </Grid>
          <Grid item xs={8}>
            <ColumnSelect
              name="state"
              handleChange={handleMaping}
              value={mapingColumn.state || ""}
              disable={isBasedOnCoordinates}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <ButtonWrapper topSpacing={2}>
          <AppButton
            translation="DIALOGS_CANCEL"
            variant="outlined"
            onClick={goBack}
          />
          <AppButton
            translation="COMMON_BUTTONS_APPLY"
            onClick={handleApplyMaping}
            disabled={!requiredIsValid}
          />
        </ButtonWrapper>
      </Grid>
    </>
  );
};

export default MappingPage;
