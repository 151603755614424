export interface IErrorResponse {
  errors: IError[];
}

export interface IError {
  errorCode: number;
  message: string;
}

export enum ErrorCodes {
  UnauthorizedAccess = 4001,
  ExternalSystemRuleBroken = 4003,
  ResourceNotFound = 4004,
  InvalidRequest = 4005,
  ResourceExists = 4006,
  InvalidOperation = 4007,
  CannotModify = 4010,
  NotEnoughParameters = 4019,
  IncorrectParameterGeneric = 4020,
  OtherError = 5000,
}
