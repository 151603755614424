import { GetBillingInfoResp } from "API/Interfaces/IBillingInfo";
import { BillingFields } from "./BillingInfo.schema";

export const billingInfoRespToForm = (
  billingResp: GetBillingInfoResp
): BillingFields => {
  const { tezServiceFee, device5InchQuantity, ...fees } = billingResp;
  const feesForm = {
    device5InchFee: (fees.device5InchFee / 100).toFixed(2),
    softwareFee: (fees.softwareFee / 100).toFixed(2),
    priorityLetterFee: (fees.priorityLetterFee / 100).toFixed(2),
    plateLookupFee: (fees.plateLookupFee / 100).toFixed(2),
    certifiedLettersFee: (fees.certifiedLettersFee / 100).toFixed(2),
  };

  return {
    ...feesForm,
    device5InchQuantity,
    feeName: tezServiceFee.feeName || null,
    feeValue: ((tezServiceFee.feeValue || 0) / 100).toFixed(2),
    tezServiceFee: tezServiceFee.isEnabled,
    tezServiceFeeType: tezServiceFee.tezServiceFeeType,
  };
};

export const billingInfoFormToResp = (a: BillingFields): GetBillingInfoResp => {
  return {
    device5InchFee: Math.round(Number(a.device5InchFee) * 100),
    device5InchQuantity: Number(a.device5InchQuantity),
    certifiedLettersFee: Math.round(Number(a.certifiedLettersFee) * 100),
    plateLookupFee: Math.round(Number(a.plateLookupFee) * 100),
    priorityLetterFee: Math.round(Number(a.priorityLetterFee) * 100),
    softwareFee: Math.round(Number(a.softwareFee) * 100),
    tezServiceFee: {
      isEnabled: a.tezServiceFee,
      feeName: a.feeName,
      feeValue: Math.round(Number(a.feeValue) * 100),
      tezServiceFeeType: a.tezServiceFeeType,
    },
  };
};
