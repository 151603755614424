import { apiClient } from "API/Auth/apiClient";
import { IPages } from "API/Interfaces";
import { ILotBreadcrumbs, ILotDetails, ILotDto } from "../Interfaces/ILot";
import urls from "./urls";

interface IResponseDataId {
  data: { id: string };
}

const LotServiceApi = {
  getLots: async (
    page: number = 1,
    search: string = "",
    locationId: string
  ): Promise<IPages<ILotDto>> => {
    const response = await apiClient.get(urls.locations.lots(locationId), {
      params: {
        searchPhrase: search,
        startingPageNumber: page,
      },
    });
    return response.data.data;
  },
  getLot: async (id: string): Promise<ILotDetails> => {
    const response = await apiClient.get(urls.lots.lot(id));
    return response.data.data;
  },
  addLot: async (
    locationId: string,
    lot: ILotDetails
  ): Promise<IResponseDataId> => {
    const response = await apiClient.post(
      urls.lots.locationLot(locationId),
      lot
    );
    return response.data;
  },
  editLot: async (id: string, lot: ILotDetails): Promise<null> => {
    const response = await apiClient.put(urls.lots.lot(id), lot);
    return response.data;
  },
  deleteLot: async (id: string): Promise<null> => {
    const response = await apiClient.delete(urls.lots.lot(id));
    return response.data;
  },
  getBreadcrumbs: async (id: string): Promise<ILotBreadcrumbs> => {
    const response = await apiClient.get(urls.lots.breadcrumbs(id));
    return response.data.data;
  },
};

export default LotServiceApi;
