import React from "react";
import { getFieldName } from "Utils";
import { FormComponentsFields } from "./types";
import { FormTextField, FormTextFieldProps } from "./FormTextField";

export const FormAdminOfficePhoneField: React.FC<Partial<FormTextFieldProps>> =
  (props) => {
    const fieldName = getFieldName<FormComponentsFields>("adminOfficePhone");
    return (
      <FormTextField
        labelTranslation="COMMON_FORMS_OFFICEPHONE"
        fieldName={fieldName}
        {...props}
      />
    );
  };

export default FormAdminOfficePhoneField;
