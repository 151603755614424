import React from "react";
import styled, { css } from "styled-components";

export interface LinesForLineListProps {
  isLastElement: boolean;
  isFirstElement: boolean;
  dashed?: boolean;
  showLines?: boolean;
}

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 22px;
  max-width: 22px;
`;

const LineElementTop = styled.div<LinesForLineListProps>(
  ({ theme: { palette }, isFirstElement, dashed }) => css`
    height: 100%;
    max-height: 35px;
    border-bottom: 1px ${dashed ? "dashed" : "solid"} ${palette.neutral.main};
    border-left: ${isFirstElement
      ? "none"
      : `1px ${dashed ? "dashed" : "solid"} ${palette.neutral.main}`};
  `
);

const LineElementBottom = styled.div<LinesForLineListProps>(
  ({ theme: { palette }, isLastElement, dashed }) => css`
    height: 100%;
    border-left: ${isLastElement
      ? "none"
      : `1px ${dashed ? "dashed" : "solid"} ${palette.neutral.main}`};
  `
);

const LinesForLineList: React.FC<LinesForLineListProps> = ({
  showLines = true,
  ...props
}) => {
  return (
    <Lines>
      {showLines && (
        <>
          <LineElementTop {...props} />
          <LineElementBottom {...props} />
        </>
      )}
    </Lines>
  );
};

export default LinesForLineList;
