import ApplicationNav from "Layout/ApplicationNav";
import { FC } from "react";
import styled from "styled-components";
import { MobileProfileBox } from ".";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "assets/images/Logo";
import { fadeIn, fadeOut } from "Utils";
import { useExpandedPanelContext } from "Contexts/ExpandedPanelContext";

const Container = styled.div<{ $show: boolean }>`
  background-color: ${(_) => _.theme.palette.secondary.main};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  visibility: ${(_) => (_.$show ? "visible" : "hidden")};
  animation: ${(_) => (_.$show ? fadeIn : fadeOut)} 0.218s linear;
  transition: visibility 0.218s linear;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
`;

const IconContainer = styled.div`
  color: #fff;
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  height: 40px;
  > * {
    height: 100%;
  }
`;

export const MobileMenu: FC = () => {
  const { setShowMobileMenu, isShowMobileMenu } = useExpandedPanelContext();

  return (
    <Container $show={isShowMobileMenu}>
      <TopWrapper>
        <IconContainer onClick={() => setShowMobileMenu(false)}>
          <CloseIcon />
        </IconContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </TopWrapper>
      <MobileProfileBox />
      <ApplicationNav />
    </Container>
  );
};

export default MobileMenu;
