import Button, {
  ButtonProps,
  ButtonProps as MUIButtonProps,
} from "@material-ui/core/Button";
import styled, { css } from "styled-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Translations from "Translation/translations";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import useBreakpoints from "Hooks/useBreakpoints";

export interface AppButtonProps extends MUIButtonProps {
  letterCase?: "lowercase" | "uppercase" | "none";
  hasHoverBg?: boolean;
  fontWeight?: number | string;
  minWidth?: number;
  topGutter?: number;
  textAlign?: "left" | "center" | "right" | "inherit";
  /** text is to auto translate */
  translation?: keyof typeof Translations;
  loading?: boolean;
}

const ButtonWrapper = styled.div<AppButtonProps>`
  margin-top: ${(_) => _.topGutter}px;
  text-align: ${(_) => _.textAlign};
  position: relative;
  button {
    ${(_) =>
      _.fontWeight &&
      css`
        font-weight: ${_.fontWeight};
      `}
    ${(_) =>
      _.letterCase &&
      css`
        text-transform: ${_.letterCase};
      `}
    min-width: ${(_) => (_.minWidth ? `${_.minWidth}px` : "auto")};
    :hover {
      ${(_) =>
        !_.hasHoverBg
          ? css`
              background-color: inherit;
            `
          : {}}
    }
  }
  .MuiButton-startIcon {
    margin: 0;
    ${(_) => _.theme.breakpoints.up("md")} {
      margin: 0 5px;
    }
  }
`;
const ProgressWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`;

const spinnerSize = (btnSize: ButtonProps["size"]) => {
  switch (btnSize) {
    case "small":
      return 20;
    case "large":
      return 34;
    default:
      return 30;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: theme.palette.primary.main,
    },
  })
);

export const AppButton: React.FC<AppButtonProps> = ({
  letterCase,
  hasHoverBg,
  fontWeight,
  minWidth,
  topGutter,
  textAlign,
  translation,
  loading,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { down } = useBreakpoints();
  return (
    <ButtonWrapper
      letterCase={letterCase}
      hasHoverBg={hasHoverBg}
      fontWeight={fontWeight}
      minWidth={minWidth ? minWidth : down("md") ? 160 : 130}
      topGutter={topGutter}
      textAlign={textAlign}
    >
      <Button data-testid="button" disabled={loading} {...props}>
        {`${translation ? t(Translations[translation]) : ""}${
          props.children ? props.children : ""
        }`}
      </Button>
      {loading && (
        <ProgressWrapper>
          <CircularProgress
            size={spinnerSize(props.size)}
            className={classes.buttonProgress}
          />
        </ProgressWrapper>
      )}
    </ButtonWrapper>
  );
};
AppButton.defaultProps = {
  hasHoverBg: true,
  topGutter: 0,
  textAlign: "inherit",
  type: "button",
  color: "primary",
  variant: "contained",
};
export default AppButton;
