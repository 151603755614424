import React from "react";
import { PageBreadcrumbs } from "Layout/Components";
import { generateRoute } from "Routing/routes";
import { useLocationContext } from "../Contexts/LocationContext";

const LocationBreadcrumbs: React.FC = () => {
  const { breadcrumbs } = useLocationContext();

  return breadcrumbs ? (
    <PageBreadcrumbs
      path={[
        {
          ...breadcrumbs.corporation,
          id: generateRoute.corporation(breadcrumbs.corporation.id),
        },
      ]}
      current={breadcrumbs.location}
    />
  ) : (
    <div />
  );
};

export default LocationBreadcrumbs;
