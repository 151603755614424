import React, { useState, createContext, useContext, useEffect } from "react";
import Papa from "papaparse";
import { useLoader } from "Hooks";
import {
  IImportLots,
  ImportLotsResponse,
  ImportCsvServiceApi,
} from "API/Services/ImportCsvServiceApi";
import useSendImportData from "../Hooks/useSendImportData";
import { useParams } from "react-router-dom";

export interface CsvImportContextPType {
  csvFile: File | null;
  importedData: any[];
  currentStep: number;
  mappedData: ImportLotsResponse[];
  errorsCount: number;
  onlyWithErrors: boolean;
  importFileId: string;
  togleOnlyWithErrors: () => void;
  handleLoadCsv: (arg: File | null) => void;
  handleImportCsv: () => void;
  setCurrentStep: (arg: number) => void;
  handleApplyMapping: (arg: IImportLots[]) => void;
  getColumnList: () => string[];
  changeErrorCount: () => void;
  handleSaveImportLots: () => void;
  handleCancelImportProcess: () => void;
  handleEditLot: (arg: ImportLotsResponse) => void;
  handleDeleteLot: (arg: ImportLotsResponse) => void;
}

export const CsvImportContext = createContext<CsvImportContextPType>(
  {} as CsvImportContextPType
);

export const useCsvImportContext = () => useContext(CsvImportContext);

export const CsvImportContextProvider: React.FC = (props) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [importedData, setImportedData] = useState<any[]>([]);
  const [mappedData, setMappedData] = useState<ImportLotsResponse[]>([]);
  const [onlyWithErrors, setOnlyWithErrors] = useState(false);
  const [errorsCount, setErrorsCount] = useState(0);
  const [importFileId, setImportFileId] = useState("");
  const { sendCsv } = useSendImportData();
  const [currentStep, setCurrentStep] = useState(0);
  const { id }: { id: string } = useParams();
  const { saveImportLot, cancelImportProcess } = ImportCsvServiceApi;
  const [setLoader] = useLoader();

  const handleLoadCsv = (csvFile: File | null) => {
    if (csvFile) {
      setCsvFile(csvFile);
    } else {
      setCsvFile(null);
    }
  };

  const handleImportCsv = () => {
    if (csvFile) {
      setLoader(true);
      return Papa.parse(csvFile, {
        header: true,
        delimiter: ";",
        skipEmptyLines: true,
        complete: (e) => {
          setImportedData(e.data);
          setCurrentStep(1);
          setLoader(false);
        },
        error: (e) => {
          console.error(e);
          setLoader(false);
        },
      });
    }
  };

  const handleCancelImportProcess = () => {
    cancelImportProcess(id, importFileId);
  };

  const changeErrorCount = () => {
    let errors = 0;
    mappedData.map((el) => (errors += el.errors.length));
    setErrorsCount(errors);
  };

  const handleApplyMapping = (lots: IImportLots[]) =>
    csvFile &&
    sendCsv(id, { fileName: csvFile.name, lots }).then((res) => {
      if (res) {
        setMappedData(res.data.lots);
        setImportFileId(res.data.importedFileId);
      }
      setCurrentStep(2);
    });

  const handleEditLot = (newLot: ImportLotsResponse) => {
    const newArray: ImportLotsResponse[] = mappedData.map((el) =>
      el.rowNumber === newLot.rowNumber ? newLot : el
    );

    setMappedData(newArray);
  };

  const handleDeleteLot = (newLot: ImportLotsResponse) => {
    const newArray: ImportLotsResponse[] = mappedData.filter(
      (el) => el.rowNumber !== newLot.rowNumber
    );

    setMappedData(newArray);
  };

  const togleOnlyWithErrors = () => {
    setOnlyWithErrors(!onlyWithErrors);
  };

  const handleSaveImportLots = () => {
    saveImportLot(id, importFileId);
    setCurrentStep(3);
  };

  useEffect(() => {
    !!importedData.length && getColumnList();
    // eslint-disable-next-line
  }, [importedData]);

  useEffect(() => {
    changeErrorCount();
    // eslint-disable-next-line
  }, [mappedData]);

  const getColumnList = () => {
    return Object.keys(importedData[0]);
  };

  return (
    <CsvImportContext.Provider
      value={{
        csvFile,
        handleImportCsv,
        handleLoadCsv,
        importedData,
        currentStep,
        setCurrentStep,
        getColumnList,
        handleApplyMapping,
        mappedData,
        errorsCount,
        changeErrorCount,
        onlyWithErrors,
        togleOnlyWithErrors,
        handleEditLot,
        handleDeleteLot,
        importFileId,
        handleSaveImportLots,
        handleCancelImportProcess,
      }}
    >
      {props.children}
    </CsvImportContext.Provider>
  );
};

export default CsvImportContext;
