import { UserRoles } from "API/Interfaces/Enums";
import { apiClient, IResponseApi } from "../Auth/apiClient";
import urls from "./urls";

export interface IAuthTokens {
  auth: string;
  authExp: number;
  refresh: string;
  refreshExp: number;
}
export interface IAuthenticateRequest {
  username: string;
  password: string;
}
export interface IAuthenticateResponse {
  roles: UserRoles;
  tokens: Pick<IAuthTokens, "auth" | "refresh">;
}

const authenticate = async (params: IAuthenticateRequest) => {
  try {
    const res = await apiClient.post<IResponseApi<IAuthenticateResponse>>(
      urls.users.authenticate,
      params
    );
    return res.data.data;
  } catch (err: any) {
    if (err.response.data.errors) {
      throw err.response.data.errors[0];
    }
    throw err;
  }
};
const refreshToken = async (): Promise<IAuthenticateResponse> => {
  try {
    const res = await apiClient.post<IResponseApi<IAuthenticateResponse>>(
      urls.users.refreshToken
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const AuthServiceApi = {
  authenticate,
  refreshToken,
};
export default AuthServiceApi;
