import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const Account: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M4.23932 14.7299C4.60982 13.6925 5.59248 13 6.69406 13C7.36301 13 8.02078 13.1716 8.60444 13.4985L10.0456 14.3055C11.2598 14.9855 12.7402 14.9855 13.9544 14.3055L15.3956 13.4985C15.9792 13.1716 16.637 13 17.3059 13C18.4075 13 19.3902 13.6925 19.7607 14.7299L21.0455 18.3273C21.5107 19.6298 20.5451 21 19.162 21H4.83801C3.45495 21 2.48935 19.6298 2.95453 18.3273L4.23932 14.7299Z" />
    <circle cx="12" cy="7" r="4" />
  </SvgIcon>
);
Account.defaultProps = {
  color: "disabled",
};
export default Account;
