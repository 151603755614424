import React from "react";
import {
  Radio,
  FormControlLabel,
  FormControlLabelProps as MUIFormControlLabelProps,
} from "@material-ui/core";

export interface AppRadioButtonProps
  extends Omit<MUIFormControlLabelProps, "control"> {
  control?: any;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = (props) => {
  return <FormControlLabel control={<Radio color="primary" />} {...props} />;
};

export default AppRadioButton;
