import { MapComponent } from "Layout/FormComponents/Map/MapComponent";
import React from "react";
import useMap from "./useMap";

interface LocationDetailsMapProps {}

export const Map: React.FC<LocationDetailsMapProps> = (props) => {
  const { center, markerPosition, setMarkerPosition, zoom } = useMap();

  return (
    <MapComponent
      position={markerPosition}
      center={center}
      onClick={setMarkerPosition}
      zoom={zoom}
      {...props}
    />
  );
};

export default Map;
