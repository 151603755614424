import { i18n, TranslationKeys, Translations } from "Translation";
import { FormComponentsAddressFields, YupSchemaKeys } from "API/Interfaces";
import { AddressConfiguration, IAddressItemValue } from "API/Interfaces";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";

export type AddressFieldsKeys = keyof FormComponentsAddressFields;
export type AddressYupSchemaKeys = YupSchemaKeys<FormComponentsAddressFields>;

export type FormTranslations = {
  [K in AddressFieldsKeys]: TranslationKeys;
};
export type MaxLengthTranslations = {
  [K in "50" | "100" | "200"]: TranslationKeys;
};
export type AddressConfigurationFields = {
  [K in AddressFieldsKeys]: IAddressItemValue;
};

const translations: FormTranslations = {
  addressStreet1: "COMMON_FORMS_ADDRESSISREQUIRED",
  addressCity: "COMMON_FORMS_CITYISREQUIRED",
  addressCounty: "COMMON_FORMS_COUNTYISREQUIRED",
  addressPostalCode: "COMMON_FORMS_POSTALCODEISREQUIRED",
  addressState: "COMMON_FORMS_STATEISREQUIRED",
  addressStreet2: "COMMON_FORMS_ADDRESSISREQUIRED",
};

const maxLengthTranslations: MaxLengthTranslations = {
  50: "COMMON_FORMS_MAXCHARACTER50",
  100: "COMMON_FORMS_MAXCHARACTER100",
  200: "COMMON_FORMS_MAXCHARACTER200",
};

const apiToFormFields: (
  arg: AddressConfiguration
) => AddressConfigurationFields = (arg) => ({
  addressCity: arg.city,
  addressCounty: arg.county,
  addressPostalCode: arg.zipCode,
  addressState: arg.state,
  addressStreet1: arg.street1,
  addressStreet2: arg.street2,
});

const defaultAddressConfiguration = {
  addressStreet1: yup
    .string()
    .required(i18n.t(Translations[translations.addressStreet1]))
    .max(100, i18n.t(Translations[maxLengthTranslations[100]])),
  addressStreet2: yup
    .string()
    .max(100, i18n.t(Translations[maxLengthTranslations[100]])),
  addressPostalCode: yup
    .string()
    .required(i18n.t(Translations[translations.addressPostalCode]))
    .max(50, i18n.t(Translations[maxLengthTranslations[50]])),
  addressCounty: yup
    .string()
    .required(i18n.t(Translations[translations.addressCounty]))
    .max(50, i18n.t(Translations[maxLengthTranslations[50]])),
  addressCity: yup
    .string()
    .required(i18n.t(Translations[translations.addressCity]))
    .max(50, i18n.t(Translations[maxLengthTranslations[50]])),
  addressState: yup
    .string()
    .required(i18n.t(Translations[translations.addressState]))
    .max(50, i18n.t(Translations[maxLengthTranslations[50]])),
};

export const generateAddressConfigurationSchema = (
  arg?: AddressConfiguration
) => {
  if (!arg) return defaultAddressConfiguration;
  const args = apiToFormFields(arg);
  let yupObject: ObjectShape = {};
  Object.keys(args).forEach((key) => {
    const keyT = key as AddressFieldsKeys;
    yupObject[keyT] = yup.string();
    if (args[keyT].isRequired) {
      yupObject[keyT] = (
        yupObject[keyT] as yup.StringSchema<
          string | undefined,
          Record<string, any>,
          string | undefined
        >
      ).required(i18n.t(Translations[translations[keyT]]));
    }
    if (args[keyT].maxLength) {
      const maxValue = args[keyT]
        .maxLength as unknown as keyof MaxLengthTranslations;
      yupObject[keyT] = (
        yupObject[keyT] as yup.StringSchema<
          string | undefined,
          Record<string, any>,
          string | undefined
        >
      ).max(
        maxValue as unknown as number,
        i18n.t(Translations[maxLengthTranslations[maxValue]])
      );
    }
  });

  return yupObject;
};
