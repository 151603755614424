import { MenuItem } from "@material-ui/core";
import { AddressConfiguration } from "API/Interfaces";
import { useAddressContext } from "Contexts";
import { useFormikContext } from "formik";
import { FormComponentsFields } from "Layout/FormComponents/types";
import React from "react";
import { getFieldName } from "Utils";
import useAddressConfiguration from "Utils/AddressConfiguration/useAddressConfiguration";
import FormSelectField, { FormSelectFieldProps } from "./FormSelectField";

export interface FormCountriesFieldProps extends Partial<FormSelectFieldProps> {
  updateSchema: (arg: AddressConfiguration) => void;
}
export const FormCountriesField: React.FC<FormCountriesFieldProps> = ({
  updateSchema,
  ...props
}) => {
  const { countries } = useAddressContext();
  const [getAddressConfiguration, { loading }] =
    useAddressConfiguration(updateSchema);
  const fieldName = getFieldName<FormComponentsFields>("country");
  const { handleChange, values, setFieldValue, validateForm } =
    useFormikContext<FormComponentsFields>();

  const onSelect = async (id: string, lastValue: number) => {
    try {
      const numId = parseInt(id);
      const res = await getAddressConfiguration(numId);
      updateSchema(res);
      validateForm();
    } catch (err) {
      setFieldValue(fieldName, lastValue);
      console.warn("Failed get address configuration");
    }
  };

  return (
    <FormSelectField
      fieldName={fieldName}
      labelTranslation="COMMON_FORMS_COUNTRY"
      onChange={(e) => {
        handleChange(e);
        onSelect(e.target.value as string, values[fieldName] as number);
      }}
      disabled={loading}
      {...props}
    >
      <MenuItem disabled value="" />
      {countries.map((country) => (
        <MenuItem value={country.id} key={country.id}>
          {country.name}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

export default FormCountriesField;
