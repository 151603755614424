import { List, ListItem, makeStyles, Typography } from "@material-ui/core";
import styled from "styled-components";
import { MenuIcons } from "assets/icons";
import { useExpandedPanelContext } from "Contexts/ExpandedPanelContext";
import React from "react";
import { matchPath, useHistory } from "react-router-dom";
import { routes } from "Routing/routes";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";

export interface ApplicationNavProps {}

const menuItems: {
  path: string;
  label: TranslationKeys;
  Icon: () => JSX.Element;
}[] = [
  {
    label: "COMMON_BUTTONS_CORPORATIONS",
    path: routes.corporationList,
    Icon: () => <MenuIcons.Corporations />,
  },
  // {
  //   label: "COMMON_BUTTONS_REPORTS",
  //   path: routes.reports,
  //   Icon: () => <MenuIcons.Reports />,
  // },
];

const useStyles = makeStyles({
  selected: {
    backgroundColor: "rgba(255,255,255,.3)!important",
    zIndex: 2,
  },
});

const IconLabel = styled(Typography)<{ $isExpanded: boolean }>`
  color: white;
  margin-left: 10px;
  font-size: 16px;
  ${(_) => _.theme.breakpoints.up("lg")} {
    visibility: ${(_) => (_.$isExpanded ? "visible" : "hidden")};
    opacity: ${(_) => (_.$isExpanded ? 100 : 0)};
    transition: opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: absolute;
    left: 100px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  > * {
    width: 36px;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    width: 135px;
    * {
      width: unset;
    }
  }
`;

const NavListItem = styled(ListItem)`
  padding: 0px;
  position: relative;
  max-height: 54px;
  ${(_) => _.theme.breakpoints.up("lg")} {
    max-height: unset;
  }
`;

export const ApplicationNav: React.FC<ApplicationNavProps> = (props) => {
  const { selected } = useStyles();
  const { location, push } = useHistory();
  const t = useTranslate();
  const { isExpanded, setIsExpanded, setShowMobileMenu } =
    useExpandedPanelContext();
  const { up } = useBreakpoints();

  return (
    <List style={{ padding: 0 }}>
      {menuItems.map(({ Icon, path, label }, i) => {
        const isSelected = Boolean(matchPath(location.pathname, { path }));
        return (
          <NavListItem
            key={i}
            onClick={() => {
              push(path);
              setIsExpanded(false);
              setShowMobileMenu(false);
            }}
            button
            selected={isSelected}
            classes={{
              selected,
            }}
          >
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <IconLabel variant="h2" $isExpanded={isExpanded && !up("xl")}>
              {t(label)}
            </IconLabel>
          </NavListItem>
        );
      })}
    </List>
  );
};

export default ApplicationNav;
