import { usePopupContext } from "Contexts";
import { useTranslate } from "Hooks";
import ErrorList from "Layout/Components/ErrorList";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export interface ErrorContextType {
  setError: (errors?: string) => void;
}

export const ErrorContext = createContext<ErrorContextType>(
  {} as ErrorContextType
);

export const useErrorContext = () => useContext(ErrorContext);

export const ErrorProvider: FC = ({ children }) => {
  const t = useTranslate();
  const { setPopup, showPopup } = usePopupContext();
  const [errorList, setErrorList] = useState<string[]>([]);

  useEffect(() => {
    if (errorList.length) {
      setPopup({
        variant: "error",
        buttonOkClick: () => setErrorList([]),
        descriptionNode: <ErrorList errorList={errorList} />,
      });
      showPopup();
    }
  }, [errorList, setPopup, showPopup]);

  const setError = useCallback(
    (error?: string) => {
      setErrorList((prev) =>
        error
          ? [...prev, error]
          : [...prev, t("DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN")]
      );
    },
    [t]
  );

  return (
    <ErrorContext.Provider value={{ setError }}>
      {children}
    </ErrorContext.Provider>
  );
};
