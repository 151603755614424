import { IAuthenticateRequest } from "API/Services";
import LogoDark from "assets/images/LogoDark";
import { Formik } from "formik";
import useBreakpoints from "Hooks/useBreakpoints";
import {
  AppFormHelperText,
  AppPaper,
  AppTypography,
  GridWrapper,
} from "Layout/Components";
import { FormSubmitButton } from "Layout/FormComponents";
import React from "react";
import * as Yup from "yup";
import { PasswordInput, UsernameInput } from "../Components";
import { AuthFields } from "../fields";
import { passwordSchema, usernameSchema } from "../schema";
import styled from "styled-components";

const schema = Yup.object().shape({
  username: usernameSchema,
  password: passwordSchema,
});

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  > svg {
    width: 106px;
    height: 70px;
    ${(_) => _.theme.breakpoints.up("sm")} {
      width: 160px;
      height: 105px;
    }
  }
`;

const AppTypographyCustom = styled(AppTypography)`
  font-size: 1.3rem;
  ${(_) => _.theme.breakpoints.up("sm")} {
    font-size: 2.3rem;
  }
`;

export const initialValues: Pick<AuthFields, "password" | "username"> = {
  username: "",
  password: "",
};

export interface LoginProps {
  error?: boolean;
  loading: boolean;
  errorMsg?: string;
  submit?: (params: IAuthenticateRequest) => Promise<void>;
}

export const LoginView: React.FC<LoginProps> = (props) => {
  const { down } = useBreakpoints();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(e) => {
        if (props.submit) {
          props.submit(e);
        }
      }}
      validateOnChange={false}
      enableReinitialize
    >
      {(utils) => (
        <form onSubmit={utils.handleSubmit}>
          <AppPaper borderRadius={40} padding={down("sm") ? 8 : 3} margin={20}>
            <GridWrapper>
              <LogoWrapper>
                <LogoDark />
              </LogoWrapper>
              <>
                <AppTypographyCustom
                  translation="COMMON_WELCOME"
                  align="center"
                  size={2.25}
                  weight={700}
                />
                <AppTypography
                  translation="COMMON_LOGINTOCRMPORTAL"
                  align="center"
                />
              </>
              <UsernameInput disabled={props.loading} />
              <PasswordInput disabled={props.loading} />
              <FormSubmitButton
                translation="COMMON_AUTH_LOGIN"
                textAlign="center"
                loading={props.loading}
                topGutter={20}
              />
              {props.error && (
                <AppFormHelperText error fontSize={1} textAlign="center">
                  {props.errorMsg}
                </AppFormHelperText>
              )}
            </GridWrapper>
          </AppPaper>
        </form>
      )}
    </Formik>
  );
};
export default LoginView;
