import React from "react";
import LoginView from "./LoginView";
import useLogin from "../Hooks/useLogin";

export const Login: React.FC = (props) => {
  const [submit, loading, errorMsg] = useLogin();
  return (
    <LoginView
      error={!!errorMsg}
      errorMsg={errorMsg}
      loading={loading}
      submit={submit}
    />
  );
};

export default Login;
