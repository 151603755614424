import { Box } from "@material-ui/core";
import { ILocationDto } from "API/Interfaces";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { AddButton, AppPaper, AppTypography } from "Layout/Components";
import IntegrationStatusChip from "Layout/Components/IntegrationStatusChip";
import ProductType from "Layout/Components/ProductType";
import SearchInput from "Layout/Components/SearchInput";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { generateRoute } from "Routing/routes";
import {
  EmptyWrapper,
  ListTitle,
  ListTitleText,
  LocationInfo,
  LocationItem,
  LocationName,
  LocationsHeader,
  LotTittleWrapper,
  Paging,
} from "./Components/Locations/Locations.styled";
import { useCorporationContext } from "./Contexts/CorporationContext";
import LotCounter from "./Components/Locations/LotCounter";
import { BigLocationIcon } from "assets/icons";

const Locations = () => {
  const t = useTranslate();
  const { push } = useHistory();
  const { down } = useBreakpoints();
  const { id, locations } = useCorporationContext();
  const { data, page, search, setSearch, numberOfPages, getData, status } =
    locations;

  useEffect(() => {
    !data && getData();
  }, [data, getData]);

  return data ? (
    <div>
      <LocationsHeader>
        {!status.error ? (
          <SearchInput
            placeholder="COMMON_FORMS_SEARCHLOCATION"
            onSubmit={setSearch}
            showButton
          />
        ) : (
          <div />
        )}

        <AddButton
          translation="COMMON_BUTTONS_ADDNEWLOCATION"
          onClick={() =>
            push(generateRoute.location("new"), {
              corporationId: id,
            })
          }
        />
      </LocationsHeader>
      <AppPaper elevation={3} padding={down("md") ? 3 : 2} fullWidth>
        {down("md") && data && data?.length > 0 && (
          <ListTitle>
            <ListTitleText>{t("COMMON_PAGES_LOCATIONS")}</ListTitleText>
            <LotTittleWrapper>
              <ListTitleText>{t("COMMON_PAGES_LOTS")}</ListTitleText>
            </LotTittleWrapper>
          </ListTitle>
        )}
        <div>
          {data?.length > 0 ? (
            data.map((l: ILocationDto) => (
              <LocationItem key={l.id}>
                <LocationInfo>
                  <Box display="flex" alignItems="center">
                    <LocationName
                      size={0.875}
                      paddingRight={1}
                      onClick={() =>
                        push(generateRoute.location(l.id), {
                          goBackToList: true,
                        })
                      }
                    >
                      {l.name}
                    </LocationName>
                    <IntegrationStatusChip
                      integrationStatus={l.integrationStatus}
                    />
                    <ProductType integratedWith={l.integratedWithSystem} />
                  </Box>
                  <AppTypography>{l.address}</AppTypography>
                </LocationInfo>
                <LotTittleWrapper>
                  <LotCounter numberOfLots={l.numberOfLots} />
                </LotTittleWrapper>
              </LocationItem>
            ))
          ) : (
            <EmptyWrapper>
              <BigLocationIcon />
              <AppTypography
                color="secondary"
                translation="COMMON_NOLOCATIONS"
              />
            </EmptyWrapper>
          )}
        </div>
      </AppPaper>
      {numberOfPages > 1 && (
        <Paging
          pages={numberOfPages}
          currentPage={page}
          callback={(p: number) => getData(p, search)}
        />
      )}
    </div>
  ) : null;
};

export default Locations;
