import { Tooltip, Typography } from "@material-ui/core";
import { AppTypography } from "Layout/Components";
import Pagination from "Layout/Components/Pagination";
import styled from "styled-components";

export const LotsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-bottom: 32px;
    padding: 0;
  }
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
  > :not(:first-child) {
    margin-left: 10px;
  }
  ${(_) => _.theme.breakpoints.down("lg")} {
    flex-direction: column;
    align-items: flex-end;
    > :not(:first-child) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
`;

export const ListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ActiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-right: 40px;
  }
`;
export const LotWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
`;
export const ColumnTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const LotItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  padding-bottom: 10px;
  margin-bottom: 10px;
  :last-child {
    border-bottom: none;
  }
`;

export const LotName = styled(AppTypography)`
  cursor: pointer;
`;

export const Paging = styled(Pagination)`
  margin-top: 20px;
`;

export const StyledTooltip = styled(Tooltip)`
  color: #fff;
  padding: 4px 8px;
  font-size: 1rem;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  line-height: 1.4em;
  border-radius: 4px;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`;
