import { ListItem } from "@material-ui/core";
import { AppButton } from "Layout/Components";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import React from "react";

export interface MoreResultsProps {
  onClick: () => void;
  loading: boolean;
}

export const MoreResults: React.FC<MoreResultsProps> = ({
  onClick,
  loading,
}) => (
  <ListItem>
    <ButtonWrapper topSpacing={2}>
      <AppButton
        variant="outlined"
        onClick={onClick}
        loading={loading}
        translation="COMMON_BUTTONS_SEEMORERESULTS"
        textAlign="center"
      />
    </ButtonWrapper>
  </ListItem>
);

export default MoreResults;
