import { BillingServiceApi } from "API/Services";
import { useLoaderContext, usePopupContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { BillingFields } from "../Components/BillingInfo/BillingInfo.schema";
import {
  billingInfoFormToResp,
  billingInfoRespToForm,
} from "../Components/BillingInfo/utils";

const useBillingInfo = () => {
  const { id }: { id: string } = useParams();
  const { setError } = useErrorContext();
  const { setLoading } = useLoaderContext();
  const { showPopup, setPopup } = usePopupContext();
  const [billingInfo, setBillingInfo] = useState<BillingFields | null>(null);

  const getBillingInfo = useCallback(() => {
    setLoading(true);
    BillingServiceApi.getBillingInfo(id)
      .then((res) => setBillingInfo(billingInfoRespToForm(res)))
      .catch(() => setError())
      .finally(() => setLoading(false));
  }, [id, setError, setLoading]);

  const saveBillingInfo = (params: BillingFields) => {
    setLoading(true);
    const arg = billingInfoFormToResp(params);
    BillingServiceApi.saveBillingInfo(id, arg)
      .then(() => {
        setBillingInfo(params);
        setPopup({
          variant: "info",
          titleTranslation: "COMMON_PAGES_BILLINGINFO",
        });
      })
      .catch(() =>
        setPopup({
          variant: "error",
          titleTranslation: "COMMON_PAGES_BILLINGINFO",
        })
      )
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  return { billingInfo, getBillingInfo, saveBillingInfo } as const;
};

export default useBillingInfo;
