import {
  IFetchStatus,
  IFetchStatusObj,
  ISetFetchStatusObj,
} from "API/Interfaces";
import React, { useMemo } from "react";

export const useFetchStatus = () => {
  const [statusString, setStatusString] = React.useState<IFetchStatus>("init");

  const setFetchStatus: ISetFetchStatusObj = useMemo(
    () => ({
      init: () => setStatusString("init"),
      loading: () => setStatusString("loading"),
      error: () => setStatusString("error"),
      success: () => setStatusString("success"),
    }),
    []
  );

  const fetchStatus: IFetchStatusObj = useMemo(
    () => ({
      init: statusString === "init",
      loading: statusString === "loading",
      error: statusString === "error",
      success: statusString === "success",
    }),
    [statusString]
  );

  return [setFetchStatus, fetchStatus] as const;
};

export default useFetchStatus;
