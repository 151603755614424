import { AppTypography } from "Layout/Components";
import { FC } from "react";
import styled from "styled-components";

const ErrorListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(_) => _.theme.palette.neutral.light};
  padding: 10px;
  border-radius: 10px;
`;

const ErrorList: FC<{ errorList: string[] }> = ({ errorList }) => {
  return (
    <ErrorListWrapper>
      {errorList.map((e, i) => (
        <AppTypography key={i}>{e}</AppTypography>
      ))}
    </ErrorListWrapper>
  );
};

export default ErrorList;
