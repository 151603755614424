import { SvgIconProps } from "@material-ui/core";
import * as React from "react";
import { MenuIconWrapper } from "./MenuIconWrapper";
export const Corporations: React.FC<SvgIconProps> = (props) => {
  return (
    <MenuIconWrapper {...props}>
      <path
        fill="#fff"
        d="M7 44a3 3 0 013-3h28a3 3 0 013 3H7zM13 5a1 1 0 011-1h10a1 1 0 011 1v6H13V5z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M27 19l10 7v15H27V19zm2 12a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zm1 4a1 1 0 100 2h4a1 1 0 100-2h-4zM12 11a1 1 0 00-1 1v29h6v-4a1 1 0 011-1h2a1 1 0 011 1v4h6V12a1 1 0 00-1-1H12zm1 20a1 1 0 011-1h10a1 1 0 110 2H14a1 1 0 01-1-1zm1-6a1 1 0 100 2h10a1 1 0 100-2H14zm-1-4a1 1 0 011-1h10a1 1 0 110 2H14a1 1 0 01-1-1zm1-6a1 1 0 100 2h10a1 1 0 100-2H14z"
        clipRule="evenodd"
      />
    </MenuIconWrapper>
  );
};

export default Corporations;
