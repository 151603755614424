import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import useTranslate from "../../Hooks/useTranslations";
import GeneralInfo from "./GeneralInfo";
import Locations from "./Locations";
import { CorporationTabs as CTabs, EntityType } from "API/Interfaces";
import { PageDetailsHeader } from "Layout/Components";
import CorporationBreadcrumbs from "./Components/CorporationBreadcrumbs";
import FunctionalitiesAndProducts from "Layout/FormComponents/FunctionalitiesAndProducts";
import usePageName from "Hooks/usePageName";
import {
  CorporationProvider,
  useCorporationContext,
} from "./Contexts/CorporationContext";
import { AddressConfigurationProvider } from "Utils";

interface CorpoState {
  currentTab: CTabs;
  goToAnotherTab?: boolean;
}

const CorporationDetailsPage = () => {
  usePageName("COMMON_PAGES_CORPORATIONDETAILS");
  const t = useTranslate();
  const { pathname, state } = useLocation<CorpoState>();
  const { replace } = useHistory();
  const { id, getCorporation } = useCorporationContext();
  const [currentTab, setCurrentTab] = useState(
    state?.currentTab || CTabs.GeneralInfo
  );

  useEffect(
    () => replace(pathname, { currentTab }),
    [currentTab, pathname, replace]
  );

  useEffect(() => {
    if (state && state?.goToAnotherTab) {
      setCurrentTab(CTabs.GeneralInfo);
    }
  }, [id, state?.goToAnotherTab, state]);

  return (
    <>
      <PageDetailsHeader hideBorder={currentTab === CTabs.Locations}>
        <CorporationBreadcrumbs />
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setCurrentTab(v)}
        >
          <Tab label={t("COMMON_PAGES_GENERALINFO")} />
          {id && <Tab label={t("COMMON_PAGES_FUNCTIONALITIESANDPRODUCTS")} />}
          {id && <Tab label={t("COMMON_PAGES_LOCATIONS")} />}
        </Tabs>
      </PageDetailsHeader>
      {currentTab === CTabs.GeneralInfo && <GeneralInfo />}
      {currentTab === CTabs.FunctionalitiesAndProducts && (
        <FunctionalitiesAndProducts
          getData={getCorporation}
          entityType={EntityType.Corporation}
        />
      )}
      {currentTab === CTabs.Locations && <Locations />}
    </>
  );
};

const CorporationDetails = () => (
  <AddressConfigurationProvider>
    <CorporationProvider>
      <CorporationDetailsPage />
    </CorporationProvider>
  </AddressConfigurationProvider>
);

export default CorporationDetails;
