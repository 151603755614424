import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";

export interface FormCheckboxProps extends CheckboxProps {
  fieldName: string;
  labelTranslation?: TranslationKeys;
}

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 9px;
`;

export const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const t = useTranslate();
  const { fieldName, labelTranslation, color, ...otherProps } = props;
  return (
    <Main>
      <FormControlLabel
        control={
          <StyledCheckbox
            name={fieldName}
            color={color || "primary"}
            {...otherProps}
          />
        }
        label={t(labelTranslation)}
      />
    </Main>
  );
};

export default FormCheckbox;
