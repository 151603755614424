import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AuthLayout from "Layout/AuthLayout";
import { Login } from "Pages/Auth";
import { routes } from "./routes";

const UnauthenticatedRouter = () => {
  return (
    <Router>
      <AuthLayout>
        <Switch>
          <Route path={routes.login} component={Login} />
          <Redirect from={routes.root} to={routes.login} />
        </Switch>
      </AuthLayout>
    </Router>
  );
};

export default UnauthenticatedRouter;
