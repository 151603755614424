import { AuthServiceApi, config } from "API/Services";
import urls from "API/Services/urls";
import axios from "axios";
import { convertUserInfo, tokensStorageService } from "Pages/Auth";
import { routes } from "Routing/routes";

export interface IResponseApi<T> {
  data: T;
  errors: any[];
}
export const apiClient = axios.create({
  baseURL: config.API_URL_PATH,
  timeout: 100000,
  headers: { Version: "1.0", "Content-Type": "application/json" },
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const isRedirect =
      originalRequest.url === urls.redirects.valet ||
      originalRequest.url === urls.redirects.valet;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== urls.users.refreshToken
    ) {
      originalRequest._retry = true;
      const tokens = tokensStorageService.get();
      if (tokens) {
        setAuthHeader(tokens.refresh);
        try {
          const res = await AuthServiceApi.refreshToken();
          tokensStorageService.set({
            ...convertUserInfo(res.tokens),
            roles: res.roles,
          });
          setAuthHeader(res.tokens.auth);
          originalRequest.headers.Authorization = `Bearer ${res.tokens.auth}`;
          if (isRedirect) {
            originalRequest.data = JSON.stringify({
              sessionId: convertUserInfo(res.tokens).sessionId,
            });
          }
          return apiClient(originalRequest);
        } catch (err) {
          if (!isRedirect) {
            window.location.replace(routes.login);
            tokensStorageService.remove();
            removeAuthHeader();
          }
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const setAuthHeader = (token: string) => {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeAuthHeader = () => {
  delete apiClient.defaults.headers.common["Authorization"];
};
