import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip } from "@material-ui/core";
import { TranslationKeys } from "Translation";
import { useFormikContext } from "formik";
import { LocationFormFieldsWithAddress } from "Utils/locationForm";
import { FormTextField } from "Layout/FormComponents";
import { isArrayOfString } from "Utils";
export interface FormAutocompleteProps {
  fieldName: keyof Pick<
    LocationFormFieldsWithAddress,
    "contactEmail" | "billingEmail"
  >;
  label?: TranslationKeys;
}

const MultiEmailAutocomplete = ({
  fieldName,
  label,
}: FormAutocompleteProps) => {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<LocationFormFieldsWithAddress>();
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (target.value.length > 0) {
          setFieldValue(fieldName, [...values[fieldName], target.value]);
        }
        break;
      }
      default:
    }
  };
  const helperText =
    touched[fieldName] && !!errors[fieldName]
      ? isArrayOfString(errors[fieldName])
        ? (errors[fieldName] as Array<string>)?.find((el) => !!el)
        : errors[fieldName]
      : undefined;

  return (
    <Autocomplete
      multiple
      options={[]}
      freeSolo
      size="small"
      value={values[fieldName]}
      limitTags={2}
      autoSelect
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            color={
              !!errors[fieldName] && !!errors[fieldName]![index]
                ? "secondary"
                : "default"
            }
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(_, v) => setFieldValue(fieldName, v)}
      renderInput={(params) => (
        <FormTextField
          onChange={undefined}
          {...params}
          fieldName={fieldName}
          labelTranslation={label}
          InputLabelProps={{ ...params.InputLabelProps, required: true }}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            onKeyDown: handleKeyDown,
          }}
        />
      )}
    />
  );
};

export default MultiEmailAutocomplete;
