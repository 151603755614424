import { FileServiceApi } from "API/Services";
import { useFormikContext } from "formik";
import { useFetchStatus, useTranslate } from "Hooks";
import { FileUploadProps } from "Layout/FormComponents/FileUpload/FileUpload";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const useFileUpload = ({ id, type }: FileUploadProps) => {
  const t = useTranslate();
  const [setStatus, status] = useFetchStatus();
  const [file, setFile] = useState<File>();
  const isPhoto = type === "lot";
  const [message, setMessage] = useState(
    t(isPhoto ? "COMMON_JPGFORMATFILE5MB" : "COMMON_JPGFORMATFILE50KB")
  );
  const { setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    setStatus.loading();
    FileServiceApi.getFileById(id, type)
      .then((res) => {
        setStatus.success();
        setFile(res);
      })
      .catch(() => setStatus.init());
  }, [id, setStatus, type]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg",
    maxSize: isPhoto ? 5000000 : 50000,
    multiple: false,
    onDropRejected: (rej) => {
      setStatus.error();
      rej[0].errors[0]?.code === "file-too-large"
        ? setMessage(
            t(
              isPhoto ? "COMMON_FILELARGERTHAN5MB" : "COMMON_FILELARGERTHAN50KB"
            )
          )
        : setMessage(rej[0].errors[0].message);
    },
    onDrop: (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;
      setStatus.loading();
      const formData = new FormData();
      formData.append(acceptedFiles[0].name, acceptedFiles[0]);
      FileServiceApi.saveFile(formData, isPhoto)
        .then((res) => {
          setFile(acceptedFiles[0]);
          setFieldValue(isPhoto ? "fileId" : "logoId", res.id);
          setStatus.success();
        })
        .catch(() => setStatus.error());
    },
  });

  return {
    file,
    setFile,
    message,
    status,
    getRootProps,
    getInputProps,
  } as const;
};

export default useFileUpload;
