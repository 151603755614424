import { useDeleteDialog } from "Hooks";
import { useHistory } from "react-router-dom";
import { routes } from "Routing/routes";

const useDeleteCorporation = () => {
  const { push } = useHistory();

  return useDeleteDialog("corporation", "goRoot", () =>
    push(routes.corporationList)
  );
};

export default useDeleteCorporation;
