import { TextField, TextFieldProps } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import React from "react";
import { TranslationKeys } from "Translation";

interface Props {
  fieldName: string;
  labelTranslation?: TranslationKeys;
  inputRequired?: boolean;
  endAdornmentIcon?: any;
}
export type FormTextFieldProps = Props & TextFieldProps;

export const FormTextField: React.FC<FormTextFieldProps> = ({
  fieldName,
  labelTranslation,
  inputRequired,
  endAdornmentIcon,
  InputProps,
  ...props
}) => {
  const t = useTranslate();
  const { errors, values, handleChange, touched, handleBlur } =
    useFormikContext<any>();

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      name={fieldName}
      label={t(labelTranslation)}
      value={values[fieldName]}
      onBlur={handleBlur}
      onChange={handleChange}
      InputLabelProps={{ required: inputRequired, ...props.InputLabelProps }}
      error={touched[fieldName] && Boolean(errors[fieldName])}
      helperText={touched[fieldName] && errors[fieldName]}
      InputProps={{
        endAdornment: endAdornmentIcon,
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default FormTextField;
