import { AddressConfiguration, IAddressItemValue } from "API/Interfaces";
import { FormAddressConfiguration } from "Pages/CorporationDetails/Contexts/CorporationContext";
import React, { useCallback, useContext } from "react";
import { apiToFormAddressConfiguration } from "Utils/corporationForm";

const defaultConfiguration: IAddressItemValue = {
  isAvailable: true,
  isRequired: true,
  maxLength: 50,
  value: "",
};

const defaultAddressConfiguration = {
  addressCity: defaultConfiguration,
  addressCounty: defaultConfiguration,
  addressPostalCode: defaultConfiguration,
  addressState: defaultConfiguration,
  addressStreet1: {
    ...defaultConfiguration,
    maxLength: 100,
  } as IAddressItemValue,
  addressStreet2: {
    ...defaultConfiguration,
    isRequired: false,
    maxLength: 100,
  } as IAddressItemValue,
};

export type AddressConfigurationContextType = {
  addressConfiguration: FormAddressConfiguration;
  setAddressConfiguration: (arg: AddressConfiguration) => void;
};
export const AddressConfigurationContext =
  React.createContext<AddressConfigurationContextType>({
    addressConfiguration: defaultAddressConfiguration,
    setAddressConfiguration: () => undefined,
  });
export const useAddressConfigurationContext = () =>
  useContext(AddressConfigurationContext);

export const AddressConfigurationProvider: React.FC = (props) => {
  const [addressConfiguration, setAddressConfiguration] = React.useState(
    defaultAddressConfiguration
  );
  const updateAddressConfiguration = useCallback(
    (arg: AddressConfiguration) =>
      setAddressConfiguration(apiToFormAddressConfiguration(arg)),
    []
  );

  return (
    <AddressConfigurationContext.Provider
      value={{
        addressConfiguration,
        setAddressConfiguration: updateAddressConfiguration,
      }}
    >
      {props.children}
    </AddressConfigurationContext.Provider>
  );
};

export default AddressConfigurationContext;
