import { Box } from "@material-ui/core";
import { Breadcrumb } from "API/Interfaces";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppLink, AppTypography } from "Layout/Components";
import AppBreadcrumbs from "Layout/Components/AppBreadcrumbs";
import { FC } from "react";
import IntegrationStatusChip from "./IntegrationStatusChip";
import ProductType from "./ProductType";

interface PageBreadcrumbsProps {
  path: Breadcrumb[];
  current: Partial<Breadcrumb>;
}

export const PageBreadcrumbs: FC<PageBreadcrumbsProps> = ({
  path,
  current,
}) => {
  const { down } = useBreakpoints();

  const CurrentPage = (
    <Box display="flex" alignItems="center">
      <AppTypography paddingRight={1} variant="h2" color="textPrimary">
        {current.name}
      </AppTypography>
      <IntegrationStatusChip integrationStatus={current.integrationStatus} />
      <ProductType integratedWith={current.integratedWithSystem} />
    </Box>
  );
  return (
    <>
      <AppBreadcrumbs>
        {path.map(({ id, name, integratedWithSystem, integrationStatus }) => (
          <Box display="flex" alignItems="center" key={id}>
            <AppLink to={id} key={id}>
              <AppTypography
                size={down("lg") ? 1.25 : 0.75}
                paddingRight={1}
                variant="h2"
              >
                {name}
              </AppTypography>
            </AppLink>
            {down("xl") && (
              <>
                <IntegrationStatusChip integrationStatus={integrationStatus} />
                <ProductType integratedWith={integratedWithSystem} />
              </>
            )}
          </Box>
        ))}

        {down("xl") && CurrentPage}
      </AppBreadcrumbs>
      {!down("xl") && CurrentPage}
    </>
  );
};

export default PageBreadcrumbs;
