const USERS = "users";
const ADDRESSES = "addresses";
const COUNTRIES = "countries";
const CORPORATIONS = "corporations";
const LOCATIONS = "locations";
const LOTS = "lots";
const IMPORT_LOTS = "import-lots";
const FILES = "files";
const REDIRECT = "redirect";
const FUNCTIONALITIES = "functionalities";

const files = {
  root: `${FILES}/`,
  photos: `${FILES}/photo`,
};

const users = {
  root: USERS,
  authenticate: `${USERS}/authenticate`,
  autoAuthenticate: `${USERS}/auto-authenticate`,
  refreshToken: `${USERS}/refresh-token`,
  redirectToEnf: `${USERS}/${REDIRECT}`,
};

const redirects = {
  enforcement: `${REDIRECT}/enf`,
  valet: `${REDIRECT}/valet`,
};

const addresses = {
  countries: `${ADDRESSES}/${COUNTRIES}`,
  countriesConfiguration: (id: string | number = "") =>
    `${ADDRESSES}/${COUNTRIES}/${id}/configuration`,
  countriesTimezones: (id: string | number = "") =>
    `${ADDRESSES}/${COUNTRIES}/${id}/timezones`,
};

const corporations = {
  all: () => `${CORPORATIONS}`,
  details: (id: string) => `${CORPORATIONS}/${id}`,
  locations: (id: string = "") => `${CORPORATIONS}/${id}/${LOCATIONS}`,
  searchAll: `${CORPORATIONS}/search-all`,
};

const locations = {
  locationDetails: (id: string, corpId: string) =>
    `/${CORPORATIONS}/${corpId}/${LOCATIONS}/${id}`,
  location: (id: string) => `/${LOCATIONS}/${id}`,
  importLots: (id: string) => `/${LOCATIONS}/${id}/${IMPORT_LOTS}`,
  changeImportLot: (locId: string, lotId: string) =>
    `/${LOCATIONS}/${locId}/${IMPORT_LOTS}/${lotId}`,
  saveImportLots: (id: string, fileId: string) =>
    `/${LOCATIONS}/${id}/save-imported-lots/${fileId}`,
  cancelImportProcess: (id: string, fileId: string) =>
    `/${LOCATIONS}/${id}/imported-file/${fileId}`,
  lots: (id: string) => `/${LOCATIONS}/${id}/${LOTS}`,
  breadcrumbs: (id: string) => `/${LOCATIONS}/${id}/breadcrumbs`,
};

const billingInfo = (id: string) => `${LOCATIONS}/${id}/billing-info`;

const lots = {
  locationLot: (id: string) => `/${LOCATIONS}/${id}/${LOTS}`,
  lot: (id: string) => `/${LOTS}/${id}`,
  breadcrumbs: (id: string) => `/${LOTS}/${id}/breadcrumbs`,
};

const functionalities = (id: string) => `/${FUNCTIONALITIES}/${id}`;

export const urls = {
  users,
  files,
  addresses,
  corporations,
  locations,
  lots,
  billingInfo,
  redirects,
  functionalities,
};

export default urls;
