import { useState, useRef } from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import styled from "styled-components";
import { useTranslate } from "Hooks";
import { useAuthContext } from "Pages/Auth";
import { ComputerIcon, LogoutIcon } from "assets/icons";
import useRedirects from "Hooks/useRedirects";

const ProfileButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  font-weight: 400;
  min-width: 180px;
  padding: 0;
  text-transform: none;
  font-size: 14px;
  :hover {
    background-color: transparent;
  }
`;

const ProfileMenuItem = styled(MenuItem)`
  padding: 8px 24px;
  width: 340px;
  font-weight: 700;
  color: ${(_) => _.theme.palette.primary.main};
  > svg {
    margin-right: 12px;
  }
`;

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggleButton = useRef(null);
  const { user, unsetUser } = useAuthContext();
  const { redirectToEnf, redirectToValet } = useRedirects();
  const handleToggle = () => setAnchorEl(toggleButton.current);
  const handleClose = () => setAnchorEl(null);
  const t = useTranslate();
  return (
    <div>
      <ProfileButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleToggle}
        ref={toggleButton}
      >
        {user ? `${user.firstName} ${user.lastName}` : "CRM Admin"}
        <ArrowDropDownIcon color="primary" />
      </ProfileButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ProfileMenuItem onClick={redirectToEnf}>
          <ComputerIcon /> {t("COMMON_AUTH_GOTOENFORCEMENT")}
        </ProfileMenuItem>
        <ProfileMenuItem onClick={() => redirectToValet()}>
          <ComputerIcon /> {t("COMMON_BUTTONS_GOTOVALETADMINPORTAL")}
        </ProfileMenuItem>
        <ProfileMenuItem onClick={unsetUser}>
          <LogoutIcon /> {t("COMMON_AUTH_LOGOUT")}
        </ProfileMenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
