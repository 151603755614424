import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./Translation/i18n";
import withClearCache from "Utils/withClearCache";

const WithClearCacheApp = withClearCache(App);
ReactDOM.render(<WithClearCacheApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
