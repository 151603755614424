import { Box, CircularProgress } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppTypography } from "Layout/Components";
import React from "react";
import { DropzoneInputProps } from "react-dropzone";

export interface FileUploadFormProps {
  inputProps: DropzoneInputProps;
  message: string;
  isLoading: boolean;
  isError: boolean;
  isPhoto: boolean;
}

const FileUploadForm: React.FC<FileUploadFormProps> = (props) => {
  const { inputProps, message, isLoading, isPhoto, isError } = props;
  const { up } = useBreakpoints();

  return !isLoading ? (
    <>
      <input {...inputProps} />
      <Add color="primary" fontSize="large" />
      <AppTypography
        align="center"
        weight={700}
        color="primary"
        translation={
          isPhoto ? "COMMON_BUTTONS_UPLOADPHOTO" : "COMMON_BUTTONS_UPLOADLOGO"
        }
      />
      {up("md") && (
        <Box marginTop={2}>
          <AppTypography
            align="center"
            weight={700}
            size={0.9}
            color={isError ? "error" : "textSecondary"}
          >
            {message}
          </AppTypography>
        </Box>
      )}
    </>
  ) : (
    <CircularProgress size={34} />
  );
};

export default FileUploadForm;
