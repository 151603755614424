import React from "react";
import { SnackbarProvider } from "notistack";
import styled from "styled-components";
import { CheckIcon } from "assets/icons";

export interface AppSnackbarProviderProps {}

const StyledSnackbarProvider = styled(SnackbarProvider)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  #notistack-snackbar {
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
  }
`;

export const AppSnackbarProvider: React.FC<AppSnackbarProviderProps> = (
  props
) => {
  return (
    <StyledSnackbarProvider
      iconVariant={{
        info: <CheckIcon style={{ marginRight: 8 }} />,
      }}
      maxSnack={3}
    >
      {props.children}
    </StyledSnackbarProvider>
  );
};

export default AppSnackbarProvider;
