import React from "react";
import { AddButton, AppTypography } from "Layout/Components";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../../Layout/Components/Pagination";
import { ICorporationDto } from "API/Interfaces";
import SearchInput from "../../Layout/Components/SearchInput";
import CorporationItem from "./CorpComponents/CorporationItem";
import { useGenericList } from "Hooks";
import CorporationServiceApi from "API/Services/CorporationServiceApi";
import { generateRoute } from "Routing/routes";
import usePageName from "Hooks/usePageName";

export interface CorporationProps {}

const StyledSearchInput = styled(SearchInput)`
  margin-right: 14px;
`;

const CorporationList = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 12px #00000026;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const CorporationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-bottom: 32px;
    padding: 0;
  }
`;

const Corporations: React.FC<CorporationProps> = () => {
  const { push } = useHistory();
  usePageName("COMMON_PAGES_CORPORATIONLIST");
  const { data, page, search, setSearch, numberOfPages, getData } =
    useGenericList<ICorporationDto>(CorporationServiceApi.getCorporations);
  return (
    <>
      <CorporationsHeader>
        <StyledSearchInput
          onSubmit={(value) => setSearch(value)}
          placeholder={"COMMON_FORMS_SEARCHCORPORATION"}
          showButton
        />

        <AddButton
          onClick={() => push(generateRoute.corporation("new"))}
          translation="COMMON_BUTTONS_ADDNEWCORPORATION"
        />
      </CorporationsHeader>
      {data?.length === 0 && (
        <AppTypography
          color="textSecondary"
          translation="COMMON_NOCORPORATIONS"
        />
      )}
      <CorporationList>
        {data && <CorporationItem corporations={data} />}
      </CorporationList>
      <Pagination
        pages={numberOfPages}
        currentPage={page}
        callback={(p: number) => getData(p, search)}
      />
    </>
  );
};

export default Corporations;
