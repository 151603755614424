import React from "react";
import styled from "styled-components";
import { themeComponents } from "../theme";
import Paginations from "@material-ui/lab/Pagination";
import { useScrollTo } from "Hooks";

export interface PaginationProps {
  pages: number;
  currentPage: number;
  callback(page?: number): void;
  display?: boolean;
}

const PaginationContainer = styled.div`
  width: 100%;
`;

const PaginationStyled = styled(Paginations)`
  & .MuiPagination-ul {
    justify-content: center;
    ${(_) => _.theme.breakpoints.up("md")} {
      justify-content: flex-end;
    }
  }
  & .MuiPaginationItem-root {
    font-weight: 700;
    color: ${themeComponents.primary};
  }
  & .MuiPaginationItem-icon {
    fill: ${themeComponents.primary};
  }
  & .Mui-selected {
    font-weight: 700;
    color: #fff;
  }
`;

const Pagination: React.FC<PaginationProps> = (props) => {
  const { display, currentPage, pages, callback, ...rest } = props;
  const { scrollToTop } = useScrollTo();
  return (
    <PaginationContainer>
      <PaginationStyled
        hidden={props.display}
        page={currentPage}
        count={pages}
        onChange={(_, p) => {
          callback(p);
          scrollToTop();
        }}
        color="primary"
        {...rest}
      />
    </PaginationContainer>
  );
};

export default Pagination;
