import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { ISearchElements } from "API/Interfaces";
import { default as React } from "react";
import GlobalSearchListItem from "./GlobalSearchListItem";

export interface GlobalSearchListProps {
  list: ISearchElements[];
  hideDialog: () => void;
  searchPhrase: string;
}

export const GlobalSearchList: React.FC<GlobalSearchListProps> = ({
  list,
  searchPhrase,
  hideDialog,
  ...props
}) => {
  return (
    <List>
      {list.map(
        (el, i) =>
          el.corporationInfo && (
            <React.Fragment key={i}>
              <ListItem>
                <ListItemText
                  primary={
                    <GlobalSearchListItem
                      {...el}
                      onClick={hideDialog}
                      searchPhrase={searchPhrase}
                    />
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          )
      )}
      {props.children}
    </List>
  );
};

export default GlobalSearchList;
