import React, { FC, useCallback, useContext, useState } from "react";
import { AddressConfiguration, IAddressItemValue } from "API/Interfaces";
import { AddressFieldsKeys } from "Utils/AddressConfiguration/addressConfigurationSchema";
import { ICorporationDetailsResponse } from "API/Interfaces";
import { useParams } from "react-router";
import {
  initSchema,
  schemaGenerator,
} from "Pages/CorporationDetails/Components/CoporationForm/CorporationDetailsForm.schema";
import { useAddressConfigurationContext } from "Utils";
import useCorporation from "../Hooks/useCorporation";
import useLocations from "../Hooks/useLocations";

export type FormAddressConfiguration = {
  [K in AddressFieldsKeys]: IAddressItemValue;
};

export type CorporationContextType = {
  id: string;
  updateSchema: (arg: AddressConfiguration) => void;
  corporationSchema: typeof initSchema;
  corporation: ICorporationDetailsResponse | null | undefined;
  locations: ReturnType<typeof useLocations>;
  getCorporation: () => void;
};

export const CorporationContext = React.createContext<CorporationContextType>(
  {} as CorporationContextType
);

export const useCorporationContext = () => useContext(CorporationContext);

export const CorporationProvider: FC = (props) => {
  const { id }: { id: string } = useParams();
  const [schema, setSchema] = useState(initSchema);
  const { corporation, getCorporation } = useCorporation();
  const locations = useLocations();
  const { setAddressConfiguration } = useAddressConfigurationContext();

  const updateSchema = useCallback(
    (arg: AddressConfiguration) => {
      setSchema(schemaGenerator(arg));
      setAddressConfiguration(arg);
    },
    [setAddressConfiguration]
  );

  return corporation !== undefined ? (
    <CorporationContext.Provider
      value={{
        id: id === "new" ? "" : id,
        corporation,
        locations,
        corporationSchema: schema,
        updateSchema,
        getCorporation,
      }}
    >
      {props.children}
    </CorporationContext.Provider>
  ) : null;
};

export default CorporationContext;
