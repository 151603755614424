import { i18n, TranslationKeys, Translations } from "Translation";

export enum DictSystems {
  Enforcement = 1,
  Text2Park = 2,
  Valet = 4,
  Text4Service = 8,
}

export enum UserRoles {
  TEZAdmin = 1,
  Billing = 2,
  TEZAdminBilling = 3,
}

export enum AuthTokenTypes {
  Auth = 1,
  Refresh = 2,
}

export enum OpeningDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  AllWeek = 7,
}

export const OpeningDaysLabel = {
  [OpeningDays.Sunday]: i18n.t(Translations.COMMON_FORMS_SUNDAY),
  [OpeningDays.Monday]: i18n.t(Translations.COMMON_FORMS_MONDAY),
  [OpeningDays.Tuesday]: i18n.t(Translations.COMMON_FORMS_TUESDAY),
  [OpeningDays.Wednesday]: i18n.t(Translations.COMMON_FORMS_WEDNESDAY),
  [OpeningDays.Thursday]: i18n.t(Translations.COMMON_FORMS_THURSDAY),
  [OpeningDays.Friday]: i18n.t(Translations.COMMON_FORMS_FRIDAY),
  [OpeningDays.Saturday]: i18n.t(Translations.COMMON_FORMS_SATURDAY),
  [OpeningDays.AllWeek]: i18n.t(Translations.COMMON_FORMS_ALLWEEK),
};

export enum TezServiceFeeType {
  PerCitation = 0,
  PerPayment = 1,
}

export enum LocationType {
  Standard = 0,
  Hotel = 1,
  Airport = 2,
}

export enum MainLotType {
  Valet = 0,
  SelfPark = 1,
  Text2Park = 2,
}

export const locationTypeLabel: {
  [key in LocationType]: TranslationKeys;
} = {
  [LocationType.Standard]: "ENUMS_LOCATIONTYPE_STANDARD",
  [LocationType.Hotel]: "ENUMS_LOCATIONTYPE_HOTEL",
  [LocationType.Airport]: "ENUMS_LOCATIONTYPE_AIRPORT",
};

export const mainLotTypeLabel: {
  [key in MainLotType]: TranslationKeys;
} = {
  [MainLotType.Valet]: "ENUMS_MAINLOTTYPE_VALET",
  [MainLotType.SelfPark]: "ENUMS_MAINLOTTYPE_SELFPARK",
  [MainLotType.Text2Park]: "ENUMS_MAINLOTTYPE_TEXT2PARK",
};

export enum TezProductType {
  VDB = 1,
  T2P = 2,
  NewEnforcement = 4,
}

export enum IntegrationStatus {
  Pending = 1,
  Created = 2,
  Active = 3,
  Terminated = 4,
}

export const integrationStatusLabel: {
  [key in IntegrationStatus]: TranslationKeys;
} = {
  [IntegrationStatus.Pending]: "ENUMS_INTEGRATIONSTATUS_PENDING",
  [IntegrationStatus.Created]: "ENUMS_INTEGRATIONSTATUS_CREATED",
  [IntegrationStatus.Active]: "ENUMS_INTEGRATIONSTATUS_ACTIVE",
  [IntegrationStatus.Terminated]: "ENUMS_INTEGRATIONSTATUS_TERMINATED",
};

export enum RedirectVariant {
  Corporation = 1,
  Location = 2,
  Lot = 3,
}

export enum CorporationTabs {
  GeneralInfo,
  FunctionalitiesAndProducts,
  Locations,
}

export enum LocationTabs {
  GeneralInfo,
  BillingInfo,
  FunctionalitiesAndProducts,
  Lots,
}

export enum LotTabs {
  GeneralInfo,
  FunctionalitiesAndProducts,
}

export enum EntityType {
  Corporation = 1,
  Location = 2,
  Lot = 3,
}
