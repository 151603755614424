import { Grid } from "@material-ui/core";
import { AddressConfiguration } from "API/Interfaces";
import { Form, Formik } from "formik";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import FormSeparator from "Layout/Components/FormSeparator";
import {
  FormCancelButton,
  FormCityFieldWithAddressConfig,
  FormCountriesField,
  FormCountyFieldWithAddressConfig,
  FormNameField,
  FormPostalCodeFieldWithAddressConfig,
  FormStateFieldWithAddressConfig,
  FormStreet1WithAddressConfig,
  FormStreet2WithAddressConfig,
  FormSubmitButton,
  FormTextField,
  FormTimeZonesField,
  Map,
} from "Layout/FormComponents";
import FileUpload from "Layout/FormComponents/FileUpload/FileUpload";
import FormAddressAutocomplete from "Layout/FormComponents/FormAddressAutocomplete";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { locationResponseToForm } from "Utils/locationForm";
import ParkingManagement from "../ParkingManagement";
import { useLocationContext } from "../../Contexts/LocationContext";
import useSaveLocation from "Pages/LocationDetails/Hooks/useSaveLocation";
import MultiEmailAutocomplete from "./MultiEmailAutocomplete";

const StyledFormSeparator = styled(FormSeparator)`
  margin-bottom: 0px;
`;

const LocationDetailsForm: React.FC = () => {
  const t = useTranslate();
  const { goBack } = useHistory();
  const { location, id, updateSchema, schema } = useLocationContext();
  const saveLocation = useSaveLocation();
  const { down } = useBreakpoints();

  React.useEffect(() => {
    if (location) {
      updateSchema(
        location.address.addressConfiguration as AddressConfiguration
      );
    }
  }, [location, updateSchema]);

  return (
    <Formik
      initialValues={locationResponseToForm(location)}
      onSubmit={saveLocation}
      validationSchema={schema}
      enableReinitialize
    >
      <Form>
        <Grid container spacing={3}>
          <Grid container item>
            <FileUpload id={id} type="location" />
          </Grid>
          <Grid container item spacing={3}>
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12} md={6}>
                <FormNameField inputRequired />
              </Grid>
              {down("md") && <Grid item xs={12} md={6} />}
              <Grid item xs={12} md={6}>
                <FormAddressAutocomplete updateSchema={updateSchema} />
              </Grid>
              {down("md") && <Grid item xs={12} md={6} />}
              <Grid item xs={12} md={6}>
                <FormCountriesField updateSchema={updateSchema} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormStreet1WithAddressConfig />
              </Grid>
              <Grid item xs={12}>
                <FormStreet2WithAddressConfig />
              </Grid>
              <Grid item xs={5} sm={4} md={4} lg={4}>
                <FormPostalCodeFieldWithAddressConfig />
              </Grid>
              <Grid item xs={7} sm={8} md={8} lg={4}>
                <FormCountyFieldWithAddressConfig />
              </Grid>
              <Grid item xs={7} sm={8} md={8} lg={4}>
                <FormCityFieldWithAddressConfig />
              </Grid>
              <Grid item xs={5} sm={4} md={4} lg={4}>
                <FormStateFieldWithAddressConfig />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTimeZonesField />
              </Grid>
            </Grid>
            {down("md") && (
              <Grid container item xs={12} sm={6} lg={5}>
                <Grid item xs={12}>
                  <Map />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledFormSeparator>
              {t("COMMON_PAGES_CONTACT")}
            </StyledFormSeparator>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ display: `${down("md") ? "" : "contents"}` }}
          >
            <Grid item xs={12} md={3}>
              <FormTextField
                fieldName="contactName"
                labelTranslation="COMMON_FORMS_CONTACTPERSON"
                inputRequired
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTextField
                fieldName="contactPhone"
                labelTranslation={"COMMON_FORMS_PHONE"}
                inputRequired
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MultiEmailAutocomplete
                fieldName="contactEmail"
                label="COMMON_FORMS_EMAIL"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StyledFormSeparator>
              {t("COMMON_PAGES_BILLING")}
            </StyledFormSeparator>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ display: `${down("md") ? "" : "contents"}` }}
          >
            <Grid item xs={12} md={3}>
              <FormTextField
                fieldName="billingName"
                labelTranslation={"COMMON_FORMS_BILLINGPERSON"}
                inputRequired
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTextField
                fieldName="billingPhone"
                labelTranslation={"COMMON_FORMS_PHONE"}
                inputRequired
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MultiEmailAutocomplete
                fieldName="billingEmail"
                label="COMMON_FORMS_EMAIL"
              />
            </Grid>
            <Grid item xs={12}>
              <ParkingManagement />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <ButtonWrapper topSpacing={down("md") ? 4 : 1} marginBottom={2}>
            <FormCancelButton onClick={() => goBack()} />
            <FormSubmitButton />
          </ButtonWrapper>
        </Grid>
      </Form>
    </Formik>
  );
};

export default LocationDetailsForm;
