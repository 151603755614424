import { SvgIcon, SvgIconProps } from "@material-ui/core";
import * as React from "react";
export interface MenuIconWrapperProps {}

export const MenuIconWrapper: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      height={48}
      width={48}
      viewBox="0 0 48 48"
      style={{ fontSize: "3.5em" }}
      {...props}
    >
      {props.children}
    </SvgIcon>
  );
};

export default MenuIconWrapper;
