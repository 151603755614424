import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import AppTableCell from "./Components/AppTableCell";
import TableColumn from "./Components/TableColumn";
import AppButton from "Layout/Components/AppButton";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { useCsvImportContext } from "./Contexts/CsvImportContext";
import React, { useState } from "react";
import EditImportedLotFrom from "./Components/EditImportedLotFrom/EditImportedLotFrom";
import { EditIcon, SmallDeleteIcon } from "assets/icons/Icons";
import { DeleteImportLotDialog } from "./Components/EditImportedLotFrom/DeleteImportLotDialog";
import Pagination from "Layout/Components/Pagination";
import { ImportLotsResponse } from "API/Services/ImportCsvServiceApi";
import CancelImportLotDialog from "./Components/CancelImportLotDialog";
import { convertDMS } from "Utils";
import _ from "lodash";
export interface ImportTableProps {}

const ImportTable: React.FC<ImportTableProps> = () => {
  const { mappedData, onlyWithErrors, handleSaveImportLots, errorsCount } =
    useCsvImportContext();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<ImportLotsResponse[][]>([]);

  const handleEdit = (item: any) => {
    setSelectedItem(item);
    setOpenEditDialog(true);
  };

  const handleDelete = (item: any) => {
    setSelectedItem(item);
    setOpenDeleteDialog(true);
  };

  React.useEffect(() => {
    setPages(
      _.chunk(
        mappedData.filter(
          (el) => (onlyWithErrors && !!el.errors.length) || !onlyWithErrors
        ),
        10
      )
    );
  }, [mappedData, onlyWithErrors]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <AppTableCell translateLabel="COMMON_FORMS_NAME" />
              <AppTableCell translateLabel="COMMON_FORMS_LOTNUMBER" />
              <AppTableCell translateLabel="COMMON_FORMS_CAPACITY" />
              <AppTableCell translateLabel="COMMON_FORMS_LATITUDE" />
              <AppTableCell translateLabel="COMMON_FORMS_LONGITUDE" />
              <AppTableCell translateLabel="COMMON_FORMS_COUNTRY" />
              <AppTableCell translateLabel="COMMON_FORMS_STREET1" />
              <AppTableCell translateLabel="COMMON_FORMS_STREET2" />
              <AppTableCell translateLabel="COMMON_FORMS_POSTALCODE" />
              <AppTableCell translateLabel="COMMON_FORMS_COUNTY" />
              <AppTableCell translateLabel="COMMON_FORMS_CITY" />
              <AppTableCell translateLabel="COMMON_FORMS_STATE" />
              <AppTableCell translateLabel="COMMON_FORMS_ACTIONS" />
            </TableRow>
          </TableHead>
          <TableBody>
            {pages[page - 1] &&
              pages[page - 1].map((l, i) => (
                <TableRow key={`mapData-${i}`}>
                  <TableColumn errors={l.errors} fieldName="name">
                    {l.name}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="number">
                    {l.number}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="capacity">
                    {l.capacity}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="latitude">
                    {convertDMS(Number(l.gpsCoordinates.latitude), "lat")}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="longitude">
                    {convertDMS(Number(l.gpsCoordinates.longitude), "lng")}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="countryName">
                    {l.address.countryName}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="street1">
                    {l.address.street1}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="street2">
                    {l.address.street2}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="zipCode">
                    {l.address.zipCode}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="county">
                    {l.address.county}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="city">
                    {l.address.city}
                  </TableColumn>
                  <TableColumn errors={l.errors} fieldName="state">
                    {l.address.state}
                  </TableColumn>
                  <TableCell>
                    <ButtonWrapper>
                      <IconButton onClick={() => handleEdit(l)} size="small">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(l)} size="small">
                        <SmallDeleteIcon />
                      </IconButton>
                    </ButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pages={pages.length}
        currentPage={page}
        callback={(p: number) => setPage(p)}
      />
      <ButtonWrapper>
        <AppButton
          variant="outlined"
          translation="DIALOGS_CANCEL"
          onClick={() => setOpenCancelDialog(true)}
        />
        <AppButton
          translation="COMMON_BUTTONS_APPLY"
          onClick={handleSaveImportLots}
          disabled={errorsCount > 0 || mappedData.length === 0}
        />
      </ButtonWrapper>
      <EditImportedLotFrom
        open={openEditDialog}
        selectedItem={selectedItem}
        onClose={setOpenEditDialog}
      />
      <DeleteImportLotDialog
        open={openDeleteDialog}
        selectedItem={selectedItem}
        onClose={setOpenDeleteDialog}
      />
      <CancelImportLotDialog
        open={openCancelDialog}
        onClose={setOpenCancelDialog}
      />
    </>
  );
};

export default ImportTable;
