import Pagination from "Layout/Components/Pagination";
import { AppTypography } from "Layout/Components";
import styled from "styled-components";

export const LocationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-bottom: 32px;
    padding: 0;
  }
`;

export const ListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 45px;
  margin-bottom: 16px;
`;

export const ListTitleText = styled(AppTypography)`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const LotTittleWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    width: 70px;
  }
`;

export const LocationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  padding-bottom: 10px;
  margin-bottom: 10px;
  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    padding-right: 45px;
  }
`;

export const LocationInfo = styled.div`
  padding-right: 10px;
`;

export const LocationName = styled(AppTypography)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const Paging = styled(Pagination)`
  margin-top: 20px;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`;
