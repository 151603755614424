import { EntityType, IFunctionalitiesData } from "API/Interfaces";
import { FunctionalitiesAndProductsServiceApi } from "API/Services/FunctionalitiesAndProductsServiceApi";
import { usePopupContext, useLoaderContext } from "Contexts";
import { useErrorContext } from "Contexts/ErrorContext";
import { AppTypography } from "Layout/Components";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { isErrorCode } from "Utils";

const { getFunctionalitiesData, saveFunctionalitiesData } =
  FunctionalitiesAndProductsServiceApi;

const FunctionalityDialogContent = (
  <div>
    <AppTypography
      translation="COMMON_PAGES_VALETADMINPORTALFUNCTIONALITY"
      weight={700}
      size={0.9}
      align="left"
      gutterBottom
    />
    <AppTypography
      translation="DIALOGS_TODISABLEVALETADMINPORTAL"
      align="left"
    />
  </div>
);

export const useFunctionalities = (
  entityType: EntityType,
  getData: () => void
) => {
  const { id }: { id: string } = useParams();

  const [functionalities, setFunctionalities] =
    useState<IFunctionalitiesData | null>(null);
  const { setPopup, showPopup } = usePopupContext();
  const { setError } = useErrorContext();
  const { setLoading } = useLoaderContext();

  const getFormData = useCallback(() => {
    setLoading(true);
    getFunctionalitiesData(id, entityType)
      .then(setFunctionalities)
      .catch(() => setError())
      .finally(() => setLoading(false));
  }, [entityType, id, setError, setLoading]);

  const sendFormData = useCallback(
    (params: IFunctionalitiesData) => {
      setLoading(true);
      saveFunctionalitiesData(id, { ...params, entityType })
        .then(() => {
          setPopup({
            titleTranslation: "COMMON_PAGES_FUNCTIONALITIESANDPRODUCTS",
            buttonOkClick: () => {
              getFormData();
              getData();
            },
          });
          showPopup();
        })
        .catch((err) => {
          if (isErrorCode(err, "InvalidOperation")) {
            setPopup({
              titleTranslation: "COMMON_PAGES_FUNCTIONALITIESMANAGEMENT",
              variant: "none",
              Icon: undefined,
              descriptionNode: FunctionalityDialogContent,
              buttonOkClick: getFormData,
            });
            showPopup();
          } else setError();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      entityType,
      id,
      setError,
      setLoading,
      setPopup,
      showPopup,
      getFormData,
      getData,
    ]
  );

  return { functionalities, getFormData, sendFormData } as const;
};

export default useFunctionalities;
