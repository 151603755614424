import React, { useContext, createContext, useState } from "react";

export type ExpandedPanelContextType = {
  isExpanded: boolean;
  setIsExpanded: (arg: boolean) => void;
  isShowMobileMenu: boolean;
  setShowMobileMenu: (arg: boolean) => void;
};
export const ExpandedPanelContext = createContext<ExpandedPanelContextType>(
  {} as ExpandedPanelContextType
);
export const useExpandedPanelContext = () => useContext(ExpandedPanelContext);

export const ExpandedPanelProvider: React.FC = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isShowMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <ExpandedPanelContext.Provider
      value={{ isExpanded, setIsExpanded, isShowMobileMenu, setShowMobileMenu }}
    >
      {props.children}
    </ExpandedPanelContext.Provider>
  );
};

export default ExpandedPanelContext;
