import { Grid } from "@material-ui/core";
import { RedirectVariant } from "API/Interfaces";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPaper, AppTypography, DeleteButton } from "Layout/Components";
import { Wrapper } from "Layout/Components/BasicStyledComponents";
import RedirectToValetButton from "Layout/Components/RedirectToValetButton";
import React from "react";
import styled from "styled-components";
import { useLocationContext } from "./Contexts/LocationContext";
import LocationDetailsForm from "./Components/LocationDetailsForm/LocationDetailsForm";
import useDeleteLocation from "./Hooks/useDeleteLocation";

const GeneralHeader = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const GeneralInfo: React.FC = () => {
  const { down } = useBreakpoints();
  const { id, location } = useLocationContext();
  const [DeleteDialog, openDeleteDialog] = useDeleteLocation();

  return (
    <>
      <AppPaper elevation={3} padding={down("md") ? 3 : 1} fullWidth>
        <GeneralHeader
          container
          item
          justifyContent="space-between"
          alignItems="center"
        >
          <AppTypography variant="h2" translation="COMMON_PAGES_GENERAL" />
          {location && (
            <Wrapper>
              {!!location.isEnabledRedirectToValet && down("md") && (
                <RedirectToValetButton
                  variant={RedirectVariant.Location}
                  id={id}
                />
              )}
              <DeleteButton
                onClick={() => openDeleteDialog()}
                translation="COMMON_BUTTONS_DELETELOCATION"
              />
            </Wrapper>
          )}
        </GeneralHeader>
        <LocationDetailsForm />
      </AppPaper>
      <DeleteDialog id={id} name={location?.name || ""} />
    </>
  );
};

export default GeneralInfo;
