import styled from "styled-components";

const StyledCorner = styled.svg`
  position: absolute;
  right: -32px;
  top: 0px;
`;

export const Corner = () => {
  return (
    <StyledCorner
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 0H0V32C0 14.3269 14.3269 0 32 0Z"
        fill="#7B59FB"
      />
    </StyledCorner>
  );
};

export default Corner;
