import { AddressServiceApi } from "API/Services";
import { IAddressRequest, IImportLots } from "API/Services/ImportCsvServiceApi";
import { getAddressFields } from "Utils";
import { EdtiFormFields } from "./Components/EditImportedLotFrom/Shema";
import { IColumnData } from "./MappingPage";
import { Country } from "API/Interfaces";
import { i18nt } from "Translation";

export enum CsvImportErrors {
  InvalidValue = 0,
}

export const changeComma = (
  value: string | number,
  column: string,
  index: number
) => {
  const geo = Number(value.toString().replace(",", "."));
  if (isNaN(geo))
    throw Object.assign(
      new Error(
        i18nt("COMMON_FORMS_CSVVALUEERROR", {
          value,
          column,
          row: index + 1,
        })
      ),
      { code: CsvImportErrors.InvalidValue }
    );

  return geo;
};

export const getAddressByGps = async (
  lat: string,
  long: string,
  countries: Country[]
): Promise<IAddressRequest> => {
  const res = await AddressServiceApi.getAddressByCoordinates(lat, long);
  const data = getAddressFields(res[0], false);
  const country = countries.find((el) => el.countryCode === data.countryCode);
  return {
    street1: data.street,
    street2: "",
    zipCode: data.postalCode,
    county: data.county,
    city: data.city,
    state: data.state,
    countryName: country?.name || "",
  };
};

export const getGpsByAddress = async (
  street1: string,
  city: string,
  zipCode: string
): Promise<{ latitude: number; longitude: number }> => {
  const res = await AddressServiceApi.getCoordinatesByAddress(
    street1,
    city,
    zipCode
  );
  const gps = res[0]?.geometry?.location;
  return { latitude: gps?.lat() || 0, longitude: gps?.lng() || 0 };
};

export const downloadTemplate = () => {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," +
      encodeURIComponent(
        `Name;Parking Company;Lot Number;Capacity;Latitude;Longitude;Country;Street 1;Street 2;Postal Code;County;City;State`
      )
  );
  element.setAttribute("download", "import_lots_template.csv");
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const FormToApi = (arg: EdtiFormFields): IImportLots => ({
  name: arg.name,
  address: {
    city: arg.city,
    countryName: arg.countryName,
    county: arg.county,
    state: arg.state,
    street1: arg.street1,
    zipCode: arg.zipCode,
    street2: arg.street2,
  },
  gpsCoordinates: {
    latitude: Number(arg.latitude),
    longitude: Number(arg.longitude),
  },
  isBasedOnCoordinates: arg.isBasedOnCoordinates,
  capacity: arg.capacity || undefined,
  number: arg.number,
  rowNumber: arg.rowNumber || undefined,
});

interface IImportError {
  [key: number]: string;
}

export const ImportErrorEnum: IImportError = {
  1: i18nt("DIALOGS_ALREADYEXISTS"),
  2: i18nt("COMMON_FORMS_FIELDISREQUIRED"),
  3: i18nt("COMMON_FORMS_FIELDISTOLONG"),
  4: i18nt("COMMON_FORMS_INVALIDFORMAT"),
};

export const mapCsvToForm = async (
  importedData: any[],
  data: IColumnData,
  isBasedOnCoordinates: boolean,
  countries: Country[]
) => {
  const correctData: IImportLots[] = await Promise.all(
    importedData.map(async (el, index) => {
      const gps =
        el[data.latitude] && el[data.longitude]
          ? {
              latitude: changeComma(el[data.latitude], data.latitude, index),
              longitude: changeComma(el[data.longitude], data.longitude, index),
            }
          : await getGpsByAddress(
              el[data.street1],
              el[data.city],
              el[data.zipCode]
            );

      return {
        name: el[data.name],
        companyParking: el[data.companyParking],
        number: el[data.number],
        capacity: el[data.capacity],
        gpsCoordinates: gps,
        address: isBasedOnCoordinates
          ? await getAddressByGps(
              changeComma(el[data.latitude], data.latitude, index).toString(),
              changeComma(el[data.longitude], data.longitude, index).toString(),
              countries
            )
          : {
              street1: el[data.street1],
              street2: el[data.street2],
              city: el[data.city],
              state: el[data.state],
              zipCode: el[data.zipCode],
              county: el[data.county],
              countryName: el[data.countryName],
            },
        isBasedOnCoordinates,
        rowNumber: index + 1,
      };
    })
  );
  return correctData;
};
