import { FC } from "react";
import styled from "styled-components";
import { AppButton, AppTypography } from ".";
import { useAuthContext } from "Pages/Auth";
import useRedirects from "Hooks/useRedirects";

interface MobileProfileBoxProps {}

const Container = styled.div`
  border: 1px solid ${(_) => _.theme.palette.neutral.main};
  border-radius: 20px;
  min-height: 130px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin: 5px 0;
  }
  ${(_) => _.theme.breakpoints.up("sm")} {
    max-width: 400px;
    margin: 20px auto;
  }
`;

const Name = styled(AppTypography)`
  color: #fff;
`;

const CustomButton = styled(AppButton)`
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  min-width: 10px;
  :hover {
    background-color: transparent !important; //important is necessary for button hover
    color: ${(_) => _.theme.palette.primary.main};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    margin: 0 5px;
  }
`;

export const MobileProfileBox: FC<MobileProfileBoxProps> = () => {
  const { user, unsetUser } = useAuthContext();
  const { redirectToEnf } = useRedirects();

  return (
    <Container>
      {user && (
        <Name
          variant="h2"
          align="center"
        >{`${user.firstName} ${user.lastName}`}</Name>
      )}
      <ButtonWrapper>
        <CustomButton
          variant="outlined"
          size="small"
          translation="COMMON_AUTH_ENFORCEMENTPORTAL"
          onClick={redirectToEnf}
        />
        <CustomButton
          variant="outlined"
          size="small"
          translation="COMMON_AUTH_LOGOUT"
          minWidth={10}
          onClick={unsetUser}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default MobileProfileBox;
