import { config } from "API/Services";
import { apiClient } from "../Auth/apiClient";
import {
  ICorporationDetailsRequest,
  ICorporationDetailsResponse,
  ICorporationDto,
  ISearchAllRequest,
  ISearchAllResponse,
} from "../Interfaces";
import { IPages } from "../Interfaces/IPagination";
import { urls } from "./urls";

interface IResponseDataId {
  data: { id: string };
}

export interface IResponceSingleData<T> {
  data: T;
}

const searchAll = async (params: ISearchAllRequest) => {
  const res = await apiClient.get<IResponceSingleData<ISearchAllResponse>>(
    urls.corporations.searchAll,
    { params }
  );
  return res.data.data;
};

const CorporationServiceApi = {
  getCorporations: async (
    startingPageNumber: number,
    searchPhrase?: string
  ): Promise<IPages<ICorporationDto>> => {
    const response = await apiClient.get(urls.corporations.all(), {
      params: {
        startingPageNumber,
        searchPhrase,
      },
    });
    return response.data.data;
  },
  getCorporation: async (id: string): Promise<ICorporationDetailsResponse> => {
    const res = await apiClient.get(urls.corporations.details(id));
    return res.data.data;
  },
  addCorporation: async (
    corpData: ICorporationDetailsRequest
  ): Promise<IResponseDataId> => {
    const response = await apiClient.post(
      `${config.API_URL_PATH}/Corporations/`,
      corpData
    );
    return response.data;
  },
  editCorporation: async (
    corpId: string,
    corpData: ICorporationDetailsRequest
  ): Promise<IResponseDataId> => {
    const response = await apiClient.put(
      `${config.API_URL_PATH}/Corporations/${corpId}`,
      corpData
    );
    return response.data;
  },
  deleteCorporation: async (id: string) => {
    const response = await apiClient.delete(
      `${config.API_URL_PATH}/Corporations/${id}`
    );
    return response;
  },
  searchAll,
};

export default CorporationServiceApi;
