import CorporationServiceApi from "API/Services/CorporationServiceApi";
import { CorporationForm } from "../Components/CoporationForm/CorporationDetailsForm.schema";
import { formToSaveCorporation } from "Utils/corporationForm";
import { generateRoute } from "Routing/routes";
import { isErrorCode } from "Utils";
import { useTranslate } from "Hooks";
import { useHistory } from "react-router-dom";
import { useLoaderContext, usePopupContext } from "Contexts";
import { useCorporationContext } from "../Contexts/CorporationContext";

export const useSaveCorporation = () => {
  const t = useTranslate();
  const { push } = useHistory();
  const { setPopup, showPopup } = usePopupContext();
  const { setLoading } = useLoaderContext();
  const { id } = useCorporationContext();

  const addCorporation = (arg: CorporationForm) => {
    setLoading(true);
    CorporationServiceApi.addCorporation(formToSaveCorporation(arg))
      .then((res) => {
        setPopup({
          titleTranslation: "DIALOGS_CREATECORPORATIONTITLE",
          description: `${arg.name} ${t("DIALOGS_CREATESUCCESS")}`,
          variant: "info",
          buttonOkClick: () => push(generateRoute.corporation(res.data.id)),
        });
        return res;
      })
      .catch((error) => {
        setPopup({
          titleTranslation: "DIALOGS_CREATECORPORATIONTITLE",
          description: isErrorCode(error, "ResourceExists")
            ? `${arg.name} ${t("DIALOGS_ALREADYEXISTS")}`
            : `${arg.name} ${t("DIALOGS_CREATEFAIL")}`,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  const editCorporation = (arg: CorporationForm) => {
    setLoading(true);
    CorporationServiceApi.editCorporation(id, formToSaveCorporation(arg))
      .then((res) => {
        setPopup({
          titleTranslation: "DIALOGS_EDITCORPORATIONTITLE",
          description: `${arg.name} ${t("DIALOGS_EDITSUCCESS")}`,
          variant: "info",
        });
        return res;
      })
      .catch((error) => {
        setPopup({
          titleTranslation: "DIALOGS_EDITCORPORATIONTITLE",
          description: isErrorCode(error, "ResourceExists")
            ? `${arg.name} ${t("DIALOGS_ALREADYEXISTS")}`
            : `${arg.name} ${t("DIALOGS_EDITFAIL")}`,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        showPopup();
      });
  };

  return id ? editCorporation : addCorporation;
};

export default useSaveCorporation;
