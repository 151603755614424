import Add from "@material-ui/icons/Add";
import { useTranslate } from "Hooks";
import useBreakpoints from "Hooks/useBreakpoints";
import { FC } from "react";
import styled from "styled-components";
import { AppButton, AppButtonProps } from ".";

interface AddButtonProps extends AppButtonProps {}

const StyledButton = styled(AppButton)`
  height: 36px;
  ${(_) => _.theme.breakpoints.up("md")} {
    height: unset;
  }
`;

export const AddButton: FC<AddButtonProps> = ({ translation, ...rest }) => {
  const t = useTranslate();
  const { down } = useBreakpoints();

  return (
    <StyledButton startIcon={<Add />} minWidth={60} {...rest}>
      {down("md") && t(translation)}
    </StyledButton>
  );
};

export default AddButton;
