import { TezServiceFeeType, YupSchemaKeys } from "API/Interfaces";
import { i18nTranslation } from "Utils";
import * as yup from "yup";

export interface BillingFields {
  device5InchFee: string;
  device5InchQuantity: number;
  softwareFee: string;
  priorityLetterFee: string;
  plateLookupFee: string;
  certifiedLettersFee: string;
  // tezServiceFee section
  tezServiceFee: boolean;
  feeName: string | null;
  feeValue: string | undefined;
  tezServiceFeeType?: TezServiceFeeType;
}

export const initialValues: BillingFields = {
  device5InchFee: "0.00",
  device5InchQuantity: 0,
  softwareFee: "0.00",
  priorityLetterFee: "0.00",
  plateLookupFee: "0.00",
  certifiedLettersFee: "0.00",
  tezServiceFee: false,
  feeName: "TezServiceFeeName",
  feeValue: "0.00",
  tezServiceFeeType: 0,
};

export type BillingInfoSchema = YupSchemaKeys<BillingFields>;

const yupNumberSchema = yup
  .string()
  .matches(
    /^\d*([,.][0-9]{1,2})?$/,
    i18nTranslation("COMMON_FORMS_INCORRECTNUMBERFORMAT")
  )
  .max(50, i18nTranslation("COMMON_FORMS_MAXCHARACTER50"));

const yupNumberRequiredSchema = yupNumberSchema.required(
  i18nTranslation("COMMON_FORMS_FIELDISREQUIRED")
);

const billingInfoSchemaObj: Partial<BillingInfoSchema> = {
  device5InchFee: yupNumberRequiredSchema,
  certifiedLettersFee: yupNumberRequiredSchema,
  device5InchQuantity: yup
    .string()
    .required(i18nTranslation("COMMON_FORMS_FIELDISREQUIRED"))
    .matches(/^[0-9]+$/, i18nTranslation("COMMON_FORMS_FIELDMUSTBEANUMBER")),
  plateLookupFee: yupNumberRequiredSchema,
  priorityLetterFee: yupNumberRequiredSchema,
  softwareFee: yupNumberRequiredSchema,
  tezServiceFee: yup.boolean(),
  feeName: yup
    .string()
    .max(100, i18nTranslation("COMMON_FORMS_MAXCHARACTER100"))
    .test(
      "fee name",
      i18nTranslation("COMMON_FORMS_FIELDISREQUIRED"),
      function (value) {
        return this.parent.tezServiceFee ? Boolean(value) : true;
      }
    )
    .nullable(),
  feeValue: yupNumberSchema.test(
    "fee value",
    i18nTranslation("COMMON_FORMS_FIELDISREQUIRED"),
    function (value) {
      return this.parent.tezServiceFee ? Boolean(value) : true;
    }
  ),
};

export const billingInfoSchema = yup.object(billingInfoSchemaObj);
