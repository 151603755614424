import { Typography } from "@material-ui/core";
import AppPaper from "Layout/Components/AppPaper";
import styled from "styled-components";
import ImportStepper from "./Components/importStepper";
import { useTranslate } from "Hooks";
import AppButton from "Layout/Components/AppButton";
import ImportPage from "./ImportPage";
import MappingPage from "./MappingPage";
import ImportTable from "./ImportTable";
import SuccessImport from "./SuccessImport";
import ErrorCounter from "./Components/ErrorCounter";
import {
  CsvImportContextProvider,
  CsvImportContext,
} from "./Contexts/CsvImportContext";
import { downloadTemplate } from "./Utils";
import OnlyErrorsCheck from "./Components/OnlyErrorsCheck";
import ImportBreadcrumbs from "./Components/ImportBreadcrumbs";

export interface ImportFromCsvProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > * {
    width: 100%;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
`;

const PageInforamiotn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0 10px;
  }
`;

const StyledPaper = styled(AppPaper)`
  > * {
    margin: 10px 0;
    :nth-child(1),
    :nth-last-child(1) {
      margin: 0px 0;
    }
  }
`;

const ImportFromCsv: React.FC<ImportFromCsvProps> = () => {
  const t = useTranslate();

  return (
    <>
      <CsvImportContextProvider>
        <CsvImportContext.Consumer>
          {({ currentStep }) => (
            <>
              <TopWrapper>
                <ImportBreadcrumbs />
                <AppButton
                  onClick={downloadTemplate}
                  translation="COMMON_BUTTONS_DOWNLOADCSVTEMPLATE"
                  variant="outlined"
                />
              </TopWrapper>
              <StyledPaper fullWidth padding={2}>
                <Wrapper>
                  <PageInforamiotn>
                    <Typography color="textPrimary" variant="h2">
                      {currentStep === 0 && t("COMMON_PAGES_IMPORTLOTS")}
                      {currentStep === 1 && t("COMMON_PAGES_COLUMNMAPPING")}
                      {currentStep === 2 && t("COMMON_PAGES_IMPORTRESULT")}
                      {currentStep === 3 && t("COMMON_PAGES_IMPORTRESULT")}
                    </Typography>
                    {currentStep === 2 && <ErrorCounter />}
                    {currentStep === 2 && <OnlyErrorsCheck />}
                  </PageInforamiotn>

                  <ImportStepper />
                </Wrapper>
                {currentStep === 0 && <ImportPage />}
                {currentStep === 1 && <MappingPage />}
                {currentStep === 2 && <ImportTable />}
                {currentStep === 3 && <SuccessImport />}
              </StyledPaper>
            </>
          )}
        </CsvImportContext.Consumer>
      </CsvImportContextProvider>
    </>
  );
};

export default ImportFromCsv;
